import { Box, Flex, Text } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import './CustomTooltip.css';

export const CustomTooltip = ({
  active,
  payload,
  label
}) => {
  const intl = useIntl();
  if (active && payload && payload.length) {
    let payloadValue = payload[0]?.value || 0;

    return (
      <Box
        className='custom_tooltip'
        position='relative'
        style={{
          background: 'white',
          padding: '8px 25px',
          borderRadius: '10px',
          border: '1px solid #D3D5DA',
          zIndex: 1000,
          outline: 'none !important',
        }}
      >
        {(
          <Box
            className={payloadValue > 0 ? 'triangle_down' : 'triangle_up'}
            position='absolute'
            left='38%'
            bottom={payloadValue > 0 ? '-15%' : '100%'}
          />
        )}
        <Flex flexDirection={"column"}>
        <Text
          className='cashflow'
          textAlign='center'
          color='#0A64BC'
          fontSize='8px'
          fontWeight={400}
          fontFamily='Poppins'
        >
          {Math.floor(payload[0]?.payload?.percentile ?? payload[0]?.payload?.value ?? 0)}
        </Text>
        <Text
          className='cashflow'
          textAlign='center'
          color='#0A64BC'
          fontSize='8px'
          fontWeight={400}
          fontFamily='Poppins'
        >
          {payload[0]?.payload?.date}
        </Text>
        </Flex>
      </Box>
    );
  }

  return null;
};
