export const MonkScoreItems: Array<{label:string,value:string}> = [
        {
            label: "MonkScore®",
            value: "monkscore",
        },
        {
            label: "Value",
            value: "value",
        },
        {
            label: "Growth",
            value: "growth",
        },
        {
            label: "Profitability",
            value: "profitability",
        },
        {
            label: "Financial Health",
            value: "health",
        },
        {
            label: "Shareholder Retribution",
            value: "ShareholderRetribution",
        },
       
]

export const CompanyProfileItems: Array<{label:string,value:string}> = [
    {
        label: "Sector",
        value: "sector",
    },
    {
        label: "Industry",
        value: "industry",
    },
    {
        label: "Country",
        value: "country",
    },
    {
        label: "Exchange",
        value: "exchange",
    },
    {
        label: "Employees",
        value: "employees",
    },
    {
        label: "Market Capitalization",
        value: "mktCapUSD",
    },
    {
        label: "Volatility",
        value: "volatility",
    },
   
]

export const RatiosItems: Array<{label:string,value:string}> = [
    {
        label: "Price to Earnings (P/E)",
        value: "priceToEarnings",
    },
    {
        label: "Price to Free Cash Flow (P/FCF)",
        value: "priceToFCF",
    },
    {
        label: "Price to Sales (P/S)",
        value: "priceToSales",
    },
    {
        label: "Return On Equity (ROE)",
        value: "returnOnEquity",
    },
    {
        label: "Return on Assets (ROA)",
        value: "returnOnAssets",
    },
    {
        label: "Return on Invested Capital (ROIC)",
        value: "returnOnInvestedCapital",
    },
    {
        label: "Interest Coverage",
        value: "interestCoverage",
    },
    {
        label: "Net Debt to Equity",
        value: "netDebtToEquity",
    },
    {
        label: "Dividend Yield",
        value: "dividendYield",
    }  
]

export const ListItems: Array<{label:string,value:string}> = [
    {
        label: "My watchlist",
        value: "watchlist",
    },
    {
        label: "My portfolio",
        value: "portfolio",
    }
]


export const numberOperators: Array<{label:string,value:string}> = [
    {
        label: "equal",
        value: "eq",
    },
    {
        label: "distinct",
        value: "dst",
    },
    {
        label: "greater than",
        value: "gt",
    },
    {
        label: "greater than or equal",
        value: "gte",
    },
    {
        label: "less than",
        value: "lt",
    },
    {
        label: "less than or equal",
        value: "lte",
    },
    {
        label: "between",
        value: "btw",
    }
]

export const stringOperators: Array<{label:string,value:string}> = [
    {
        label: "is",
        value: "is",
    },
    {
        label: "is one of",
        value: "iof",
    },
    {
        label: "is not one of",
        value: "inof",
    }
]