import React, { useState, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  Box,
  Flex,
  Checkbox,
} from "@chakra-ui/react";
import "../Stocks/tooltipModal/tooltipModal.css";

const TermPrivacyModal = ({
  isOpen,
  setIsOpen,
  TCChecked,
  setTCChecked,
  sendPeriodicUpdates,
  setSendPeriodicUpdates,
}) => {
  const handleCurrentAction = () => {
    setTCChecked(true);
    setTimeout(() => setIsOpen(false), 1000);
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      scrollBehavior="inside"
      size="sm"
      isCentered
    >
      <ModalOverlay sx={{ background: "#3a83c9a8" }} />
      <ModalContent
        bg="#FFF"
        borderRadius="20px"
        my={140}
        mx={5}
        p={"20px"}
        display="flex"
      >
        <ModalBody
          display="flex"
          py={"40px"}
          px="20px"
          flexDir="column"
          backgroundColor={"#F9FAFB"}
          borderRadius={"20px"}
          gap={"30px"}
          mt={0}
          alignItems={"start"}
        >
          <Text
            fontFamily="Poppins"
            fontStyle="normal"
            fontWeight="600"
            fontSize="24px"
            lineHeight="36px"
            textAlign="left"
            color="#021425"
          >
            {"Do you agree to our terms and privacy policies?"}
          </Text>

          <Box
            height={"300px"}
            minH={"150px"}
            background={"white"}
            p={"20px"}
            width={"100%"}
            borderRadius={"20px"}
            overflow={"scroll"}
            scrollMargin={"2"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "16px",
                borderRadius: "100px",
                backgroundColor: "#F9FAFB",
                paddingX: "8px",
                paddingY: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: `#085096`,
                borderRadius: "100px",
              },
            }}
          >
            <Text
              color={"#6C727F"}
              fontWeight={"400"}
              fontSize={"10px"}
              lineHeight={"20px"}
              fontFamily="Poppins"
            >
              Before you proceed, we kindly request you agree to our{" "}
              <a
                href="https://www.monk.st/legal"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                legal terms
              </a>
              ,{" "}
              <a
                href="https://www.monk.st/privacy"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                privacy policy
              </a>
              ,{" "}
              <a
                href="https://www.monk.st/disclaimer"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                disclaimer
              </a>{" "}
              and consent to the processing of your personal data in accordance
              with the General Data Protection Regulation (GDPR). <br /> <br />
              By proceeding, you confirm that you have read and agreed to our
              Privacy Policy, our Legal Notice, our Disclaimer and give your
              free and informed consent for us to collect and process your
              personal data for the following purposes:
              <br />
              <br />
              a. User Registration: To create and manage your user account.{" "}
              <br />
              <br />
              b. Service Provision: To provide you with the functionalities and
              features of our platform. <br />
              <br />
              c. Communication: To communicate with you regarding platform
              updates, announcements, and support-related matters. <br />
              <br />
              d. Notifications: To send you the notifications you configure in
              our platform, including updates on the companies you choose.{" "}
              <br />
              <br />
              e. Analytics and Improvements: To analyze user behavior,
              preferences, and trends to enhance and optimize the performance,
              usability, and features of our platform. <br />
              <br />
              f. Legal Compliance: To comply with legal obligations, such as
              data protection laws and regulations, and respond to lawful
              requests from authorities. <br />
              <br />
              h. Security: To ensure the security of our SaaS platform and
              protect against unauthorized access, data breaches, and other
              security threats. <br />
              <br />
              i. Billing and Payments: If applicable, to process payments,
              invoices, and manage billing information for the use of our
              premium or subscription-based services. <br />
              <br />
              j. Customization and Personalization: To personalize your
              experience on our SaaS platform based on your preferences and
              usage patterns.
              <br />
            </Text>
          </Box>

          <Flex
            justifyContent="flex-start"
            alignItems={"center"}
            fontFamily="Poppins"
            width={"100%"}
            gap="8px"
          >
            <Checkbox
              mr={"8px"}
              height="18px"
              width="18px"
              borderRadius={"4px"}
              border="1.5px solid #434343"
              type="checkbox"
              isChecked={sendPeriodicUpdates}
              onChange={(e) => {
                setSendPeriodicUpdates(e.target.checked);
              }}
            />
            <Text
              fontSize="12px"
              fontFamily="Poppins"
              fontWeight="400"
              lineHeight="18px"
              color="#475467"
            >
              Yes, I want to receive periodic updates.
            </Text>
          </Flex>

          <Button
            textAlign="center"
            py="28px"
            width="100%"
            fontFamily="Poppins"
            color="white"
            borderRadius="8px"
            backgroundColor="#0A64BC"
            boxShadow="0px 2px 3px rgba(0, 0, 0, 0.25)"
            cursor="pointer"
            _hover={{ backgroundColor: "#0A64BC" }}
            onClick={() => {
              setIsOpen(false);
              setTCChecked(true);
            }}
          >
            <Text
              fontWeight={600}
              fontSize="16px"
              fontFamily="Poppins"
              lineHeight="24px"
            >
              I have read it and consent!
            </Text>
          </Button>
          {/*
          <Flex mt={5}>
            <Checkbox
              height="24px"
              width="24px"
              mx={3}
              className="form_checkBox"
              backgroundColor="white"
              borderRadius="8px"
              border="1.5px solid #D3D5DA"
              boxShadow={0}
              type="checkbox"
            />
            <Text
              fontSize="16px"
              fontFamily="Poppins"
              fontWeight="400"
              lineHeight="24px"
              color="#475467"
            >
              <FormattedMessage
                id="pn_update_check"
                values={{
                  span: (chunks: ReactNode) => (
                    <span className="highlight_link">{chunks}</span>
                  ),
                }}
              />
            </Text>
          </Flex> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TermPrivacyModal;
