import { Flex, Text } from '@chakra-ui/react';
import GrowthBadge from '../GrowthBadge';

type TProps = {
  year: number;
  value: number;
  isMomentum?: boolean;
  sign?: string;
};

function YearGrowthBadge({ year, value, isMomentum, sign = '%' }: TProps) {
  const momentumLabels = ['6 months', '9 months', '1 year'];

  return (
    <Flex
      as={'li'}
      px='10px'
      py='5px'
      columnGap='5px'
      borderRadius={'10px'}
      backgroundColor={'#F9FAFB'}
      alignItems={'center'}
    >
      {isMomentum && year <= 3 && (
        <Text fontSize={'10px'} fontWeight={'400'} w={'max-content'}>
          {momentumLabels?.[year - 1]}
        </Text>
      )}
      {!isMomentum && (
        <Text fontSize={'10px'} fontWeight={'400'} w={'max-content'}>
          {year} year{year > 1 && 's'}
        </Text>
      )}
      <GrowthBadge
        vector={value > 0 ? 'up' : 'down'}
        meta={`${value?.toPrecision(3)}${sign}`}
        large
      />
    </Flex>
  );
}

export default YearGrowthBadge;
