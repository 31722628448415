import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { closeModal, selectModal } from '../../../store/slices/modal';
import translateIcon from '../../../assets/svg/translate.svg';
import { MODAL_TYPES_ENUM } from '../../../constants/variables';
import styles from './styles.module.css';
import { HiChevronDown } from 'react-icons/hi2';

const LOCALES = {
  es: {
    title: 'Español',
    buttonText: 'Continuar en español',
  },
  en: {
    title: 'English',
    buttonText: 'Continue in English',
  },
  ua: {
    title: 'Українська',
    buttonText: 'перейти на українську',
  },
};

function LocaleModal() {
  const dispatch = useDispatch();
  const modalData = useSelector(selectModal);

  const [selectedLocale, setSelectedLocale] = useState('');

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      isOpen={modalData?.isOpen && modalData?.type === MODAL_TYPES_ENUM.locale}
      onClose={handleCloseModal}
      size='sm'
      isCentered
      closeOnOverlayClick={true}
      closeOnEsc={true}
    >
      <ModalOverlay sx={{ background: '#3a83c9a8' }} />
      <ModalContent
        bg='#FFF'
        borderRadius='20px'
        p={'20px'}
        maxW={{ base: '340px', md: '415px' }}
      >
        <ModalBody
          py={'40px'}
          px='20px'
          display={'flex'}
          flexDirection={'column'}
          rowGap={'20px'}
          alignItems={'center'}
          fontFamily='Poppins'
          fontSize='sm'
          lineHeight='24px'
          backgroundColor={'#F9FAFB'}
          borderRadius={'20px'}
        >
          <img src={translateIcon} alt='Icon' />
          <Text
            fontSize='18px'
            fontWeight='600'
            lineHeight='27px'
            color='#021425'
          >
            Choose your language
          </Text>

          <Menu matchWidth>
            <MenuButton
              as={Button}
              rightIcon={<HiChevronDown />}
              w={'100%'}
              h='56px'
              pl={'20px'}
              pr={'10px'}
              textAlign={'left'}
              backgroundColor={'#FFF'}
              border={'1px solid #0000001A'}
              borderRadius={'8px'}
              color={'#085096'}
              fontSize={'16px'}
              lineHeight={'20px'}
            >
              <span className={styles.menuButton}>
                {selectedLocale === ''
                  ? 'Pick an option'
                  : LOCALES?.[selectedLocale]?.title}
              </span>
            </MenuButton>
            <MenuList>
              {Object.keys(LOCALES).map((key) => (
                <MenuItem
                  key={key}
                  onClick={() => setSelectedLocale(key)}
                  className={styles.menuButton}
                >
                  {LOCALES[key].title}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>

          <Flex justifyContent={'flex-end'} w={'100%'}>
            <Button
              colorScheme='blue'
              onClick={handleCloseModal}
              w={'100%'}
              h={'56px'}
              color={'#F9FAFB'}
              backgroundColor={'#0A64BC'}
              borderRadius={'8px'}
              fontSize={'16px'}
              lineHeight={'24px'}
              fontWeight={600}
            >
              {selectedLocale === ''
                ? 'Close'
                : LOCALES?.[selectedLocale]?.buttonText}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default LocaleModal;
