import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { FC } from "react";

export interface ReportErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ReportErrorModal: FC<ReportErrorModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="full" isCentered>
        <ModalOverlay sx={{ background: "#3a83c9a8" }} />
        <ModalContent
          bg="#F9FaFB"
          borderRadius="20px"
          mt={5}
          mx={"5"}
          width={"full"}
          height={"full"}
        >
          <ModalCloseButton size={"lg"} />
          <ModalBody margin={0} padding={0}>
            <iframe
              title="report-error"
              src="https://forms.monk.st/bug-report/"
              width="100%"
              height="100%"
              style={{
                border: "none",
                borderRadius: "20px",
              }}
              allowFullScreen={false}
            ></iframe>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
