export function iOSSafari(userAgent) {
    return ( /iP(ad|od|hone)/i.test(userAgent) &&
      /WebKit/i.test(userAgent) &&
      !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent)) ) || /(iPhone|Safari|iOS)/i.test(userAgent);
}

export function scrollToTop(duration = 1000) {
  const scrollY = window.scrollY; // Get current scroll position

  // Define easing function for animation (optional)
  const easeInOutQuad = (t) => t<.5 ? 2*t*t : 1-Math.pow(-2*(t-1), 2)/2;

  const startTime = Date.now();

  const animate = () => {
    const now = Date.now();
    const progress = (now - startTime) / duration;
    const easedProgress = easeInOutQuad(progress);
    const newScrollY = scrollY * (1 - easedProgress);

    window.scrollTo(0, newScrollY);

    if (progress < 1) {
      requestAnimationFrame(animate);
    }
  };

  animate();
}