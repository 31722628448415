import { googleToYahooExchangeMapping } from "../constants/foreignExchanges";

export const getYahooFullTicker = (stockTicker, exchangeTicker) => {
  if (
    !exchangeTicker ||
    exchangeTicker === "NYSE" ||
    exchangeTicker === "NYSEAMERICAN" ||
    exchangeTicker === "NASDAQ" ||
    exchangeTicker === "OTCMKTS" ||
    exchangeTicker === "NYSEARCA"
  ) {
    return stockTicker;
  }

  const yahooExchangeCode = googleToYahooExchangeMapping[exchangeTicker] || "";
  return stockTicker + yahooExchangeCode;
};
