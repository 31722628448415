import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Flex, Text } from '@chakra-ui/react';

import { leftSideBarMenu, submenu } from '../../../constants/menu';

type TProps = {
  currentIdx: number;
  setCurrentIdx: (value: React.SetStateAction<number>) => void;
};

function LeftHomeSubView({ currentIdx = 0, setCurrentIdx }: TProps) {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  let activeMenu = 'analysis';

  if (tab && leftSideBarMenu.findIndex((item) => item.name === tab) === -1) {
    activeMenu = 'analysis';
  } else if (tab) {
    activeMenu = tab;
  }

  const activeItem = leftSideBarMenu.find((item) => item.name === activeMenu);

  const Icon = activeItem?.icon as React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;

  const tabSubMenu = submenu[activeMenu as string];

  return (
    <Flex minW='240px' flexDirection='column' alignItems='center' gap='20px'>
      <Box>
        <Box
          borderRadius='50%'
          backgroundColor='#E9EAEB'
          p='15px'
          color='#fff'
          mb='10px'
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          w='100px'
          h='100px'
        >
          <Icon
            width={activeMenu === 'screener' ? '60px' : '70px'}
            height={activeMenu === 'screener' ? '60px' : '70px'}
          />
        </Box>
        <Text
          fontSize='16px'
          fontWeight='600'
          lineHeight='24px'
          textAlign='center'
        >
          {activeItem?.title}
        </Text>
      </Box>

      <Flex flexDirection='column' gap='10px' width='100%'>
        {tabSubMenu.map((subItem, idx) => {
          if (subItem?.hidden) {
            return null;
          }

          const SubIcon = subItem.icon;

          return (
            <Button
              key={subItem.name}
              alignItems='center'
              justifyContent='flex-start'
              columnGap='10px'
              borderRadius='10px'
              backgroundColor={currentIdx === idx ? '#FFF' : 'transparent'}
              px='10px'
              py='10px'
              width='100%'
              height='auto'
              onClick={() => setCurrentIdx(idx)}
            >
              <Box
                backgroundColor={currentIdx === idx ? '#085096' : 'transparent'}
                color={currentIdx === idx ? '#FFF' : '#999A9B'}
                p='4px'
                borderRadius='5px'
              >
                <SubIcon width='24px' height='24px' />
              </Box>
              <Text
                color='#1C1C1C'
                fontWeight='400'
                fontSize='14px'
                lineHeight='21px'
              >
                {subItem.title}
              </Text>
            </Button>
          );
        })}
      </Flex>
    </Flex>
  );
}

export default React.memo(LeftHomeSubView);
