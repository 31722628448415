import { Box, Flex, Text } from "@chakra-ui/react";
import { useCallback } from "react";
import { useFetchCashflowDataQuery } from "../../../services/stockApi";
import { ICompanyRatios } from "../../../types/Chart";
import { FormatChartNumber } from "../../../utils/formatChartNumber";
import GrowthBadge from "../../common/badges/GrowthBadge";
import CandlestickChart from "../CandlestickChart/CandleStickChart";
import { useSelector } from "react-redux";
import CompanyMetaRow from "../../../subviews/RightSubview/Stocks/CompanyMetaRow";
import {
  actualRecentCompanyRatios,
  actualRatios,
  actualStock,
} from "../../../store/slices/stock";
import { useParams } from "react-router-dom";

interface GrowthCardProps {
  activeChart: string;
}

const tabs = {
  FCF: "FCFLTM",
  "Net Income": "NetIncomeLTM",
  Revenue: "RevenueLTM",
  "Operating Income": "OperatingIncomeLTM",
  "Operating Cash Flow": "OperatingCashFlowLTM",
  "Capital Expenditure": "CapExLTM",
  "Long Term Reinvestment Rate": "longTermReinvestmentRate",
  "R&D to Enterprise Value": "rdToEnterpriseValue",
  "LTM FCF Growth As Percentage Of Revenue":
    "ltmFCFGrowthAsPercentageOfRevenue",
  "R&D Index Score": "rdIndexScore",
};

const getDataByYear = (
  data: ICompanyRatios[] | undefined,
  year: number,
  key:
    | "FCFLTM"
    | "NetIncomeLTM"
    | "RevenueLTM"
    | "OperatingIncomeLTM"
    | "OperatingCashFlowLTM"
    | "CapExLTM"
    | "longTermReinvestmentRate"
    | "rdToEnterpriseValue"
    | "ltmFCFGrowthAsPercentageOfRevenue"
    | "rdIndexScore"
) => {
  if (!data || data.length < year * 4) return 0;
  return data[year * 4]?.[key] || 0;
};

const getBarsData = (
  data: ICompanyRatios[] | undefined,
  key:
    | "FCFLTM"
    | "NetIncomeLTM"
    | "RevenueLTM"
    | "OperatingIncomeLTM"
    | "OperatingCashFlowLTM"
    | "CapExLTM"
    | "longTermReinvestmentRate"
    | "rdToEnterpriseValue"
    | "ltmFCFGrowthAsPercentageOfRevenue"
    | "rdIndexScore"
) => {
  if (!data) return [0, 0, 0];
  return [
    ((getDataByYear(data, 0, key) - getDataByYear(data, 1, key)) * 100) /
      Math.abs(getDataByYear(data, 1, key)),
    ((getDataByYear(data, 1, key) - getDataByYear(data, 2, key)) * 100) /
      Math.abs(getDataByYear(data, 2, key)),
    ((getDataByYear(data, 2, key) - getDataByYear(data, 3, key)) * 100) /
      Math.abs(getDataByYear(data, 3, key)),
  ];
};

const GrowthCard = ({ activeChart }: GrowthCardProps) => {
  const { ticker, exchangeTicker } = useParams();

  const companyRatios = useSelector(actualRecentCompanyRatios);
  const mostRecentRatio = useSelector(actualRatios);

  const stock = useSelector(actualStock);

  const lastUpdate = new Date(stock?.lastScoreUpdateDate || Date.now())
    ?.toLocaleDateString()
    ?.replaceAll("/", "-")
    ?.split("-")
    .reverse()
    .join("-");

  const { data: cashflowData } = useFetchCashflowDataQuery(
    `${exchangeTicker}:${ticker}`
  );

  const sign = "%";

  const getChartDetails = useCallback(() => {
    if (!companyRatios || companyRatios.length === 0)
      return {
        chartNumber: 0,
        barsData: [0, 0, 0],
        oneYearMetric: 0,
        chartData: [],
      };

    const chartNumber = FormatChartNumber({
      payload:
        companyRatios[0][tabs[activeChart]] !== undefined
          ? companyRatios[0][tabs[activeChart]]
          : 0,
    });

    const barsData = getBarsData(companyRatios, tabs[activeChart]);
    const oneYearMetric = barsData[0] || 0;

    const ratiosData = mostRecentRatio?.statementsDate
      ? [mostRecentRatio, ...companyRatios]
      : companyRatios;

    const chartData = ratiosData
      ?.map((val, i) => ({
        time: i,
        value: val[tabs[activeChart]] || 0,
        yearQ: `${val.calendarYear}`,
        date: new Date(i === 0 ? val.ratiosUpdateDate : val.statementsDate)
          ?.toLocaleDateString()
          ?.replaceAll("/", "-")
          ?.split("-")
          .reverse()
          .join("-"),
      }))
      .reverse();

    return {
      chartNumber,
      barsData,
      oneYearMetric,
      chartData,
    };
  }, [activeChart, companyRatios, mostRecentRatio]);

  return (
    <Box bgColor="#FFF" w="100%" h="100%">
      <Box
        position="relative"
        my={"10px"}
        backgroundColor={"#FFF"}
        borderRadius={"20px"}
        id="GrowthCard"
      >
        <Flex
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap="10px"
          ml="10px"
        >
          <Text
            as={"p"}
            fontSize={"24px"}
            lineHeight={"36px"}
            fontWeight={500}
            display={"flex"}
            alignItems={"flex-end"}
          >
            {getChartDetails().chartNumber}
            <Text fontSize={"12px"} lineHeight={"28px"}>
              &nbsp;
              {cashflowData &&
                cashflowData?.length > 0 &&
                cashflowData[0].reportedCurrency}
            </Text>
          </Text>
        </Flex>

        <Box mb="20px" ml="10px">
          <GrowthBadge
            vector={getChartDetails().oneYearMetric > 0 ? "up" : "down"}
            meta={`${
              getChartDetails().oneYearMetric > 0 ? "+" : ""
            }${getChartDetails().oneYearMetric?.toFixed(
              activeChart === "Net Income" ? 1 : 0
            )}${sign} last 1 year`}
          />
        </Box>

        <Box>
          <CandlestickChart
            data={getChartDetails().chartData}
            lessCurved
            activeChart={activeChart}
          />
        </Box>

        <CompanyMetaRow
          compData={stock}
          lastUpdate={lastUpdate}
          idElementForScreenshot={`GrowthScoreCard`}
          isChart
          styledBox
        />
      </Box>
    </Box>
  );
};

export default GrowthCard;
