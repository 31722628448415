const data = [
  {
    id: 1,
    name: "Market cap",
    slug: "mktCapUSD",
    withIcon: true,
    currency: "USD",
    infoType: "size",
    meta: "large",
  },
  {
    id: 2,
    name: "Volatility",
    slug: "volatility",
    withIcon: true,
    currency: null,
    infoType: "size",
    meta: "low volatility",
  },
  {
    id: 3,
    name: "Revenue",
    slug: "revenue",
    withIcon: true,
    currency: "NAN",
    infoType: "growth",
    meta: "10% last 12m",
  },
  {
    id: 4,
    name: "Operating Income",
    slug: "operating_income",
    withIcon: true,
    currency: "NAN",
    infoType: "growth",
    meta: "10% last 12m",
  },
  {
    id: 5,
    name: "Net Income",
    slug: "net_income",
    withIcon: true,
    currency: "NAN",
    infoType: "growth",
    meta: "8% last 12m",
  },
  {
    id: 6,
    name: "FCF",
    slug: "free_cash_flow",
    withIcon: true,
    currency: "NAN",
    infoType: "growth",
    meta: "10% last 12m",
  },
];

export { data };
