import { Box, Flex, Text } from "@chakra-ui/react";
import { useCallback } from "react";
import CandlestickChart from "../CandlestickChart/CandleStickChart";
import { FormatChartNumber } from "../../../utils/formatChartNumber";
import GrowthBadge from "../../common/badges/GrowthBadge";
import { ICompanyRatios } from "../../../types/Chart";
import { useSelector } from "react-redux";
import {
  actualRecentCompanyRatios,
  actualRatios,
  actualStock,
} from "../../../store/slices/stock";
import CompanyMetaRow from "../../../subviews/RightSubview/Stocks/CompanyMetaRow";

const tabs = [
  { tab: "Dividend Yield", longName: "Dividend Yield" },
  { tab: "Buyback Ratio", longName: "Buyback Ratio" },
  { tab: "Debt Paydown", longName: "Debt Paydown Yield" },
  { tab: "Shareholder Yield", longName: "Shareholder Yield" },
];

interface ShareholderCardProps {
  activeChart: string;
}

const getDataByYear = (
  data: ICompanyRatios[] | undefined,
  year: number,
  key:
    | "buybackYield"
    | "debtPaydownYield"
    | "dividendYield"
    | "shareholderYield"
) => {
  if (!data || data.length < year * 4) return 0;
  return (
    data
      .slice(4 * year, 4 + 4 * year)
      .reduce((prev, curr) => prev + (curr[key] || 0), 0) * 100
  );
};

const getBarsData = (
  data: ICompanyRatios[] | undefined,
  key:
    | "buybackYield"
    | "debtPaydownYield"
    | "dividendYield"
    | "shareholderYield"
) => {
  if (!data) return [0, 0, 0];
  return [
    ((getDataByYear(data, 0, key) - getDataByYear(data, 1, key)) * 100) /
      Math.abs(getDataByYear(data, 1, key)),
    ((getDataByYear(data, 1, key) - getDataByYear(data, 2, key)) * 100) /
      Math.abs(getDataByYear(data, 2, key)),
    ((getDataByYear(data, 2, key) - getDataByYear(data, 3, key)) * 100) /
      Math.abs(getDataByYear(data, 3, key)),
  ];
};

const ShareholderCard = ({ activeChart }: ShareholderCardProps) => {
  const companyRatios = useSelector(actualRecentCompanyRatios);

  const mostRecentRatio = useSelector(actualRatios);

  const getChartDetails = useCallback(() => {
    if (!companyRatios || companyRatios.length === 0)
      return {
        chartNumber: 0,
        barsData: [0, 0, 0],
        oneYearMetric: 0,
        chartData: [],
      };

    const dict = {
      "Dividend Yield": "dividendYield",
      "Buyback Ratio": "buybackYield",
      "Debt Paydown": "debtPaydownYield",
      "Shareholder Yield": "shareholderYield",
    };

    const chartNumber = FormatChartNumber({
      payload:
        mostRecentRatio == null || mostRecentRatio[dict[activeChart]] == null
          ? companyRatios[0][dict[activeChart]] !== undefined
            ? companyRatios[0][dict[activeChart]] * 100
            : 0
          : mostRecentRatio[dict[activeChart]] * 100,
    });

    const barsData = getBarsData(companyRatios, dict[activeChart]);
    const oneYearMetric = barsData[0] || 0;

    const ratiosData =
      mostRecentRatio == null
        ? companyRatios
        : [mostRecentRatio, ...companyRatios];
    const chartData = ratiosData
      ?.map((val, i) => ({
        time: i,
        value: (val[dict[activeChart]] || 0) * 100,
        yearQ: `${val.calendarYear}`,
        date: new Date(i === 0 ? val.ratiosUpdateDate : val.statementsDate)
          ?.toLocaleDateString()
          ?.replaceAll("/", "-")
          ?.split("-")
          .reverse()
          .join("-"),
      }))
      .reverse();

    return {
      chartNumber,
      barsData,
      oneYearMetric,
      chartData,
    };
  }, [activeChart, companyRatios, mostRecentRatio]);

  const sign = "bps";

  const stock = useSelector(actualStock);

  const lastUpdate = new Date(stock?.lastScoreUpdateDate || Date.now())
    ?.toLocaleDateString()
    ?.replaceAll("/", "-")
    ?.split("-")
    .reverse()
    .join("-");

  return (
    <Box bgColor="#F9FAFB" w="100%" h="100%">
      <Box
        position="relative"
        my={"10px"}
        backgroundColor={"#FFF"}
        borderRadius={"20px"}
        id="ShareholderCard"
      >
        <Flex
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap="10px"
          ml="10px"
        >
          <Text
            as={"p"}
            fontSize={"24px"}
            lineHeight={"36px"}
            fontWeight={500}
            display={"flex"}
            alignItems={"flex-end"}
          >
            {getChartDetails().chartNumber}
            <Text fontSize={"12px"} lineHeight={"28px"}>
              %
            </Text>
          </Text>
        </Flex>

        {!Number.isNaN(getChartDetails().oneYearMetric) && (
          <Box mb="20px" ml="10px">
            <GrowthBadge
              vector={getChartDetails().oneYearMetric > 0 ? "up" : "down"}
              meta={`${
                getChartDetails().oneYearMetric > 0 ? "+" : ""
              }${Math.round(getChartDetails().oneYearMetric)?.toFixed(
                0
              )}${sign} last 1 year`}
            />
          </Box>
        )}

        <Box>
          <CandlestickChart
            data={getChartDetails().chartData}
            activeChart={activeChart}
          />
        </Box>

        <CompanyMetaRow
          compData={stock}
          lastUpdate={lastUpdate}
          idElementForScreenshot={`ShareholderScoreCard`}
          isChart
          styledBox
        />
      </Box>
    </Box>
  );
};

export default ShareholderCard;
