import { Box, Text, useDisclosure } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { BiCheck } from "react-icons/bi";
import { RxCopy } from "react-icons/rx";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import errorIcon from "../../assets/svg/ErrorIcon.svg";
import formulaIcon from "../../assets/svg/formulaIcon.svg";
import eyeicon from "../../assets/svg/eyeblue.svg";
import casesolar from "../../assets/svg/caseblue.svg";
import { bugReportUrl } from "../../constants/menu";
import { MODAL_TYPES_ENUM } from "../../constants/variables";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";
import { openModal as openStoreModal } from "../../store/slices/modal";
import { ReportErrorModal } from "../Modals/ReportErrorModal";
import { TooltipModalContext } from "./TooltipModal";
import { Add2WatchlistModal } from "../Modals/Add2Watchlist";
import { IStock } from "../../types/Stock";


const ActionsSection = ({stock}:{stock:IStock}) => {
  const dispatch = useDispatch();
  const { isCopied, copyToClipboard } = useCopyToClipboard(true, 3000);

  const { openModal, updateModalData } = useContext(TooltipModalContext);

  const [isOpen, setIsOpen] = useState(false);

  const { isOpen: isOpenWatchlist, onClose, onOpen } = useDisclosure()
  const [isWatchlist, setIsWatchlist] = useState(true);

  const handleCopyClick = () => {
    copyToClipboard(window.location.href);
  };

  const handleOpenScientificExplanationModal = () => {
    updateModalData({
      titleKey: "Scientific Explanation",
      bodyKey: ["scientific_modal_description"],
    });
    openModal();
  };

  const handleOpenWatchlistPortfolioModal = (isWatchlist: boolean) => {
    const accessToken = localStorage.getItem('accessToken')
    if(accessToken) {
      setIsWatchlist(isWatchlist)
      onOpen()
    }else {
      dispatch(openStoreModal({ name: "User", link: "", type: "user" }));
    }
   
  };

  return (
    <Box w={"100%"} mb="60px">
      <Text
        fontSize="24px"
        fontWeight={600}
        lineHeight="36px"
        fontFamily="Poppins"
        mb={4}
        ms={1}
      >
        <FormattedMessage id="action_title" defaultMessage="Actions" />
        <Text
          fontSize="16px"
          fontWeight={300}
          lineHeight="24px"
          fontFamily="Poppins"
        >
          <FormattedMessage
            id="action_subtext"
            defaultMessage="Here are a few things you might want to do now."
          />
        </Text>
      </Text>
      {/* <Box
        w="100%"
        display="flex"
        mb={3}
        p={6}
        backgroundColor="#F9FAFB"
        borderRadius="16px"
      >
        <img src={favouriteIcon} alt="" />
        <Text fontWeight={500} ms={5} fontSize="16px" lineHeight="24px">
        <FormattedMessage
          id="action_item1"
          defaultMessage="Add to favorites"
        />
        </Text>
      </Box> */}
      <Box
        w="100%"
        display="flex"
        mb={3}
        p={6}
        backgroundColor="#F9FAFB"
        borderRadius="16px"
        onClick={handleCopyClick}
        cursor="pointer"
      >
        {isCopied ? (
          <BiCheck size={25} color="#58CC02" />
        ) : (
          <RxCopy size={25} color="#0A64BC" />
        )}

        <Text
          fontWeight={500}
          ms={5}
          fontSize="16px"
          lineHeight="24px"
          {...(isCopied ? { color: "#58CC02" } : {})}
        >
          {isCopied ? (
            <FormattedMessage
              id="action_item2_completed"
              defaultMessage="Copied this link"
            />
          ) : (
            <FormattedMessage
              id="action_item2"
              defaultMessage="Copy this link"
            />
          )}
        </Text>
      </Box>
      <Box
        w="100%"
        display="flex"
        mb={3}
        p={6}
        backgroundColor="#F9FAFB"
        borderRadius="16px"
        onClick={handleOpenScientificExplanationModal}
        cursor="pointer"
      >
        <img src={formulaIcon} alt="" />
        <Text fontWeight={500} ms={5} fontSize="16px" lineHeight="24px">
          <FormattedMessage
            id="action_item3"
            defaultMessage="See the science behind"
          />
        </Text>
      </Box>
      <Box
        w="100%"
        display="flex"
        mb={3}
        p={6}
        backgroundColor="#F9FAFB"
        borderRadius="16px"
        cursor="pointer"
        onClick={() =>
          dispatch(
            openStoreModal({
              name: "Report an error",
              link: bugReportUrl,
              type: MODAL_TYPES_ENUM.iframe,
            })
          )
        }
      >
        <img src={errorIcon} alt="" />
        <Text fontWeight={500} ms={5} fontSize="16px" lineHeight="24px">
          <FormattedMessage
            id="action_item4"
            defaultMessage="Report an error"
          />
        </Text>
      </Box>
      <Box
        w="100%"
        display="flex"
        mb={3}
        p={6}
        backgroundColor="#F9FAFB"
        borderRadius="16px"
        onClick={() => { handleOpenWatchlistPortfolioModal(true) }}
        cursor="pointer"
      >
        <img src={eyeicon} alt="" />
        <Text fontWeight={500} ms={5} fontSize="16px" lineHeight="24px">
          <FormattedMessage
            id="action_item5"
            defaultMessage="Add to my watchlist"
          />
        </Text>
      </Box>
      <Box
        w="100%"
        display="flex"
        mb={3}
        p={6}
        backgroundColor="#F9FAFB"
        borderRadius="16px"
        onClick={() => { handleOpenWatchlistPortfolioModal(false) }}
        cursor="pointer"
      >
        <img src={casesolar} alt="" />
        <Text fontWeight={500} ms={5} fontSize="16px" lineHeight="24px">
          <FormattedMessage
            id="action_item6"
            defaultMessage="Add to my portfolio"
          />
        </Text>
      </Box>
      <ReportErrorModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      {isOpenWatchlist && <Add2WatchlistModal
        isOpen={isOpenWatchlist}
        companyName={stock.companyName ?? stock.exchangeShortName ?? stock.ticker ?? ""}
        ticker={stock.ticker}
        is={isWatchlist ? "watchlist": "portfolio"}
        onClose={onClose}></Add2WatchlistModal>}
    </Box>
  );
};

export default ActionsSection;
