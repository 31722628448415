import { Box, Flex, Text, Button } from "@chakra-ui/react";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import GrowthBadge from "../../common/badges/GrowthBadge";
import GenericChart from "../GenericChartScores/GenericChart";
import {
  FormatChartNumber,
  handleTitleSubtext,
} from "../../../utils/formatChartNumber";
import { EntryBoxChart } from "../EntryChart/Box";
import CompanyMetaRow from "../../../subviews/RightSubview/Stocks/CompanyMetaRow";
import { useDispatch, useSelector } from "react-redux";
import {
  actualScore,
  actualStock,
  openActiveChart,
} from "../../../store/slices/stock";
import { useScaleCompanyRatio } from "../../../hooks/useData";
import "./MonkScoreChartCard.css";

const ValuationChartCard = () => {
  const data = useSelector(actualScore);
  const stock = useSelector(actualStock);

  const dispatch = useDispatch();

  const onClickChart = (name: string) => {
    setTimeout(() => {
      dispatch(
        openActiveChart({
          type: "valuation",
          active: name,
        }) as any
      );
    }, 100);
  };

  const getChartValuation = useCallback(() => {
    if (data) {
      return data.valuationScoreTimeline.length > 0
        ? data.valuationScoreTimeline
        : [];
    }
    return [];
  }, [data]);

  const sign = "%";

  const oneYearMetric = useCallback(() => {
    if (data) {
      const valuation = data.valuationScoreTimeline;
      return Math.floor(
        valuation.length > 0 ? valuation[valuation.length - 1].percentile : 0
      );
    }
    return 0;
  }, [data]);

  const lastUpdate = new Date(stock?.lastScoreUpdateDate || Date.now())
    ?.toLocaleDateString()
    ?.replaceAll("/", "-")
    ?.split("-")
    .reverse()
    .join("-");

  const { chartData } = useScaleCompanyRatio();

  const intl = useIntl();

  return (
    <Box w="100%" h="auto" m="auto" display={"flex"} flexDirection={"column"}>
      <Text as={"p"} fontSize={"14px"} lineHeight={"21px"} fontWeight={700}>
        <FormattedMessage
          id="factors_generic"
          values={{
            name: stock?.companyName ?? "",
            quality: handleTitleSubtext(stock?.monkScore),
            card: "valuation",
          }}
        />{" "}
        <Text as="span" fontSize={"14px"} lineHeight={"21px"} fontWeight={400}>
          compared to its peers.
        </Text>
      </Text>

      <Box bgColor="#F9FAFB" id="ValuationScoreCard">
        <Box
          position="relative"
          my={"10px"}
          backgroundColor={"#FFF"}
          borderRadius={"20px"}
          pt="20px"
          pb="2px"
        >
          <Box px="20px">
            <Flex
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              gap="10px"
            >
              <Text
                as={"p"}
                fontSize={"24px"}
                lineHeight={"36px"}
                fontWeight={500}
                display={"flex"}
                alignItems={"flex-end"}
              >
                {FormatChartNumber({
                  payload: Math.floor(oneYearMetric()),
                })}{" "}
                / 100
              </Text>

              <Button
                width={"auto"}
                fontSize={"14px"}
                lineHeight={"21px"}
                fontWeight={400}
                display={"flex"}
                alignItems={"center"}
                variant={"unstyled"}
                p="0"
                height={"auto"}
                justifyContent={"flex-end"}
                flexWrap={"wrap"}
                wordBreak={"break-all"}
                textDecoration="none"
                columnGap={"4px"}
              >
                <Text display={"flex"} alignItems={"center"}>
                  <FormattedMessage
                    id="evolution"
                    values={{
                      name: "Health",
                    }}
                  />{" "}
                </Text>
              </Button>
            </Flex>

            <Box mb="20px">
              <GrowthBadge
                vector={oneYearMetric() > 0 ? "up" : "down"}
                meta={`${
                  oneYearMetric() > 0 ? "+" : ""
                }${oneYearMetric()?.toFixed(2)}${sign} last 1 year`}
              />
            </Box>

            <Box>
              <GenericChart data={getChartValuation()} />
            </Box>
          </Box>

          <Box px="10px">
            {stock && (
              <CompanyMetaRow
                compData={stock}
                lastUpdate={lastUpdate}
                idElementForScreenshot={`ValuationScoreCard`}
                isChart
                styledBox
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box w="100%" my="10px">
        <Text
          id="message-container"
          as={"p"}
          fontSize={"14px"}
          lineHeight={"21px"}
        >
          <FormattedMessage id="factor_below_prefix" />
          <FormattedMessage
            id="factor_metric_company"
            values={{
              name: stock?.companyName ?? "",
            }}
          >
            {(txt) => <span className="highlight">{txt}</span>}
          </FormattedMessage>
          <FormattedMessage
            id="factor_subfix"
            values={{
              metric_name: intl.formatMessage({ id: "valuation_name" }),
            }}
          />
        </Text>
      </Box>

      <Box bgColor="#F9FAFB" id="ValuationCardRatios">
        <Box
          my={"10px"}
          backgroundColor={"#FFF"}
          borderRadius={"20px"}
          pt="20px"
          pb="2px"
          display={"flex"}
          flexDirection={"column"}
        >
          <Box px="20px">
            <Text
              my={"20px"}
              fontWeight={600}
              fontSize={"16px"}
              lineHeight={"24px"}
            >
              Price Percentiles
            </Text>
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="Price / Sale"
              data={chartData("P/S")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="Price / Earnings"
              data={chartData("PER")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="Price / FCF"
              data={chartData("P/FCF")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="Price / Sales Growth"
              data={chartData("PEG")}
            />
            <Text
              my={"20px"}
              fontWeight={600}
              fontSize={"16px"}
              lineHeight={"24px"}
            >
              Enterprise Value Percentiles
            </Text>
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="EV / Revenue"
              data={chartData("EV/Revenue")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue={false}
              name="EV / Revenue Growth"
              data={chartData("PEG")}
            />
          </Box>

          <Box px="10px">
            {stock && (
              <CompanyMetaRow
                compData={stock}
                lastUpdate={lastUpdate}
                idElementForScreenshot={`ValuationCardRatios`}
                isChart
                styledBox
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ValuationChartCard;
