import { Box, Button, Flex, Text, useBreakpoint } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useFetchStockDataQuery,
  useFetchTimeSeriesDailyAdjustedDataQuery,
} from '../../../services/stockApi';
import BottomQuoteCard from '../bottomQuoteCard';
import CandlestickChart from '../CandlestickChart/CandleStickChart';
import GraphsBottomBar from '../GraphsBottomBar/GraphsBottomBar';
import { useCallback, useState } from 'react';
import TooltipModal from '../tooltipModal/tooltipModal';
import Jesse_Livermore from '../../../assets/image/Jesse_Livermore.png';
import { IPriceInfo } from '../../../types/Core';
import { FormatChartNumber } from '../../../utils/formatChartNumber';
import GrowthBadge from '../../common/badges/GrowthBadge';
import { ReactComponent as OutlineInfoIcon } from '../../../assets/svg/outline-info.svg';

interface MomentumCardProps {
  fullTicker: string;
}

const getTimeSeriesByQuarter = (
  timeSeriesData: IPriceInfo[] | undefined,
  limit: number
) => {
  if (!timeSeriesData) return [];

  let d = new Date();
  d.setMonth(d.getMonth() - limit);
  let timeSeriesDataArr = timeSeriesData
    .filter((price) => new Date(price.date) >= d)
    .map((price) => ({
      date: new Date(price.date)
        ?.toLocaleDateString()
        ?.replaceAll('/', '-')
        ?.split('-')
        .reverse()
        .join('-'),
      close: price.close,
    }));
  return timeSeriesDataArr;
};

const MomentumCard = ({ fullTicker }: MomentumCardProps) => {
  const { data: compData } = useFetchStockDataQuery(fullTicker);
  const { data: timeSeriesData } =
    useFetchTimeSeriesDailyAdjustedDataQuery(fullTicker);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const breakpoint = useBreakpoint();
  const isMobile = ['base', 'sm'].includes(breakpoint);

  const quarterData = useCallback(
    () => ({
      month6Data: getTimeSeriesByQuarter(timeSeriesData, 6),
      month9Data: getTimeSeriesByQuarter(timeSeriesData, 9),
      month12Data: getTimeSeriesByQuarter(timeSeriesData, 12),
    }),
    [timeSeriesData]
  );

  const momentumBarsData = [
    ((Number(
      quarterData().month6Data.length ? quarterData().month6Data[0].close : 0
    ) -
      Number(
        quarterData().month6Data.length
          ? quarterData().month6Data[quarterData().month6Data.length - 1].close
          : 0
      )) *
      100) /
      Number(
        quarterData().month6Data.length
          ? quarterData().month6Data[quarterData().month6Data.length - 1].close
          : 0
      ),
    ((Number(
      quarterData().month9Data.length ? quarterData().month9Data[0].close : 0
    ) -
      Number(
        quarterData().month9Data.length
          ? quarterData().month9Data[quarterData().month9Data.length - 1].close
          : 0
      )) *
      100) /
      Number(
        quarterData().month9Data.length
          ? quarterData().month9Data[quarterData().month9Data.length - 1].close
          : 0
      ),
    ((Number(
      quarterData().month12Data.length ? quarterData().month12Data[0].close : 0
    ) -
      Number(
        quarterData().month12Data.length
          ? quarterData().month12Data[quarterData().month12Data.length - 1]
              .close
          : 0
      )) *
      100) /
      Number(
        quarterData().month12Data.length
          ? quarterData().month12Data[quarterData().month12Data.length - 1]
              .close
          : 0
      ),
  ];

  const formattedDailySeriesData = getTimeSeriesByQuarter(timeSeriesData, 12)
    ?.map((report, i) => {
      return {
        time: i,
        value: report.close,
        date: report.date,
      };
    })
    ?.reverse();

  const oneYearMetric = momentumBarsData?.[2];

  const sign = '%';

  return (
    <Box bgColor='#F9FAFB' id="MomentumCard">
      <Box
        position='relative'
        my={'10px'}
        backgroundColor={'#FFF'}
        borderRadius={'20px'}
        p='20px'
      >
        <Flex
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          gap='10px'
        >
          <Text
            as={'p'}
            fontSize={'24px'}
            lineHeight={'36px'}
            fontWeight={500}
            display={'flex'}
            alignItems={'flex-end'}
          >
            {momentumBarsData?.length > 0 && momentumBarsData[2] > 0 ? '+' : ''}
            {FormatChartNumber({
              payload: isNaN(momentumBarsData[2]) ? 0 : momentumBarsData[2],
            })}
            <Text fontSize={'12px'} lineHeight={'28px'}>
              %
            </Text>
          </Text>

          <Button
            width={'auto'}
            fontSize={'14px'}
            lineHeight={'21px'}
            fontWeight={400}
            display={'flex'}
            alignItems={'center'}
            variant={'unstyled'}
            p='0'
            height={'auto'}
            justifyContent={'flex-end'}
            flexWrap={'wrap'}
            wordBreak={'break-all'}
            textDecoration='underline'
            textDecorationStyle={'dashed'}
            textDecorationColor={'#c9cacb'}
            textDecorationThickness={'from-font'}
            textUnderlineOffset={'4px'}
            columnGap={'4px'}
            onClick={() => setIsOpen(true)}
          >
            <Text display={'flex'} alignItems={'center'}>
              <FormattedMessage id='momentum_chart_title' />
              <OutlineInfoIcon
                style={{
                  textDecoration: 'none',
                  marginLeft: '6px',
                }}
              />
            </Text>
          </Button>
        </Flex>
        <Box mb='20px'>
          <GrowthBadge
            vector={oneYearMetric > 0 ? 'up' : 'down'}
            meta={`${oneYearMetric > 0 ? '+' : ''}${oneYearMetric?.toFixed(
              1
            )}${sign} last 1 year`}
          />{' '}
        </Box>
        <Box>
          <CandlestickChart
            data={formattedDailySeriesData}
            lessCurved
            activeChart='momentum'
          />
        </Box>

        <GraphsBottomBar
          graphName='momentum'
          percentageDifference3Year={momentumBarsData}
        />
      </Box>
      {/* <BottomQuoteCard
        image={Jesse_Livermore}
        textId={"momentum_quote"}
        personName="Jesse Livermore"
      /> */}
      <TooltipModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        activeChart='stockPrice'
      />
    </Box>
  );
};

export default MomentumCard;
