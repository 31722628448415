import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    primary: {
      400: "#3A83C9",
      500: "#0A64BC",
    },
  },
  components: {
    Button: {
      sizes: {
        custom: {
          h: "44px",
          w: "159px",
          fontSize: "12px",
          borderRadius: "20px !important",
          padding: "10px, 15px, 10px, 15px",
          gap: "10px",
        },
      },
      variants: {
        "with-shadow": {
          bg: "red.400",
          boxShadow: "0 0 2px 2px #efdfde",
        },
        "monkBox":{
          h: "44px",
          w: "159px",
          fontSize: "12px",
          borderRadius: "20px !important",
          padding: "10px, 15px, 10px, 15px",
          gap: "10px",
          fontWeight: "600",
          lineHeight: "24px",
          backgroundColor:'#FFF',
          color:'#085096'
        }
      },
    },
  },
});

export const buttonMediumProps = {
  width: "159px !important",
  height: "44px !important",
  borderRadius: "20px !important",
  padding: "10px, 15px, 10px, 15px",
  gap: "10px",
};

export const textMediumButtonProps = {
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "24px",
};

export const textSmallProps = {
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "20px",
};
