import { useToast } from "@chakra-ui/react"

interface PropsHook {
    titleSuccess: string
    descriptionSuccess: string
    titleError: string
    descriptionError: string
    titlePending: string
    descriptionPending: string

}
export const usePromisedToast = () => {
    const toast = useToast()

    const wrapperPromise = (promise: Promise<any>, { titleSuccess,
        titleError,
        titlePending,
        descriptionSuccess,
        descriptionError,
        descriptionPending
     }: PropsHook) => {
        toast.promise(promise, {
            success: { title: titleSuccess, description: descriptionSuccess },
            error: { title: titleError, description: descriptionError },
            loading: { title: titlePending, description: descriptionPending },
        })
    }

    return {
        wrapperPromise
    }
}