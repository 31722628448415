import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Watchlist, WatchlistOne } from "../types/Watchlist";

export const watchlistPortfolioApi = createApi({
  reducerPath: "watchlistPortfolioData",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/api/v1/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        const replacedToken = token.replace(/['"]+/g, "").trim();
        headers.set("authorization", replacedToken);
        headers.set("token", replacedToken);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    fetchWatchlistData: builder.query({
      query: (is:"watchlist"|"portfolio"="watchlist") => `${is}/`,
      transformResponse: (response: { data: Array<Watchlist> }, meta, arg) =>
        response.data,
    }),
    fetchPortfolioData: builder.query<Watchlist[], string>({
      query: () => `portfolio/`,
      transformResponse: (response: { data: Array<Watchlist> }, meta, arg) =>
        response.data,
    }),
    fetchOneWatchlist: builder.query<WatchlistOne, string>({
      query: (url) => `${url}`,
      transformResponse: (response: WatchlistOne, meta, arg) => response,
    }),
    fetchOnePortfolio: builder.query<Watchlist, string>({
      query: (id) => `portfolio/${id}`,
      transformResponse: (response: Watchlist, meta, arg) => response,
    }),
    update: builder.mutation<Watchlist, Partial<Watchlist>>({
      query: ({ id, isWatchlist, ...patch }) => ({
        url: `${isWatchlist ? "watchlist" : "portfolio"}/${id}`,
        method: "PUT",
        body: patch,
      }),
    }),
    create: builder.mutation<Watchlist, {name:string,isWatchlist:boolean}>({
      query: ({ name, isWatchlist,}) => ({
        url: `${isWatchlist ? "watchlist" : "portfolio"}/newByName`,
        method: "POST",
        body: {
          name
        },
      }),
    }),
    delete: builder.mutation<Watchlist, {id:string,isWatchlist:boolean}>({
      query: ({ id, isWatchlist,}) => ({
        url: `${isWatchlist ? "watchlist" : "portfolio"}/${id}`,
        method: "DELETE",
      }),
    }),
    addCompanies: builder.mutation<Watchlist, {id:string,isWatchlist:boolean,companyIds:Array<string>,tickers:Array<string>}>({
      query: ({ id, isWatchlist, companyIds, tickers}) => ({
        url: `${isWatchlist ? "watchlist" : "portfolio"}/${id}/addCompanies`,
        method: "PUT",
        body: {
          companyIds,
          tickers
        },
      }),
    }),
    removeCompanies: builder.mutation<Watchlist, {id:string,isWatchlist:boolean,companyIds:Array<string>,tickers:Array<string>}>({
      query: ({ id, isWatchlist, companyIds, tickers}) => ({
        url: `${isWatchlist ? "watchlist" : "portfolio"}/${id}/removeCompanies`,
        method: "PUT",
        body: {
          companyIds,
          tickers
        },
      }),
    }),
  }),
});

export const {
  useFetchWatchlistDataQuery,
  useFetchPortfolioDataQuery,
  useFetchOneWatchlistQuery,
  useFetchOnePortfolioQuery,
  useUpdateMutation,
  useCreateMutation,
  useDeleteMutation,
  useAddCompaniesMutation,
  useRemoveCompaniesMutation
} = watchlistPortfolioApi;
