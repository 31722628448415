import { Checkbox, Flex, Text, CheckboxGroup, Stack, Box } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon, HamburgerIcon, ReactIcon, AddIcon } from "@chakra-ui/icons"
import { ReactComponent as EnterpriseIcon } from "../../../assets/svg/carbon_enterprise.svg"
import { ReactComponent as PercentIcon } from "../../../assets/svg/carbon_percentage.svg"
import { PropsWithChildren } from "react";
import { motion } from "framer-motion"
import { itemVariants } from "../../../utils/framerVariants"

export function ListItem({ onGo }: { onGo: () => void }) {
    return (
        <Flex as={motion.div} w='100%'
            variants={itemVariants}
            animate="visible"
            exit="hidden"
            h='40px'
            alignItems='center'
            justifyContent='space-between'
            padding='10px'
            gap='10px'
            onClick={onGo} 
            cursor={"pointer"}
        >
            <Flex alignItems={'center'}>
                <HamburgerIcon color='#0564B8'></HamburgerIcon>
                <Text mx='5px' color={'#000000'} fontFamily='Poppins' fontWeight={400} fontSize={'12px'} lineHeight={'20px'}>My list</Text>
            </Flex>
            <ChevronRightIcon onClick={onGo} color='#0564B8'></ChevronRightIcon>
        </Flex>
    )
}

export function CompanyItem({ onGo }: { onGo: () => void }) {
    return (
        <Flex
            as={motion.div}
            variants={itemVariants}
            animate="visible"
            exit="hidden"
            w='100%'
            h='40px'
            alignItems='center'
            justifyContent='space-between'
            padding='10px'
            gap='10px'
            onClick={onGo} 
            cursor={"pointer"}
        >
            <Flex alignItems={'center'}>
                <EnterpriseIcon color='#0564B8'></EnterpriseIcon>
                <Text mx='5px' color={'#000000'} fontFamily='Poppins' fontWeight={400} fontSize={'12px'} lineHeight={'20px'}>Company Profile</Text>
            </Flex>
            <ChevronRightIcon onClick={onGo} color='#0564B8'></ChevronRightIcon>
        </Flex>
    )
}


export function MonkItem({ onGo }: { onGo: () => void }) {
    return (
        <Flex w='100%'
            as={motion.div}
            variants={itemVariants}
            animate="visible"
            exit="hidden"
            h='40px'
            alignItems='center'
            justifyContent='space-between'
            padding='10px'
            gap='10px'
            onClick={onGo} 
            cursor={"pointer"}
        >
            <Flex alignItems={'center'}>
                <ReactIcon color='#0564B8'></ReactIcon>
                <Text mx='5px' color={'#000000'} fontFamily='Poppins' fontWeight={400} fontSize={'12px'} lineHeight={'20px'}>MonkStreet Scores</Text>
            </Flex>
            <ChevronRightIcon onClick={onGo} color='#0564B8'></ChevronRightIcon>
        </Flex>
    )
}

export function FundamentalsItem({ onGo }: { onGo: () => void }) {
    return (
        <Flex w='100%'
            as={motion.div}
            variants={itemVariants}
            animate="visible"
            exit="hidden"
            h='40px'
            alignItems='center'
            justifyContent='space-between'
            padding='10px'
            gap='10px'
            onClick={onGo} 
            cursor={"pointer"}
        >
            <Flex alignItems={'center'}>
                <PercentIcon color='#0564B8'></PercentIcon>
                <Text mx='5px' color={'#000000'} fontFamily='Poppins' fontWeight={400} fontSize={'12px'} lineHeight={'20px'}>Fundamentals</Text>
            </Flex>
            <ChevronRightIcon onClick={onGo} color='#0564B8'></ChevronRightIcon>
        </Flex>
    )
}

interface AddedItemProps {
    text: string;
    value:string;
    onAdded: (value: string) => void
}

export function AddedItem({ text, onAdded, value }: AddedItemProps) {
    return (
        <Flex
            as={motion.div}
            variants={itemVariants}
            animate="visible"
            exit="hidden"
            w='100%'
            h='40px'
            alignItems='center'
            justifyContent='space-between'
            padding='10px'
            gap='10px'
            onClick={(e) =>{ 
                e.preventDefault()
                onAdded(value)}}
            cursor={"pointer"}
        >
            <Flex alignItems={'center'}>
                <Text mx='5px' color={'#000000'} fontFamily='Poppins' fontWeight={400} fontSize={'12px'} lineHeight={'20px'}> {text} </Text>
            </Flex>
            <AddIcon w='12px' h='12px' color='#777777'></AddIcon>
        </Flex>
    )
}

export function SectorItems() {
    return (
        <CheckboxGroup size='md' colorScheme='blue' defaultValue={['Basic Materials']}>
            <Stack spacing={[1, 5]} direction={['column']}>
                <Checkbox value='Basic Materials'>Basic Materials</Checkbox>
                <Checkbox value='Communication Services'>Communication Services</Checkbox>
                <Checkbox value='Consumer Cyclical'>Consumer Cyclical</Checkbox>
                <Checkbox value='Consumer Defensive'>Consumer Defensive</Checkbox>
                <Checkbox value='Energy'>Energy</Checkbox>
                <Checkbox value='Financial Services'>Financial Services</Checkbox>
                <Checkbox value='Healthcare'>Healthcare</Checkbox>
                <Checkbox value='Industrials'>Industrials</Checkbox>
                <Checkbox value='Real Estate'>Real Estate</Checkbox>
                <Checkbox value='Technology'>Technology</Checkbox>
                <Checkbox value='Utilities'>Utilities</Checkbox>
            </Stack>
        </CheckboxGroup>
    )
}

interface MenuType {
    type?: "list" | "company" | "score" | "fundamentals"| "main"
    innerText?: string
    onBack?: () => void
}
export function DropdownBox(props: PropsWithChildren<MenuType>) {

    const Icon = (color) => {

        if (props.type === "list") {
            return <HamburgerIcon color={color}></HamburgerIcon>
        } else if (props.type === "company") {
            return <EnterpriseIcon color={color}></EnterpriseIcon>
        }
        else if (props.type === "score") {
            return <ReactIcon color={color}></ReactIcon>
        }
        else {
            return <PercentIcon color={color}></PercentIcon>
        }

    }

    return (
        <Flex w='280px'
            as={motion.div}
            variants={itemVariants}
            animate="visible"
            exit="hidden"
            alignItems='start'
            justifyContent='space-between'
            borderRadius={'8px'}
            border={'1px solid rgba(0, 0, 0, 0.1)'}
            flexDirection={'column'}
        >
            {
                (props.innerText && props.type) && (
                    <Flex w='100%' alignItems={'center'} justifyContent={'flex-start'} mb='5px' padding='10px'
                        gap='10px'
                        borderBottom={'1px solid rgba(0, 0, 0, 0.1)'}
                    >
                        <ChevronLeftIcon onClick={() => props.onBack && props.onBack()} color='#rgba(153, 154, 155, 1)'></ChevronLeftIcon>
                        <Icon color='#rgba(153, 154, 155, 1)'></Icon>
                        <Text mx='5px' color={'#000000'} fontFamily='Poppins' fontWeight={400} fontSize={'12px'} lineHeight={'20px'}>{props.innerText}</Text>
                    </Flex>
                )
            }

            <Box w='100%' padding='10px'
                gap='10px'>
                {props.children}
            </Box>

        </Flex>
    )
}