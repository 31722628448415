import { IIncome, IRevenue } from "../types/Stock";
import { FormatChartNumber } from "./formatChartNumber";

export const formatNumberToDecimalPlaces = (n: number) => {
  return (Math.round(n * 100) / 100).toFixed(2);
};

export const prepareMarketCapValue = (n: number) => {
  if (!n) {
    return 0;
  }

  if (Math.abs(n) > 1_000_000_000_000) {
    return FormatChartNumber({ payload: n });
  }

  if (Math.abs(n) > 1_000_000_000) {
    return FormatChartNumber({ payload: n }).replace("B", "");
    // return Math.floor(n / 1_000_000_000);
  }

  if (Math.abs(n) > 1_000_000) {
    return FormatChartNumber({ payload: n }).replace("M", "");
  }

  return FormatChartNumber({ payload: n });
};

export const prepareMarketCapSym = (n: number) => {
  if (!n) {
    return "";
  }

  if (Math.abs(n) > 1_000_000_000_000) {
    return "";
  }

  if (Math.abs(n) > 1_000_000_000) {
    return "bn";
  }

  if (Math.abs(n) > 1_000_000) {
    return "M";
  }

  return "";
};

export const prepareMarketCapMark = (n: number) => {
  //   >100bn - mega
  // 10-100bn - large
  // 2-10bn - mid
  // 300M-2bn - small
  // 50-300M - micro
  // <50M - nano
  if (n > 100_000_000_000) {
    return "mega";
  }

  if (n > 10_000_000_000 && n <= 100_000_000_000) {
    return "large";
  }

  if (n > 2_000_000_000 && n <= 10_000_000_000) {
    return "mid";
  }

  if (n > 300_000_000 && n <= 2_000_000_000) {
    return "small";
  }

  if (n > 50_000_000 && n <= 300_000_000) {
    return "micro";
  }

  if (n && n <= 50_000_000) {
    return "nano";
  }

  return "";
};

export const prepareVolatilityMark = (n: number) => {
  //   <1: low
  // 1-1.2: medium
  // 1.2-1.5: high
  // >1.5: very high

  if (n < 1) {
    return "low";
  }

  if (n >= 1 && n < 1.2) {
    return "medium";
  }

  if (n >= 1.2 && n <= 1.5) {
    return "high";
  }

  if (n > 1.5) {
    return "very high";
  }

  return "";
};

export const calculateRevenueGrowth = (revenueData: IRevenue[]) => {
  if (!revenueData || !Array.isArray(revenueData)) {
    return 0;
  }

  const result = revenueData?.reduce(
    (acc, item, idx) => {
      if (idx < 4 && item?.RevenueLTM) {
        acc.currentYear += item?.RevenueLTM;
      }

      if (idx >= 4 && idx < 8 && item?.RevenueLTM) {
        acc.prevYear += item?.RevenueLTM;
      }

      return acc;
    },
    {
      currentYear: 0,
      prevYear: 0,
    }
  );

  // prevYear = 100%
  // currentYear = ?

  return Math.floor((result.currentYear * 100) / result.prevYear) - 100;
};

export const calculateIncomeGrowthByField = (
  data: IIncome[],
  field: "operatingIncome" | "netIncome"
) => {
  if (!data || !Array.isArray(data)) {
    return 0;
  }

  const result = data?.reduce(
    (acc, item, idx) => {
      if (idx < 4 && item?.[field]) {
        acc.currentYear += item?.[field];
      }

      if (idx >= 4 && idx < 8 && item?.[field]) {
        acc.prevYear += item?.[field];
      }

      return acc;
    },
    {
      currentYear: 0,
      prevYear: 0,
    }
  );

  // prevYear = 100%
  // currentYear = ?

  return Math.floor((result.currentYear * 100) / result.prevYear) - 100;
};
