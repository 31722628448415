import { Box, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IStock } from '../../../types/Stock';
import MonkScore from '../MonkScore';
import StockImage from './NewStockImage';

interface Props {
  stock: Pick<IStock, 'ticker' | 'companyName' | 'monkScore' | 'image'>;
  editMode: boolean;
  onEdit?: ( stock:Pick<IStock, 'ticker' | 'companyName' | 'monkScore' | 'image'> ) => void;
}

export const NewPhoneStockEditable: FC<Props> = ({ stock, editMode, onEdit }) => {
  const navigate = useNavigate();
  const { companyName, ticker, monkScore, image } = stock;

  return (
    <Box
      w='100%'
      display='flex'
      px={'15px'}
      py={3}
      mt={'0 !important'}
      alignItems='center'
      justifyContent='space-between'
      backgroundColor='#F9FAFB'
      borderRadius='16px'
      cursor='pointer'
      fontFamily='Rubik'
      onClick={() =>
        !editMode && navigate(`/stocks/${ticker?.split(':')[0]}/${ticker?.split(':')[1]}`)
      }
      >
      <Box
        alignItems='center'
        display='grid'
        gridTemplateColumns='50px 1fr 100px'
        width='100%'
      >
        <StockImage fullTicker={ticker} name={companyName} image={image} />

        <Flex 
        ms={8}
        alignItems={'flex-start'}
        direction={'column'}
        justifyContent={'flex-start'}
        columnGap={'16px'}>
          <Text
            fontSize='14px'
            fontWeight={500}
            lineHeight='20px'
            noOfLines={1}
            fontFamily='Poppins'
          >
            {companyName}
          </Text>
          <Text
            fontSize='12px'
            fontWeight={400}
            lineHeight='20px'
            color='#9EA3AE'
          >
            {ticker?.split('.')[0]}
          </Text>
        </Flex>

        <Box display='flex' justifyContent='flex-end' alignItems='center'>
          <MonkScore monkScore={Math.floor(monkScore)} />
          {editMode && (<Box
                    backgroundColor={'red.400'}
                    height='21px'
                    width='21px'
                    minW={'21px'}
                    minH='21px'
                    borderRadius='50%'
                    textAlign={'center'}
                    onClick={(e)=>{
                      e.preventDefault()
                      onEdit && onEdit(stock)
                    }}
                >
                   <Text color='white' fontSize='26px' fontWeight={500} lineHeight='20px'>
                        -
                   </Text>
                </Box>
                )
         }
        </Box>
      </Box>
    </Box>
  );
};
