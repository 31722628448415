import React, { PropsWithChildren } from 'react';
import {
  Box,
  Heading,
  VStack,
  Text,
  Image,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';

const imageUrl = require('../../../assets/image/hero.png');

export const HeroBox = (props: PropsWithChildren) => {
  
  return (
    <Box
      w="100%"
      as="section"
      height={"320px"}
      placeItems="center"
      alignItems="center"
      justifyContent="center" // Adjust content alignment as needed
      borderRadius="20px"
      backgroundImage={`url(${imageUrl})`}
      backgroundSize="cover"
      backgroundPosition="center"
      backdropBrightness={"50%"}
      backgroundRepeat="no-repeat"
      backgroundColor="rgba(0,0,0,0.6);"
      backgroundBlendMode="color"
      padding={"10px"}
    >
      {props.children}
    </Box>
  );
};