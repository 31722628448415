import { Box, Flex, Text, Button } from "@chakra-ui/react";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import GrowthBadge from "../../common/badges/GrowthBadge";
import GenericChart from "../GenericChartScores/GenericChart";
import {
  FormatChartNumber,
  handleTitleSubtext,
} from "../../../utils/formatChartNumber";
import { EntryBoxChart } from "../EntryChart/Box";
import CompanyMetaRow from "../../../subviews/RightSubview/Stocks/CompanyMetaRow";
import { IStock } from "../../../types/Stock";
import { useSelector } from "react-redux";
import { actualScore } from "../../../store/slices/stock";
import "./MonkScoreChartCard.css";

interface MonkScoreCardProps {
  stockData: IStock;
  fullTicker: string;
  companyName: string;
  score: number;
}

const MonkChartCard = ({
  companyName,
  score,
  stockData,
}: MonkScoreCardProps) => {
  const data = useSelector(actualScore);

  const sortedFn: (data: any) => any = (data) =>
    Object.values(
      data.reduce((b, a) => {
        let key = a.year + a.period;
        b[key] = b[key] ?? a;
        if (new Date(b[key].scoreUpdateDate) < new Date(a.scoreUpdateDate))
          b[key] = a;
        return b;
      }, {})
    );

  const getChartData = useCallback(() => {
    if (data) {
      return data.monkScoreTimeline.length > 0
        ? sortedFn(data.monkScoreTimeline)
        : [];
    }
    return [];
  }, [data]);

  const getChartValuation = useCallback(() => {
    if (data) {
      return data.valuationScoreTimeline.length > 0
        ? sortedFn(data.valuationScoreTimeline)
        : [];
    }
    return [];
  }, [data]);

  const getChartGrow = useCallback(() => {
    if (data) {
      return data.growtScoreTimeline.length > 0
        ? sortedFn(data.growtScoreTimeline)
        : [];
    }
    return [];
  }, [data]);

  const getChartProfitability = useCallback(() => {
    if (data) {
      return data.profitabilityScoreTimeline.length > 0
        ? sortedFn(data.profitabilityScoreTimeline)
        : [];
    }
    return [];
  }, [data]);

  const getChartHealth = useCallback(() => {
    if (data) {
      return data.healthScoreTimeline.length > 0
        ? sortedFn(data.healthScoreTimeline)
        : [];
    }
    return [];
  }, [data]);

  const getChartShareholder = useCallback(() => {
    if (data) {
      return data.shareholderScoreTimeline.length > 0
        ? sortedFn(data.shareholderScoreTimeline)
        : [];
    }
    return [];
  }, [data]);

  const sign = "%";

  const oneYearMetric = useCallback(() => {
    if (data) {
      const monkscore = data.monkScoreTimeline;
      return Math.floor(
        monkscore.length > 0 ? monkscore[monkscore.length - 1].percentile : 0
      );
    }
    return 0;
  }, [data]);

  const lastUpdate = new Date(stockData?.lastScoreUpdateDate || Date.now())
    ?.toLocaleDateString()
    ?.replaceAll("/", "-")
    ?.split("-")
    .reverse()
    .join("-");

  return (
    <Box w="100%" h="auto" m="auto" display={"flex"} flexDirection={"column"}>
      <Text as={"p"} fontSize={"14px"} lineHeight={"21px"} fontWeight={700}>
        <FormattedMessage
          id="factors_fundaments"
          values={{
            name: companyName,
            quality: handleTitleSubtext(score),
          }}
        />{" "}
        <Text as="span" fontSize={"14px"} lineHeight={"21px"} fontWeight={400}>
          based on the fundamental factors that science has identified as the
          most reliable predictors of future returns.
        </Text>
      </Text>

      <Box bgColor="#F9FAFB" id="MonkScoreCard">
        <Box
          position="relative"
          my={"10px"}
          backgroundColor={"#FFF"}
          borderRadius={"20px"}
          pt="20px"
          pb="2px"
        >
          <Box px="20px">
            <Flex
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              gap="10px"
            >
              <Text
                as={"p"}
                fontSize={"24px"}
                lineHeight={"36px"}
                fontWeight={500}
                display={"flex"}
                alignItems={"flex-end"}
              >
                {FormatChartNumber({
                  payload: Math.floor(oneYearMetric()),
                })}{" "}
                / 100
              </Text>

              <Button
                width={"auto"}
                fontSize={"14px"}
                lineHeight={"21px"}
                fontWeight={400}
                display={"flex"}
                alignItems={"center"}
                variant={"unstyled"}
                p="0"
                height={"auto"}
                justifyContent={"flex-end"}
                flexWrap={"wrap"}
                wordBreak={"break-all"}
                textDecoration="none"
                columnGap={"4px"}
              >
                <Text display={"flex"} alignItems={"center"}>
                  <FormattedMessage
                    id="evolution"
                    values={{
                      name: "MonkScore®",
                    }}
                  />{" "}
                </Text>
              </Button>
            </Flex>

            <Box mb="20px">
              <GrowthBadge
                vector={oneYearMetric() > 0 ? "up" : "down"}
                meta={`${
                  oneYearMetric() > 0 ? "+" : ""
                }${oneYearMetric()?.toFixed(2)}${sign} last 1 year`}
              />
            </Box>

            <Box>
              <GenericChart data={getChartData()} />
            </Box>
          </Box>

          <Box px="10px">
            <CompanyMetaRow
              compData={stockData}
              lastUpdate={lastUpdate}
              idElementForScreenshot={`MonkScoreCard`}
              isChart
              styledBox
            />
          </Box>
        </Box>
      </Box>
      <Box w="100%" my="10px">
        <Text
          id="message-container"
          as={"p"}
          fontSize={"14px"}
          lineHeight={"21px"}
        >
          <FormattedMessage id="factor_below_prefix" />
          <FormattedMessage
            id="factors_below"
            values={{
              name: companyName,
            }}
          >
            {(txt) => <span className="highlight">{txt}</span>}
          </FormattedMessage>
        </Text>
      </Box>

      <Box bgColor="#F9FAFB" id="MonkScoreCardRatios">
        <Box
          my={"10px"}
          backgroundColor={"#FFF"}
          borderRadius={"20px"}
          pt="20px"
          pb="2px"
          display={"flex"}
          flexDirection={"column"}
        >
          <Box px="20px">
            <EntryBoxChart name="Valuation" data={getChartValuation()} />
            <EntryBoxChart name="Growth" data={getChartGrow()} />
            <EntryBoxChart
              name="Profitability"
              data={getChartProfitability()}
            />
            <EntryBoxChart name="Health" data={getChartHealth()} />
            <EntryBoxChart
              name="Shareholder Retribution"
              data={getChartShareholder()}
            />
          </Box>

          <Box px="10px">
            <CompanyMetaRow
              compData={stockData}
              lastUpdate={lastUpdate}
              idElementForScreenshot={`MonkScoreCardRatios`}
              isChart
              styledBox
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MonkChartCard;
