import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FC } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { FormattedMessage } from "react-intl";

interface AccordionProps {
  data?: {
    title: string;
    description: string;
  };
  titleId?: string;
  footerId?: string;
  descriptionId?: string;
  list?: string[];
  listStyles?: object;
  children?: JSX.Element;
}

const Accordion: FC<AccordionProps> = ({
  data,
  list,
  listStyles,
  titleId = "FAQHeading",
  descriptionId = "FAQDescription",
  footerId,
  children,
}) => {
  return (
    <Box bgColor="#F9FAFB" padding="5px" mt={3} borderRadius="20px">
      <ChakraAccordion defaultIndex={[1]} allowMultiple>
        <AccordionItem border="none">
          {({ isExpanded }) => (
            <>
              <h4>
                <AccordionButton _hover={{ background: "#F9FAFB" }}>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight="600"
                    fontSize={{ base: "14px", md: "16px" }}
                    pr={{ base: "53px", md: 10 }}
                    mr={{ base: 0, md: 5 }}
                    lineHeight="24px"
                  >
                    <FormattedMessage
                      id={titleId}
                      values={{
                        FAQtitle: data?.title,
                      }}
                    />
                  </Box>
                  {isExpanded ? (
                    <AiOutlineMinus fontSize="18px" color="blue" />
                  ) : (
                    <AiOutlinePlus fontSize="18px" color="blue" />
                  )}
                </AccordionButton>
              </h4>
              <AccordionPanel
                pt={0}
                pb={4}
                fontWeight={{ base: "400", md: "300" }}
                fontSize={{ base: "12px", md: "16px" }}
                lineHeight={{ base: "20px", md: "22px" }}
                color="#6C727F"
              >
                {list ? (
                  <>
                    <FormattedMessage
                      id={descriptionId}
                      values={{
                        FAQdesc: data?.description,
                        span: (chunks) => (
                          <Text fontWeight="600" as="span">
                            {chunks}
                          </Text>
                        ),
                        br: <br />,
                      }}
                    />

                    <Stack
                      as="ul"
                      spacing={4}
                      direction="column"
                      mt={4}
                      {...listStyles}
                    >
                      {list.map((item, i) => (
                        <Box key={i} as="li">
                          <FormattedMessage
                            id={item}
                            values={{
                              span: (chunks) => (
                                <Text fontWeight="600" as="span">
                                  {chunks}
                                </Text>
                              ),
                              br: <br />,
                            }}
                          />
                        </Box>
                      ))}

                      {children}

                      {footerId && (
                        <FormattedMessage
                          id={footerId}
                          values={{
                            span: (chunks) => (
                              <Text fontWeight="600" as="span">
                                {chunks}
                              </Text>
                            ),
                            br: <br />,
                          }}
                        />
                      )}
                    </Stack>
                  </>
                ) : (
                  <FormattedMessage
                    id={descriptionId}
                    values={{
                      FAQdesc: data?.description,
                      span: (chunks) => (
                        <Text fontWeight="600" as="span">
                          {chunks}
                        </Text>
                      ),
                      br: <br />,
                    }}
                  />
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </ChakraAccordion>
    </Box>
  );
};

export default Accordion;
