import { ChangeEvent, useEffect, useState } from "react";

import { Box, Flex, Input } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import Logo from "../../components/Logo";
import { ICompany } from "../../types/Stock";
import axios from "axios";
import MobileTopContent from "./MobileTopContent";
import CompanySearch from "./CompanySearch";

function TopBar() {
  const [searchValue, setSearchValue] = useState("");

  const [stocks, setStocks] = useState<ICompany[]>([]);
  const [cache, setCache] = useState(new Map<string, ICompany[]>());

  const fetchSearchStocks = async (searchValue: string) => {
    if (searchValue === "") return;

    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/stocks`,
      {
        params: { limit: 20, search: searchValue },
      }
    );

    const filteredData = res.data.data.filter(
      (e: ICompany, i: number) =>
        res.data.data.findIndex((a: ICompany) => a.symbol === e.symbol) === i &&
        i <= 5
    );

    const newCache = new Map(cache);
    newCache.set(searchValue, filteredData);
    setCache(newCache);
  };

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    fetchSearchStocks(e.target.value);
    // setStocks(filteredData);
  };

  useEffect(() => {
    if (searchValue === "") {
      // empty cache
      setStocks([]);
      setCache(new Map<string, ICompany[]>());
    }

    if (searchValue && cache.has(searchValue)) {
      setStocks(cache.get(searchValue) as ICompany[]);
    }
  }, [searchValue, cache]);

  const clearSearch = () => setSearchValue("");

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      columnGap={"30px"}
      position={{ md: "fixed", base: "static" }}
      top="0"
      left="0"
      pl={{ md: "35px", base: "10px" }}
      pr={{ md: "40px", base: "10px" }}
      pt={{ md: "unset", base: "10px" }}
      pb={{ md: "unset", base: "66px" }}
      width="100%"
      height={{ md: "100px", base: "auto" }}
      backgroundColor="#04284B"
      fontFamily="Poppins"
      zIndex={200}
      as="header"
    >
      <Flex alignItems={"center"} display={{ md: "flex", base: "none" }}>
        <Link to={"/"} className="defaultLink">
          <Logo />
        </Link>
      </Flex>

      <CompanySearch
        searchValue={searchValue}
        onChange={onChange}
        stocks={stocks}
        clearSearch={clearSearch}
      />

      {/* <Flex
        alignItems='center'
        columnGap='24px'
        justifyContent='center'
        width='fit-content'
        position='relative'
        ml={{ md: 'unset', sm: 'auto' }}
      >
        <CustomLink
          to={'/'}
          title='Settings'
          ariaLabel='Settings'
          withTransition
        >
          <IoMdSettings fontSize='30px' color='currentColor' />
        </CustomLink>

        <CustomLink to={'/'} title='Profile' ariaLabel='Profile' withTransition>
          <HiOutlineUserCircle fontSize='30px' color='currentColor' />
        </CustomLink>
      </Flex> */}

      <MobileTopContent
        searchValue={searchValue}
        onChange={onChange}
        stocks={stocks}
        clearSearch={clearSearch}
      />
    </Box>
  );
}

export default TopBar;
