import { Box, Flex, Text } from '@chakra-ui/react';
import { formatNumberToDecimalPlaces } from '../../../utils/Math';
import './GraphBottomBar.css';
import YearGrowthBadge from '../../common/badges/YearGrowthBadge';

interface GraphsBottomBarProps {
  percentageDifference3Year: number[];
  graphName?: string;
}

const GraphsBottomBar = ({
  percentageDifference3Year,
  graphName,
}: GraphsBottomBarProps) => {

  return (
    <Box
      height='50px'
      pb={0}
      mt={2}
      borderRadius='10px'
      backgroundColor='white'
      mb={'20px'}
      className='no_scrollable_box'
    >
      <Box
        display='flex'
        height='49.1px'
        alignItems='center'
        className='scrollable_box'
        gap={'16px'}
      >
        {percentageDifference3Year &&
          percentageDifference3Year?.map((percent: number, index: number) => {
            if (typeof percent === 'undefined') {
              return null;
            }

            return (
              <YearGrowthBadge
                key={index}
                year={index + 1}
                value={
                  // TODO: refactor this
                  ['profitability', 'earningQuality'].includes(
                    graphName as string
                  )
                    ? graphName === 'earningQuality'
                      ? Math.round(percent / 100) || 0
                      : Math.round(percent) || 0
                    : Number(formatNumberToDecimalPlaces(percent))
                }
                sign={
                  // TODO: refactor this
                  [
                    'profitability',
                    'earningQuality',
                    'Dividend Yield',
                    'Buyback Ratio',
                    'Debt Paydown',
                  ].includes(graphName as string)
                    ? graphName === 'earningQuality'
                      ? 'pp'
                      : 'bps'
                    : '%'
                }
                isMomentum={graphName === 'momentum'}
              />
              // <Box
              //   cursor={'default'}
              //   padding='5px 10px'
              //   height='31px'
              //   key={index}
              //   borderRadius='10px'
              //   backgroundColor='#F9FAFB'
              //   display='flex'
              //   justifyContent='space-between'
              //   alignItems='center'
              //   mr={3}
              // >
              //   <Text
              //     fontSize='10px'
              //     lineHeight='19px'
              //     fontWeight={400}
              //     width={
              //       graphName === 'momentum' && index < 2 ? '53px' : '33px'
              //     }
              //   >
              //     {graphName === 'momentum'
              //       ? mometumTitleArr[index]
              //       : `${index + 1} year`}
              //   </Text>
              //   <Box
              //     padding='0 5px'
              //     ml={1}
              //     borderRadius='5px'
              //     backgroundColor={
              //       percent >= 0
              //         ? graphName === 'Outstanding Shares'
              //           ? '#FF4B4B'
              //           : '#58CC02'
              //         : graphName === 'Outstanding Shares'
              //         ? '#58CC02'
              //         : '#FF4B4B'
              //     }
              //     alignItems='center'
              //   >
              //     <Flex
              //       fontSize='10px'
              //       pt='4px'
              //       pb='3px'
              //       fontWeight={400}
              //       height='23px'
              //       // minWidth={"15px"}
              //       color='white'
              //     >
              //       <Text>{percent && Number(percent) > 0 ? '+' : ''}</Text>
              //       <Text>
              //         {graphName === 'profitability' ||
              //         graphName === 'earningQuality'
              //           ? Math.round(percent) || 0
              //           : Number(formatNumberToDecimalPlaces(percent)) || 0}
              //       </Text>
              //       <Text ms='2px'>
              //         {graphName === 'profitability' ||
              //         graphName === 'earningQuality'
              //           ? 'bps'
              //           : '%'}
              //       </Text>
              //     </Flex>
              //   </Box>
              // </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default GraphsBottomBar;
