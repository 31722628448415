import { Box, Flex, Text } from '@chakra-ui/react';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import cn from 'classnames';

import styles from './styles.module.css';
import CustomLink from '../../components/common/CustomLink';
import { leftSideBarMenu } from '../../constants/menu';

function getActiveLink(pathname:string){
    return pathname == '/' ? "analysis" : pathname ?? "analysis"
}
function LeftSideBar() {
  const [searchParams] = useSearchParams();
  const location = useLocation()
  const activeLink = getActiveLink(location.pathname)
  const linkElements = leftSideBarMenu?.map((item) => {
    const Icon = item.icon;

    return (
      <CustomLink
        key={item?.name}
        to={item?.link}
        className={cn(
          styles.link,
          !item?.isComingSoon && styles.active,
          item?.isComingSoon && styles.disabled
        )}
      >
        <Flex
          flexDirection='column'
          alignItems='center'
          justifyContent='flex-start'
          color='currentColor'
          rowGap='6px'
          w={'45px'}
          className={styles.wrapper}
        >
          <Icon color='inherit' />
          <Text fontSize='10px' lineHeight='15px' fontWeight='500'>
            {item.title}
          </Text>
        </Flex>
        {item?.isComingSoon && <span className={styles.soonBadge}>Soon</span>}
      </CustomLink>
    );
  });

  return (
    <Box
      display={{ md: 'flex', base: 'none' }}
      flexDirection='column'
      alignItems='center'
      justifyContent='flex-start'
      rowGap={'24px'}
      position='fixed'
      top='100px'
      left='0'
      px='27px'
      pt='60px'
      width='100px'
      height='100%'
      backgroundColor='#04284B'
      fontFamily='Poppins'
      zIndex={200}
    >
      {linkElements}
    </Box>
  );
}

export default LeftSideBar;
