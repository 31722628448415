import { Flex, Text, useToast } from "@chakra-ui/react";
import { CommonModal } from "./Common";
import { ReactComponent as PlusIcon } from '../../assets/svg/add.svg'
import { useAddCompaniesMutation } from "../../services/watchlistPortfolioApi";
import { useSelector } from "react-redux";
import { watchlist as storeWatchlist } from "../../store/slices/watchlist";
import { ActionableCompanySearch } from "../../layout/TopBar/CommonCompanySearch";
import { ChangeEvent, useState } from "react";
import { ICompany } from "../../types/Stock";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchOne
   } from "../../store/slices/watchlist";

interface Props {
    isOpen: boolean;
    is:'watchlist' | 'portfolio',
    onSaveSuccess: () => void;
    onOpen?: () => void;
    onClose: () => void;
}

export function AddCompanyModal({ isOpen, onClose, is, onSaveSuccess }: Props) {

    const watchlist = useSelector(storeWatchlist)
    const [searchValue, setSearchValue] = useState('');
    const clearSearch = () => setSearchValue('');
    const { id } = useParams();
    const toast = useToast()
    const dispatch = useDispatch()

    const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    }

      const [
        addCompany
      ] = useAddCompaniesMutation()
    
      const addedCompanyPromise = async (stock:ICompany) => {
        try{
            await addCompany({
                id: watchlist?.id ?? id ?? '',
                isWatchlist: watchlist?.isWatchlist,
                companyIds: [],
                tickers: [stock.ticker]
            }).unwrap() 
            onSaveSuccess()
            dispatch(fetchOne({is,id: id ?? ''}) as any)
            return Promise.resolve(true)
        }catch(error){
            return Promise.reject(true)
        }
      };

    return (
        <CommonModal isOpen={isOpen} onClose={()=>{
            clearSearch()
            onClose()
        }}>
            <Flex flexDirection={'column'} alignItems={'center'}>
                <Flex alignItems={'center'} flexDirection={'column'}>
                    <PlusIcon />
                    <Text
                        fontSize='14px'
                        fontWeight={"600"}
                        lineHeight={"17px"}
                        align="center"
                        fontFamily='Poppins'
                        noOfLines={2}
                        mb={2}
                        mt={2}
                    >
                        {`Add a company to “ ${watchlist?.name ?? ''} ”`}
                    </Text>
                    <Text
                        fontWeight='300'
                        fontSize='14px'
                        lineHeight='24px'
                        fontFamily='Poppins'
                        noOfLines={2}
                        mb={4}
                    >
                       Search for a company and click on its name to add it.
                    </Text>
                </Flex>
                <ActionableCompanySearch
                    searchValue={searchValue}
                    onChange={onChange}
                    clearSearch={clearSearch}
                    onClickStock={async (stock)=>{
                        toast.promise(addedCompanyPromise(stock) as any, {
                            success: { title: 'Company added', description: `Looks great you added ${stock.companyName}` },
                            error: { title: ':) Upss!!!', description: 'Something wrong.' },
                            loading: { title: 'Pending', description: 'Adding company' },
                          })
                        clearSearch()
                    }}
            />
            </Flex>
            
        </CommonModal>
    )
}