import {
  Button,
  Divider,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { createContext, FC, useState } from "react";
import { FormattedMessage } from "react-intl";

export interface TooltipModalProps {
  isOpen: boolean;
  onClose: () => void;
  titleKey: string;
  bodyKey: string[];
  learnMoreLink?: string;
}

interface TooltipModalContextType {
  isOpen: boolean;
  modalData: { titleKey: string; bodyKey: string[]; learnMoreLink?: string };
  updateModalData: (data: TooltipModalContextType["modalData"]) => void;
  openModal: () => void;
  closeModal: () => void;
}

export const TooltipModalContext = createContext<TooltipModalContextType>({
  isOpen: false,
  modalData: {
    titleKey: "tooltip_ROIC",
    bodyKey: ["tooltip_ROIC_body"],
  },
  updateModalData: () => {},
  openModal: () => {},
  closeModal: () => {},
});

export const TooltipModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [modalData, setModalData] = useState({
    titleKey: "tooltip_ROIC",
    bodyKey: ["tooltip_ROIC_body"],
  });

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const updateModalData = (data: TooltipModalContextType["modalData"]) => {
    setModalData(data);
    openModal();
  };

  return (
    <TooltipModalContext.Provider
      value={{ isOpen, openModal, closeModal, modalData, updateModalData }}
    >
      {children}
    </TooltipModalContext.Provider>
  );
};

export const TooltipModal: FC<TooltipModalProps> = ({
  isOpen,
  onClose,
  titleKey,
  bodyKey,
  learnMoreLink,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay bg="#F9FAFB90" />
      <ModalContent>
        <ModalHeader
          px="6"
          pt="6"
          pb="4"
          display="flex"
          alignItems="flex-start"
        >
          <IconButton
            icon={
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="50" height="50" rx="10" fill="#CEE0F1" />
                <rect
                  x="7.5"
                  y="7.5"
                  width="35"
                  height="35"
                  rx="17.5"
                  fill="#1CB0F6"
                />
                <path
                  d="M23.8 29.675V21.5H26.2V29.675H23.8ZM25.025 20.6C24.6417 20.6 24.325 20.5083 24.075 20.325C23.825 20.1417 23.7 19.85 23.7 19.45C23.7 19 23.825 18.7 24.075 18.55C24.325 18.3833 24.6417 18.3 25.025 18.3C25.3583 18.3 25.65 18.3833 25.9 18.55C26.1667 18.7 26.3 19 26.3 19.45C26.3 19.8667 26.1667 20.1667 25.9 20.35C25.65 20.5167 25.3583 20.6 25.025 20.6ZM25 35.9C23.5 35.9 22.0917 35.6167 20.775 35.05C19.475 34.5 18.325 33.7333 17.325 32.75C16.3417 31.75 15.5667 30.6 15 29.3C14.45 27.9833 14.175 26.575 14.175 25.075C14.175 23.575 14.45 22.175 15 20.875C15.5667 19.5583 16.3417 18.4083 17.325 17.425C18.325 16.425 19.475 15.65 20.775 15.1C22.0917 14.5333 23.5 14.25 25 14.25C26.5 14.25 27.9 14.5333 29.2 15.1C30.5167 15.65 31.6667 16.425 32.65 17.425C33.65 18.4083 34.425 19.5583 34.975 20.875C35.5417 22.175 35.825 23.575 35.825 25.075C35.825 26.575 35.5417 27.9833 34.975 29.3C34.425 30.6 33.65 31.75 32.65 32.75C31.6667 33.7333 30.5167 34.5 29.2 35.05C27.9 35.6167 26.5 35.9 25 35.9ZM25 33.675C26.2 33.675 27.3167 33.4583 28.35 33.025C29.3833 32.575 30.2917 31.9583 31.075 31.175C31.875 30.375 32.4917 29.4583 32.925 28.425C33.375 27.375 33.6 26.2583 33.6 25.075C33.6 23.875 33.375 22.7583 32.925 21.725C32.4917 20.6917 31.875 19.7833 31.075 19C30.2917 18.2 29.375 17.5833 28.325 17.15C27.2917 16.7 26.1833 16.475 25 16.475C23.8 16.475 22.6833 16.7 21.65 17.15C20.6167 17.5833 19.7 18.2 18.9 19C18.1167 19.7833 17.5 20.7 17.05 21.75C16.6167 22.7833 16.4 23.8917 16.4 25.075C16.4 26.275 16.6167 27.3917 17.05 28.425C17.5 29.4583 18.1167 30.375 18.9 31.175C19.7 31.9583 20.6167 32.575 21.65 33.025C22.7 33.4583 23.8167 33.675 25 33.675Z"
                  fill="white"
                />
              </svg>
            }
            aria-label="Info"
            variant="outline"
            size="sm"
            mr={"10px"}
            width={"50px"}
            height={"50px"}
          />

          <Text flex="1" mr="10px">
            <FormattedMessage id={titleKey} />
          </Text>

          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: "pointer" }}
            onClick={onClose}
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="#667085"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </ModalHeader>
        <ModalBody
          px="6"
          fontFamily="Poppins"
          fontSize="sm"
          lineHeight="24px"
          pb="5"
          pt="0"
        >
          {bodyKey.map((key, i) => (
            <Text {...(i ? { mt: "14px" } : {})} whiteSpace="pre-line">
              <FormattedMessage
                id={key}
                values={{
                  br: <br />,
                  bold: (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </Text>
          ))}
        </ModalBody>
        <Divider borderColor="#E4E7EC" />
        <ModalFooter
          fontFamily="Poppins"
          fontSize="16px"
          lineHeight="16px"
          p="5"
        >
          {learnMoreLink && (
            <Button
              color="white"
              bgColor="#0A64BC"
              py="14px"
              px="5"
              borderRadius="16px"
              variant="link"
              onClick={() => window.open(learnMoreLink, "_blank")}
              mr={3}
            >
              Learn More
            </Button>
          )}
          <Button flex="1" colorScheme="blue" onClick={onClose}>
            Understood
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
