import { ReactComponent as FilterIcon } from '../../assets/svg/filter.svg';
import { ReactComponent as BulbIcon } from '../../assets/svg/bulb.svg';
import { ReactComponent as AtomIcon } from '../../assets/svg/atom.svg';
import { ReactComponent as CaseIcon } from '../../assets/svg/solar-case.svg';
import { ReactComponent as EyeIcon } from '../../assets/svg/eye.svg';
import { ReactComponent as StarIcon } from '../../assets/svg/star.svg';
import { ReactComponent as ExclamationIcon } from '../../assets/svg/exclamation.svg';
import { ReactComponent as CommentIcon } from '../../assets/svg/comment.svg';
import { ReactComponent as EmailIcon } from '../../assets/svg/outline-email.svg';
import { ReactComponent as MaterialSymIcon } from '../../assets/svg/material-symbols.svg';
import { ReactComponent as CollectionsIcon } from '../../assets/svg/collection.svg';
import { ReactComponent as UserExpertIcon } from '../../assets/svg/user-expert.svg';

import { ReactComponent as UserAnalystIcon } from '../../assets/svg/user_analyst.svg';
import { ReactComponent as FactorsIcon } from '../../assets/svg/factors.svg';
import { ReactComponent as BadgeOutlineIcon } from '../../assets/svg/badge-outline.svg';

const leftSideBarMenu = [
  {
    link: '/',
    title: 'Analysis',
    name: 'analysis',
    icon: AtomIcon,
    isComingSoon: false,
  },
  {
    link: '/screener',
    title: 'Screener',
    name: 'screener',
    icon: FilterIcon,
    isComingSoon: false,
  },
  {
    link: '/?tab=ideas',
    title: 'Ideas',
    name: 'ideas',
    icon: BulbIcon,
    isComingSoon: true,
  },
  {
    link: '/portfolio',
    title: 'Portfolio',
    name: 'portfolio',
    icon: CaseIcon,
    isComingSoon: false,
  },
  {
    link: '/watchlist',
    title: 'Watchlist',
    name: 'watchlist',
    icon: EyeIcon,
    isComingSoon: false,
  },
];

const singleStockMenu = [
  {
    title: 'MonkScore',
    name: 'monk_score',
    icon: FactorsIcon,
    color: '#0564B8',
    hidden: false,
  },
  {
    title: 'Analyst overview',
    name: 'analyst_overview',
    icon: UserAnalystIcon,
    color: null,
    hidden: true,
  },
  {
    title: 'Insider overview',
    name: 'insider_overview',
    icon: BadgeOutlineIcon,
    color: null,
    hidden: true,
  },
  {
    title: 'Other overview',
    name: 'other_overview',
    icon: BadgeOutlineIcon,
    color: null,
    hidden: true,
  },
];

const rightSideBarMenu = [
  {
    link: 'https://forms.monk.st/nps/',
    title: 'Rate us!',
    name: 'rate_us',
    icon: StarIcon,
  },
  {
    link: 'https://forms.monk.st/issues/',
    title: 'Issues?',
    name: 'issues',
    icon: ExclamationIcon,
  },
  {
    link: 'https://forms.monk.st/feedback/',
    title: 'Feedback?',
    name: 'feedback',
    icon: CommentIcon,
  },
  {
    link: 'https://forms.monk.st/contact/',
    title: 'Contact us!',
    name: 'contact_us',
    icon: EmailIcon,
  },
];

const bugReportUrl = 'https://forms.monk.st/bug-report/';

const submenu = {
  analysis: [
    {
      name: 'popular_stocks',
      title: 'Popular stocks',
      subtitle: 'Our users are researching these stocks...',
      icon: MaterialSymIcon,
      hidden: false,
    },
    {
      name: 'stock_collections',
      title: 'Stock collections',
      subtitle: 'Our users are researching these collections...',
      icon: CollectionsIcon,
      hidden: true,
    },
    {
      name: 'guru_portfolios',
      title: 'Guru`s portfolios',
      subtitle: 'Our users are researching these portfolios...',
      icon: UserExpertIcon,
      hidden: true,
    },
  ],
  watchlist:[]
};

export {
  leftSideBarMenu,
  rightSideBarMenu,
  submenu,
  singleStockMenu,
  bugReportUrl,
};
