import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import {
  useFetchBalanceSheetDataQuery,
  useFetchCashflowDataQuery,
  useFetchIncomeStatementDataQuery,
  useFetchStockDataQuery,
} from "../../../services/stockApi";
import { useState } from "react";
import TooltipModal from "../tooltipModal/tooltipModal";
import CandlestickChart from "../CandlestickChart/CandleStickChart";
import GraphsBottomBar from "../GraphsBottomBar/GraphsBottomBar";

import {
  IBalanceSheet,
  ICashFlowStatement,
  IChartData,
  IIncomeStatement,
} from "../../../types/Core";

import { FormatChartNumber } from "../../../utils/formatChartNumber";
import {
  useFetchCompanyRatiosQuery,
  useFetchSloanRatioQuery,
} from "../../../services/chartApi";
import { ReactComponent as OutlineInfoIcon } from "../../../assets/svg/outline-info.svg";
import GrowthBadge from "../../common/badges/GrowthBadge";
import { ICompanyRatios } from "../../../types/Chart";

interface TransparencyCardProps {
  fullTicker: string;
}

const getSloanRatioByYear = (
  sloanRatioData: ICompanyRatios[] | undefined,
  year: number
) => {
  if (!sloanRatioData) return 0;
  return sloanRatioData
    .slice(4 * year, 4 + 4 * year)
    .reduce((prev, curr) => prev + (curr["sloanRatio"] || 0), 0);
};

const TransparencyCard = ({ fullTicker }: TransparencyCardProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: sloanRatioData } = useFetchCompanyRatiosQuery(fullTicker);

  const formattedEarningQualityData = sloanRatioData
    ?.map((report, i) => ({
      time: i,
      value: report.sloanRatio || 0,
      yearQ: `${report.calendarYear}${report.period}`,
      date: new Date(report.statementsDate)
        ?.toLocaleDateString()
        ?.replaceAll("/", "-")
        ?.split("-")
        .reverse()
        .join("-"),
    }))
    .reverse();

  const EarningQualitythisYear =
    sloanRatioData === undefined || sloanRatioData.length === 0
      ? 0
      : sloanRatioData[0].sloanRatio || 0;

  const EarningQualityBarsData = [
    (getSloanRatioByYear(sloanRatioData, 0) -
      getSloanRatioByYear(sloanRatioData, 1)) /
      getSloanRatioByYear(sloanRatioData, 1),
    (getSloanRatioByYear(sloanRatioData, 1) -
      getSloanRatioByYear(sloanRatioData, 2)) /
      getSloanRatioByYear(sloanRatioData, 2),
    (getSloanRatioByYear(sloanRatioData, 2) -
      getSloanRatioByYear(sloanRatioData, 3)) /
      getSloanRatioByYear(sloanRatioData, 3),
  ];

  const oneYearMetric = EarningQualityBarsData?.[0] / 100;

  const sign = "pp";

  return (
    <Box bgColor="#F9FAFB" id="TransparencyCard">
      <Box
        position="relative"
        my={"10px"}
        backgroundColor={"#FFF"}
        borderRadius={"20px"}
        p="20px"
      >
        <Flex
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap="10px"
        >
          <Text
            as={"p"}
            fontSize={"24px"}
            lineHeight={"36px"}
            fontWeight={500}
            display={"flex"}
            alignItems={"flex-end"}
          >
            {FormatChartNumber({
              payload: EarningQualitythisYear * 100 || 0,
            })}
            <Text fontSize={"12px"} lineHeight={"28px"}>
              %
            </Text>
          </Text>
          <Button
            width={"auto"}
            fontSize={"14px"}
            lineHeight={"21px"}
            fontWeight={400}
            display={"flex"}
            alignItems={"center"}
            variant={"unstyled"}
            p="0"
            height={"auto"}
            justifyContent={"flex-end"}
            flexWrap={"wrap"}
            wordBreak={"break-all"}
            textDecoration="underline"
            textDecorationStyle={"dashed"}
            textDecorationColor={"#c9cacb"}
            textDecorationThickness={"from-font"}
            textUnderlineOffset={"4px"}
            columnGap={"4px"}
            onClick={() => setIsOpen(true)}
          >
            <Text display={"flex"} alignItems={"center"}>
              <FormattedMessage id="Earning_chart_title" />
              <OutlineInfoIcon
                style={{
                  textDecoration: "none",
                  marginLeft: "6px",
                }}
              />
            </Text>
          </Button>
        </Flex>

        <Box mb="20px">
          <GrowthBadge
            vector={oneYearMetric > 0 ? "up" : "down"}
            meta={`${oneYearMetric > 0 ? "+" : ""}${Math.round(
              oneYearMetric
            )?.toFixed(1)}${sign} last 1 year`}
          />
        </Box>

        <Box>
          <CandlestickChart
            data={formattedEarningQualityData ?? []}
            activeChart="Earning Quality"
          />
        </Box>

        <GraphsBottomBar
          graphName="earningQuality"
          percentageDifference3Year={EarningQualityBarsData}
        />
      </Box>
      <TooltipModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        activeChart={"Sloan Ratio"}
      />
    </Box>
  );
};

export default TransparencyCard;
