import { FC, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box, Text } from "@chakra-ui/react";
import { useBreakpoint } from "@chakra-ui/react";
import { CustomTooltip } from "./CustomTooltip";

export interface GenericChartProps {
  lessCurved?: boolean;
  altColor?: boolean;
  data: {
    percentile: number;
    value: number;
    year: string;
    period: string;
  }[];
}



const GenericChart: FC<GenericChartProps> = ({
  data,
  lessCurved,
  altColor,
}) => {

  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "base";

  const CustomTick = ({ x, y, payload, stroke }) => {
    // Extract label from payload (assuming payload contains label)
    const label = payload.value;
  
    // Calculate text position based on tick location (adjust as needed)
    const textX = isMobile ? x + 10 : x - 38; // Adjust horizontal offset
    const textY = y - 3; // Adjust vertical offset
  
    return (
      <g>
        <line x1={x} y1={y} x2={x} y2={y + 4} stroke={stroke} fill={"#C9CACB"} /> {/* Original tick line */}
        <text fill={"#C9CACB"} x={textX} y={textY} dominantBaseline="middle" fontSize={isMobile ? "8px" : "12px"}>
          {label}
        </text>
      </g>
    );
  };

  const intl = useIntl();

 
  const [hoverIndex, setHoverIndex] = useState<string>();
  const [position, setPosition] = useState({
    data: { x: 0, y: 0 },
    show: false,
  });

  const formattedData = useCallback(() => {
    return data.map((item) => ({
      date: `${item.year}`,
      value: item.value,
      percentile: item.percentile,
      period: item.period,
    }));
  }, [data]);

  

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ResponsiveContainer width="100%" aspect={isMobile ? 1 : 2.8}>
        <BarChart
          data={formattedData()}
          margin={{
            top: 5,
            right: isMobile ? 0 : 30,
            left: isMobile ? -20 : 20,
            bottom: 5,
          }}
          barGap={5}
        >
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={!isMobile}
            tick={isMobile ? {fill:"#C9CACB"} : CustomTick}
            fontSize={"8px"}
          />
          <YAxis
            type="number"
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#A3AED0" }}
            fontSize={"12px"}
            padding={{ bottom: 3 }}
            tickMargin={isMobile ? 5 : 20}
          />
          <Tooltip
            wrapperStyle={{ outline: "none" }}
            content={<CustomTooltip active payload label />}
            cursor={{ fill: "transparent" }}
            position={{
              x: position?.data.x - 35 ?? 0,
              y:
                position?.data.y -
                  (data[Number(hoverIndex)]?.value > 0 ? 60 : -8) ?? 0,
            }}
          />
          <Bar
            dataKey="percentile"
            barSize={24}
            className="rechart_bar"
            radius={[5, 5, 0, 0]}
            onMouseMove={(data) => setPosition({ data: data, show: true })}
            onMouseLeave={(data) => setPosition({ data: data, show: false })}
          >
            {data.map((entry, i, arr) => {
              const isCurrent = i + 1 === arr.length;
              return (
                <Cell
                  onMouseEnter={() => {
                    if (i) {
                      setHoverIndex(`${i}`);
                    } else {
                      setHoverIndex("");
                    }
                  }}
                  key={`cell-${i}`}
                  fill={Number(hoverIndex) === i ? "#085096" : "#CEE0F1"}
                  fillOpacity={
                    Number(hoverIndex) === i ? 1 : isCurrent ? 1 : ".25"
                  }
                  //radius={3}
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GenericChart;
