import { useIntl } from "react-intl";

export function FormatChartNumber({ payload }: { payload: number }) {
  const intl = useIntl();
  if (Math.abs(payload) < 10) {
    return intl.formatNumber(payload, {
      notation: "compact",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }
  return intl.formatNumber(payload, {
    notation: "compact",
    maximumSignificantDigits: 3,
    minimumSignificantDigits: 3,
    maximumFractionDigits: 2,
    minimumFractionDigits: 1,
  });
}

export const handleTitleSubtext = (value: number) => {
  if (value < 20) {
    return "Very Poor";
  } else if (value >= 20 && value < 40) {
    return "Poor";
  } else if (value >= 40 && value < 65) {
    return "Good";
  } else if (value >= 65 && value < 90) {
    return "Very Good";
  } else if (value >= 90) {
    return "Excellent";
  }
};
