const defaultStocks = [
  {
    companyName: 'Apple Inc.',
    ticker: 'NASDAQ:AAPL',
    monkScore: 0,
    image: `${process.env.PUBLIC_URL}/stock-icons/AAPL-NASDAQ.png`,
  },
  {
    companyName: 'Amazon.com, Inc.',
    ticker: 'NASDAQ:AMZN',
    monkScore: 0,
    image: `${process.env.PUBLIC_URL}/stock-icons/AMZN-NASDAQ.png`,
  },
  {
    companyName: 'Costco Wholesale Corporation',
    ticker: 'NASDAQ:COST',
    image: `${process.env.PUBLIC_URL}/stock-icons/COST-NASDAQ.png`,

    monkScore: 0,
  },
  {
    companyName: 'Alphabet Inc.',
    ticker: 'NASDAQ:GOOG',
    image: `${process.env.PUBLIC_URL}/stock-icons/GOOG-NASDAQ.png`,

    monkScore: 0,
  },
  {
    companyName: 'Meta Platforms Inc Class A',
    ticker: 'NASDAQ:META',
    monkScore: 0,
    image: `${process.env.PUBLIC_URL}/stock-icons/META-NASDAQ.png`,
  },
  {
    companyName: 'Microsoft Corp',
    ticker: 'NASDAQ:MSFT',
    monkScore: 0,
    image: `${process.env.PUBLIC_URL}/stock-icons/MSFT-NASDAQ.png`,
  },
];

export default defaultStocks;
