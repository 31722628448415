import exchangesData from "./json/exchanges.json";

interface Exchange {
  Prefix: string;
  name: string;
}

export const exchangeSelect = () => {
  return exchangesData
    .sort((a: Exchange, b: Exchange) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    })
    .map((v) => {
      return {
        label: v.name,
        value: v.Prefix,
      };
    });
};
