import { Box, Flex, Text } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import './CustomTooltip.css';

export const CustomTooltip = ({
  active,
  payload,
  label,
  activeChart,
  notRevertDate = false,
}) => {
  const intl = useIntl();
  if (active && payload && payload.length) {
    let payloadValue =
      activeChart === 'Earning Quality'
        ? payload[0]?.value * 100 || 0
        : payload[0]?.value || 0;

    return (
      <Box
        className='custom_tooltip'
        position='relative'
        style={{
          background: 'white',
          padding: '8px 25px',
          borderRadius: '10px',
          border: '1px solid #D3D5DA',
          zIndex: 1000,
          outline: 'none !important',
        }}
      >
        {activeChart !== 'monkscore' && (
          <Box
            className={payloadValue > 0 ? 'triangle_down' : 'triangle_up'}
            position='absolute'
            left='38%'
            bottom={payloadValue > 0 ? '-15%' : '100%'}
          />
        )}

        <Flex
          className='label'
          style={{ display: 'flex', justifyContent: 'center', outline: 'none' }}
        >
          <Text fontSize='14px' fontWeight={600} fontFamily='Poppins'>
            {activeChart !== 'Outstanding Shares'
              ? Math.abs(payloadValue) < 10
                ? intl.formatNumber(payloadValue, {
                    notation: 'compact',
                    maximumFractionDigits:
                      activeChart === 'momentum' || activeChart === 'Dividends'
                        ? 2
                        : 2,
                    minimumFractionDigits: 2,
                  })
                : intl.formatNumber(payloadValue, {
                    notation: 'compact',
                    maximumSignificantDigits: 3,
                    minimumSignificantDigits: 3,
                    maximumFractionDigits:
                      activeChart === 'momentum' || activeChart === 'Dividends'
                        ? 2
                        : 2,
                    minimumFractionDigits: 1,
                  })
              : intl.formatNumber(payloadValue || 0, {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: 1,
                  notation: 'compact',
                })}
          </Text>
          {activeChart !== 'Outstanding Shares' &&
            activeChart !== 'Health' &&
            activeChart !== 'valuation' && (
              <Text
                fontSize='8px'
                fontWeight={600}
                fontFamily='Poppins'
                mt='6px'
                ms='2px'
              >
                {payloadValue < 999 && payloadValue > -999
                  ? `${
                      activeChart === 'momentum' || activeChart === 'Dividends'
                        ? 'USD'
                        : '%'
                    }`
                  : 'USD'}
              </Text>
            )}
        </Flex>
        <Text
          className='cashflow'
          textAlign='center'
          color='#0A64BC'
          fontSize='8px'
          fontWeight={400}
          fontFamily='Poppins'
        >
          {notRevertDate
            ? payload[0]?.payload?.date
            : payload[0]?.payload?.date?.split('-').reverse().join('-')}
        </Text>
      </Box>
    );
  }

  return null;
};
