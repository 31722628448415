import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import "../../App.css";
import Eye from "../../assets/svg/Eye.png";
import Eyeshow from "../../assets/svg/Eyeshow.svg";
import Lock from "../../assets/svg/Lock.svg";
import Mail from "../../assets/svg/Mail.svg";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import {
  closeModal,
  openModal as openModalDispatch,
} from "../../store/slices/modal";
import { MODAL_TYPES_ENUM } from "../../constants/variables";

const SigninSignupWithCredentialsModal = ({
  openModal,
  setOpenModal,
  signinClicked,
  setSigninClicked,
  fetchLoginStatus,
  directSignupWithCredentials = false,
  userInput,
  setUserInput,
  TCChecked,
  setOpenTCModal,
  sendPeriodicUpdates,
  onClose,
}: {
  openModal: boolean;
  setOpenModal: Dispatch<boolean>;
  signinClicked: boolean;
  setSigninClicked: Dispatch<boolean>;
  fetchLoginStatus: any;
  directSignupWithCredentials?: boolean;
  userInput: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  };
  setUserInput: Dispatch<{
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }>;
  TCChecked: boolean;
  setOpenTCModal: Dispatch<boolean>;
  sendPeriodicUpdates: boolean;
  onClose?: () => void;
}) => {
  const [showForm, setShowForm] = useState(
    directSignupWithCredentials || false
  );

  const [emailFocused, setEmailFocused] = useState(
    userInput?.email ? true : false
  );
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [fNameFocused, setFNameFocused] = useState(false);
  const [lNameFocused, setLNameFocused] = useState(false);
  const [show, setShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [userMessage, setUserMessage] = useState({ msg: "", color: "" });
  const [isChecked, setIsChecked] = useState(false);
  const [showForgotPwd, setShowForgotPwd] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState({
    email: "",
    password: "",
    firstName: "",
  });

  useEffect(() => {
    userInput?.email
      ? setEmailFocused(true)
      : userInput?.password && setPasswordFocused(true);
  }, [userInput]);

  useEffect(() => {
    if (showForm) setUserMessage({ msg: "", color: "" });
  }, [showForm]);

  async function verifyEmail(email) {
    const response = await axios.get(
      `https://emailverifier.reoon.com/api/v1/verify`,
      {
        params: {
          email: email,
          key: process.env.REACT_APP_REOON_API_KEY,
          mode: "quick",
        },
      }
    );

    if (response.data) {
      return response.data.status;
    } else {
      return "invalid";
    }
  }

  const handleValidation = (emailVerificationStatus = "valid") => {
    let error = { email: "", password: "", firstName: "" };

    if (userInput?.email !== "") {
      if (!userInput?.email?.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/g)) {
        error.email = "Invalid email address.";
      } else if (emailVerificationStatus === "invalid") {
        error.email = "Check the email address provided.";
      } else if (emailVerificationStatus === "disposable") {
        error.email = "Disposable emails are not allowed.";
      }
    } else {
      error.email = "Email is required.";
    }

    if (userInput?.password !== "") {
      if (userInput?.password?.length < 8) {
        error.password = "Password should have atleast 8 character.";
      }
    } else {
      error.password = "Password  is required.";
    }

    if (userInput?.firstName === "") {
      error.firstName = "First Name is required.";
    }

    setError(error);
  };

  const handleUserSigninPostSignup = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/login`,
      { ...userInput, flag: "USING_EMAIL" }
    );
    if (
      response?.status === 200 &&
      response?.data?.message === "login successfully"
    ) {
      setShowLoader(false);
      localStorage.setItem("accessToken", response?.data?.data?.token);
      localStorage.setItem("usr", JSON.stringify(response?.data?.data?.user));
      fetchLoginStatus(response?.data?.data?.token);
      setShowForm(false);
      setSigninClicked(false);
      setOpenModal(false);
    }
  };

  const handleUserSignin = async () => {
    setError({ email: "", password: "", firstName: "" });
    if (
      userInput?.email?.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/g) &&
      userInput?.password?.length >= 8
    ) {
      setShowLoader(true);
      setUserMessage({ msg: "", color: "" });
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/login`,
        { ...userInput, flag: "USING_EMAIL" }
      );
      if (
        response?.status === 200 &&
        response?.data?.message === "user doesnot exist"
      ) {
        setShowLoader(false);
        setShowForm(true);
        setSigninClicked(false);
        setUserMessage({ msg: response?.data?.message, color: "red" });
        setUserInput({ email: "", password: "", firstName: "", lastName: "" });
      } else if (
        response?.status === 200 &&
        response?.data?.message === "incorrect password"
      ) {
        setShowLoader(false);
        setSigninClicked(true);
        setShowForm(true);
        setError({ email: "", password: "", firstName: "" });
        setError({
          email: "",
          password: response?.data?.message,
          firstName: "",
        });
      } else if (
        response?.status === 200 &&
        response?.data?.message === "login successfully"
      ) {
        setShowLoader(false);
        localStorage.setItem("accessToken", response?.data?.data?.token);
        localStorage.setItem("usr", JSON.stringify(response?.data?.data?.user));
        fetchLoginStatus(response?.data?.data?.token);
        setShowForm(false);
        setSigninClicked(false);
        setOpenModal(false);
        if (onClose) {
          onClose();
        }
      } else {
        setShowLoader(false);
        setSigninClicked(true);
        setShowForm(false);
        setError({ email: "", password: "", firstName: "" });
        setUserMessage({ msg: response?.data?.message, color: "red" });
      }
    } else {
      handleValidation();
    }
  };

  const handleUserSignup = async () => {
    setError({ email: "", password: "", firstName: "" });

    const emailVerificationStatus = await verifyEmail(userInput?.email);

    if (
      emailVerificationStatus !== "valid" ||
      !userInput?.email?.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/g) ||
      userInput?.password?.length < 8 ||
      userInput?.firstName === ""
    ) {
      handleValidation(emailVerificationStatus);
      return;
    }

    setShowLoader(true);
    setUserMessage({ msg: "", color: "" });
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/signUp`,
      {
        ...userInput,
        flag: "USING_EMAIL",
        sendPeriodicUpdates: sendPeriodicUpdates,
      }
    );

    if (
      response?.status === 200 &&
      response?.data?.message === "signup successfully"
    ) {
      await axios.post(
        `https://cloud.activepieces.com/api/v1/webhooks/D30Izk60RHNZ0npFdw4xe?name=${userInput.firstName}&email=${userInput.email}`
      );
      setShowLoader(false);
      setShowForm(false);
      setSigninClicked(true);
      handleUserSigninPostSignup();
      setError({ email: "", password: "", firstName: "" });
    } else {
      setShowLoader(false);
      setSigninClicked(true);
      setError({ email: "", password: "", firstName: "" });
      setUserMessage({ msg: response?.data?.message, color: "red" });
    }
  };

  useEffect(() => {
    if (!signinClicked && TCChecked && userInput.email) {
      handleUserSignup();
    }
  }, [TCChecked, signinClicked, userInput.email]);

  const handleResetPassword = async () => {
    // make API call
    try {
      setError({ email: "", password: "", firstName: "" });
      setShowLoader(true);
      setUserMessage({ msg: "", color: "" });

      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/forgot-password`,
        { email: userInput.email }
      );
      if (res.data.success) {
        // place the following in the try block
        setOpenModal(false);
        dispatch(closeModal());
        // open reset password modal
        dispatch(
          openModalDispatch({
            name: "ResetPassword",
            type: MODAL_TYPES_ENUM.resetPassword,
            link: userInput.email,
          })
        );
      } else {
        setUserMessage({ msg: res.data.message, color: "red" });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <ModalOverlay sx={{ background: "#3a83c9a8" }} />
        <ModalContent
          my={140}
          mx={5}
          background="#FFF"
          borderRadius="20px"
          p={"20px"}
        >
          {showForgotPwd ? (
            <ModalBody
              py={"40px"}
              px="20px"
              backgroundColor={"#F9FAFB"}
              borderRadius={"20px"}
              mt={0}
            >
              <Text
                fontSize="24px"
                lineHeight="36px"
                textAlign="left"
                fontFamily="Poppins"
                fontWeight={600}
                mb={6}
              >
                {"Forgot your password?"}
              </Text>

              <Text
                fontSize="14px"
                fontFamily="Poppins"
                lineHeight="20px"
                textAlign="center"
                fontWeight={400}
                color={userMessage?.color}
                mb={6}
              >
                {userMessage?.msg}
              </Text>

              <>
                <InputGroup my="14px" position="relative">
                  <InputLeftElement py="27px" ms={1}>
                    <img src={Mail} alt="" />
                  </InputLeftElement>
                  <Input
                    focusBorderColor="#D3D5DA"
                    onFocus={() => setEmailFocused(true)}
                    onBlur={() =>
                      setEmailFocused(userInput.email ? true : false)
                    }
                    onChange={(e) =>
                      setUserInput({ ...userInput, email: e.target.value })
                    }
                    value={userInput?.email}
                    backgroundColor="white"
                    pt="33px"
                    pb="13px"
                    height="55px"
                    fontWeight={400}
                    fontSize="16px"
                    borderRadius="16px"
                    fontFamily="Poppins"
                    border={0}
                    placeholder=" "
                    type="text"
                  />
                  <FormLabel
                    position="absolute"
                    fontFamily="Poppins"
                    top={emailFocused ? "1px" : 3}
                    color="#9EA3AE"
                    fontWeight={400}
                    fontSize={emailFocused ? "12px" : "16px"}
                    left={10}
                    zIndex={2}
                    cursor="text"
                    onClick={() => setEmailFocused(true)}
                  >
                    <FormattedMessage id="emailLabel" />
                  </FormLabel>
                </InputGroup>
                <Text
                  color="red"
                  fontSize="14px"
                  fontWeight={400}
                  ms={3}
                  fontFamily="Poppins"
                >
                  {error?.email}
                </Text>

                <Button
                  textAlign="center"
                  py="28px"
                  width="100%"
                  fontFamily="Poppins"
                  color="white"
                  borderRadius="8px"
                  backgroundColor="#0A64BC"
                  boxShadow="0px 2px 3px rgba(0, 0, 0, 0.25)"
                  my={"14px"}
                  cursor="pointer"
                  _hover={{ backgroundColor: "#0A64BC" }}
                  onClick={() => handleResetPassword()}
                >
                  <Text
                    fontWeight={600}
                    fontSize="16px"
                    fontFamily="Poppins"
                    lineHeight="24px"
                  >
                    {showLoader ? <Spinner /> : "Reset my password"}
                  </Text>
                </Button>
              </>

              <Text
                fontSize="16px"
                fontWeight={400}
                lineHeight="24px"
                textAlign="center"
                fontFamily="Poppins"
                color="#0A64BC"
                cursor="pointer"
                mt={6}
                onClick={() => {
                  setShowForm(true);
                  setShowForgotPwd(false);
                }}
              >
                Take me back to login
              </Text>
            </ModalBody>
          ) : (
            <ModalBody
              py={"40px"}
              px="20px"
              backgroundColor={"#F9FAFB"}
              borderRadius={"20px"}
              mt={0}
            >
              <Text
                fontSize="24px"
                lineHeight="36px"
                textAlign="left"
                fontFamily="Poppins"
                fontWeight={600}
                mb={6}
              >
                {signinClicked
                  ? "Enter your email and password"
                  : "Enter your email and a password"}
              </Text>
              <Text
                fontSize="14px"
                fontFamily="Poppins"
                lineHeight="20px"
                textAlign="center"
                fontWeight={400}
                color={userMessage?.color}
                mb={3}
              >
                {userMessage?.msg}
              </Text>
              <>
                {!signinClicked && (
                  <>
                    <InputGroup my="14px" position="relative">
                      <Input
                        focusBorderColor="#D3D5DA"
                        onFocus={() => setFNameFocused(true)}
                        onBlur={() =>
                          setFNameFocused(userInput.firstName ? true : false)
                        }
                        onChange={(e) =>
                          setUserInput({
                            ...userInput,
                            firstName: e.target.value,
                          })
                        }
                        value={userInput?.firstName}
                        backgroundColor="white"
                        pt="33px"
                        pb="13px"
                        height="55px"
                        fontWeight={400}
                        fontSize="16px"
                        borderRadius="16px"
                        fontFamily="Poppins"
                        border={0}
                        placeholder=" "
                        type="text"
                      />
                      <FormLabel
                        position="absolute"
                        fontFamily="Poppins"
                        top={fNameFocused ? "1px" : 3}
                        color="#9EA3AE"
                        fontWeight={400}
                        fontSize={fNameFocused ? "12px" : "16px"}
                        left={10}
                        zIndex={2}
                        cursor="text"
                        onClick={() => setFNameFocused(true)}
                      >
                        First Name
                      </FormLabel>
                    </InputGroup>
                    <Text
                      color="red"
                      fontSize="14px"
                      fontWeight={400}
                      ms={3}
                      fontFamily="Poppins"
                    >
                      {error?.firstName}
                    </Text>
                  </>
                )}
                {!signinClicked && (
                  <InputGroup my="14px" position="relative">
                    <Input
                      focusBorderColor="#D3D5DA"
                      onFocus={() => setLNameFocused(true)}
                      onBlur={() =>
                        setLNameFocused(userInput.lastName ? true : false)
                      }
                      onChange={(e) =>
                        setUserInput({
                          ...userInput,
                          lastName: e.target.value,
                        })
                      }
                      value={userInput?.lastName}
                      backgroundColor="white"
                      pt="33px"
                      pb="13px"
                      height="55px"
                      fontWeight={400}
                      fontSize="16px"
                      borderRadius="16px"
                      fontFamily="Poppins"
                      border={0}
                      placeholder=" "
                      type="text"
                    />
                    <FormLabel
                      position="absolute"
                      fontFamily="Poppins"
                      top={lNameFocused ? "1px" : 3}
                      color="#9EA3AE"
                      fontWeight={400}
                      fontSize={lNameFocused ? "12px" : "16px"}
                      left={10}
                      zIndex={2}
                      cursor="text"
                      onClick={() => setLNameFocused(true)}
                    >
                      Last Name
                    </FormLabel>
                  </InputGroup>
                )}
                <InputGroup my="14px" position="relative">
                  <InputLeftElement py="27px" ms={1}>
                    <img src={Mail} alt="" />
                  </InputLeftElement>
                  <Input
                    focusBorderColor="#D3D5DA"
                    onFocus={() => setEmailFocused(true)}
                    onBlur={() =>
                      setEmailFocused(userInput.email ? true : false)
                    }
                    onChange={(e) =>
                      setUserInput({ ...userInput, email: e.target.value })
                    }
                    value={userInput?.email}
                    backgroundColor="white"
                    pt="33px"
                    pb="13px"
                    height="55px"
                    fontWeight={400}
                    fontSize="16px"
                    borderRadius="16px"
                    fontFamily="Poppins"
                    border={0}
                    placeholder=" "
                    type="text"
                  />
                  <FormLabel
                    position="absolute"
                    fontFamily="Poppins"
                    top={emailFocused ? "1px" : 3}
                    color="#9EA3AE"
                    fontWeight={400}
                    fontSize={emailFocused ? "12px" : "16px"}
                    left={10}
                    zIndex={2}
                    cursor="text"
                    onClick={() => setEmailFocused(true)}
                  >
                    <FormattedMessage id="emailLabel" />
                  </FormLabel>
                </InputGroup>
                <Text
                  color="red"
                  fontSize="14px"
                  fontWeight={400}
                  ms={3}
                  fontFamily="Poppins"
                >
                  {error?.email}
                </Text>
                <InputGroup my="14px" position="relative">
                  <InputLeftElement pointerEvents="none" py="27px" ms={1}>
                    <img src={Lock} alt="" />
                  </InputLeftElement>
                  <Input
                    fontWeight={400}
                    fontSize="16px"
                    height="55px"
                    pt="33px"
                    pb="13px"
                    fontFamily="Poppins"
                    onFocus={() => setPasswordFocused(true)}
                    onBlur={() =>
                      setPasswordFocused(
                        show || userInput.password ? true : false
                      )
                    }
                    onChange={(e) =>
                      setUserInput({
                        ...userInput,
                        password: e.target.value.trim(),
                      })
                    }
                    value={userInput?.password}
                    backgroundColor="white"
                    focusBorderColor="#D3D5DA"
                    borderRadius="16px"
                    border={0}
                    placeholder=" "
                    type={show ? "text" : "password"}
                  />
                  <InputRightElement
                    me={1}
                    py="27px"
                    onClick={() => setShow(!show)}
                    cursor="pointer"
                    children={<img src={show ? Eye : Eyeshow} alt="" />}
                  />
                  <FormLabel
                    position="absolute"
                    top={passwordFocused ? "1px" : 3}
                    color="#9EA3AE"
                    fontWeight={400}
                    fontSize={passwordFocused ? "12px" : "16px"}
                    left={10}
                    zIndex={2}
                    fontFamily="Poppins"
                    cursor="text"
                    onClick={() => setPasswordFocused(true)}
                  >
                    <FormattedMessage id="passwordLabel" />
                  </FormLabel>
                </InputGroup>
                <Text
                  color="red"
                  fontSize="14px"
                  fontFamily="Poppins"
                  fontWeight={400}
                  ms={3}
                >
                  {error.password}
                </Text>
                {signinClicked && (
                  <Box
                    display="flex"
                    justifyContent={signinClicked ? "start" : "center"}
                    my={6}
                  >
                    <Checkbox
                      height="24px"
                      width="24px"
                      mx={3}
                      className="form_checkBox"
                      backgroundColor="white"
                      borderRadius="8px"
                      border="1.5px solid #D3D5DA"
                      boxShadow={0}
                      type="checkbox"
                      isChecked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                    />
                    <Text
                      fontSize="16px"
                      fontFamily="Poppins"
                      lineHeight="24px"
                      fontWeight={400}
                      cursor="pointer"
                      onClick={() => setIsChecked(!isChecked)}
                    >
                      <FormattedMessage
                        id={signinClicked ? "remindeme" : "acceptT_C"}
                      />
                    </Text>
                    <Text
                      fontSize="16px"
                      fontFamily="Poppins"
                      ms={1}
                      lineHeight="24px"
                      fontWeight={400}
                      color="#0A64BC"
                      cursor="pointer"
                      onClick={() => setIsChecked(!isChecked)}
                    >
                      {!signinClicked && (
                        <FormattedMessage id="privacyPolicy" />
                      )}
                    </Text>
                  </Box>
                )}
                <Button
                  textAlign="center"
                  py="28px"
                  width="100%"
                  fontFamily="Poppins"
                  color="white"
                  borderRadius="8px"
                  backgroundColor="#0A64BC"
                  boxShadow="0px 2px 3px rgba(0, 0, 0, 0.25)"
                  my={"14px"}
                  cursor="pointer"
                  _hover={{ backgroundColor: "#0A64BC" }}
                  onClick={() =>
                    signinClicked ? handleUserSignin() : setOpenTCModal(true)
                  }
                >
                  <Text
                    fontWeight={600}
                    fontSize="16px"
                    fontFamily="Poppins"
                    lineHeight="24px"
                  >
                    {showLoader ? (
                      <Spinner />
                    ) : (
                      <FormattedMessage
                        id={
                          signinClicked
                            ? "signinWithEmailBtn"
                            : "signupWithEmailBtn"
                        }
                      />
                    )}
                  </Text>
                </Button>
              </>

              {signinClicked && (
                <Text
                  fontSize="16px"
                  fontWeight={400}
                  lineHeight="24px"
                  textAlign="center"
                  fontFamily="Poppins"
                  color="#0A64BC"
                  cursor="pointer"
                  mt={6}
                  onClick={() => setShowForgotPwd(true)}
                >
                  Forgot your password?
                </Text>
              )}
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default SigninSignupWithCredentialsModal;
