import { Box, Flex, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import SigninSignup from '../components/Auth/SigninSignup';
import Accordion from '../components/Stocks/Accordion';
import MobileContent from '../layout/MainContent/MobileContent';
import DesktopContent from '../layout/MainContent/DesktopContent';

const Legal = () => {
  return (
    <>
      <MobileContent>
        <DesktopContent>
          <Flex width='375px' flexDirection={'column'} ml='auto' mr='auto'>
            <Box color='white' columnGap={1} position='relative' mb='40px'>
              {/* <Box
                position='absolute'
                right={3}
                top={3}
                style={{ zIndex: 100 }}
              >
                <SigninSignup />
              </Box> */}
              <Text
                fontSize='32px'
                fontWeight='500'
                lineHeight='35px'
                textDecorationColor='white'
                pt={1}
                pl={1}
                color={'#000'}
              >
                <FormattedMessage id='legal_heading' />
              </Text>
            </Box>

            <Box px={2} pt='6' pb={6}>
              <Text
                fontSize='24px'
                fontWeight='500'
                textDecorationColor='white'
              >
                <FormattedMessage id='legal_main_subheading' />
              </Text>

              <Accordion
                titleId='legal_title1'
                descriptionId='legal_description1'
              />

              <Accordion
                titleId='legal_title2'
                descriptionId='legal_description2'
                list={['legal_list1a', 'legal_list2a']}
                footerId='legal_footer1'
              />

              <Accordion
                titleId='legal_title3'
                descriptionId='legal_description3'
              />

              <Accordion
                titleId='legal_title4'
                descriptionId='legal_description4'
              />

              <Accordion
                titleId='legal_title5'
                descriptionId='legal_description4'
              />

              <Accordion
                titleId='legal_title6'
                descriptionId='legal_description6'
              />
            </Box>
          </Flex>
        </DesktopContent>
      </MobileContent>
    </>
  );
};

export default Legal;
