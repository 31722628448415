import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IBatchStockItem, IStock } from '../types/Stock';
import {
  IBalanceSheet,
  ICashFlowStatement,
  IIncomeStatement,
  IPriceInfo,
} from '../types/Core';

export const stockApi = createApi({
  reducerPath: 'stockData',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/api/v1/`,
  }),
  endpoints: (builder) => ({
    fetchStockData: builder.query<IStock, string>({
      query: (fullTicker) => `stocks/${fullTicker}`,
      transformResponse: (response: { data: IStock }, meta, arg) =>
        response.data,
    }),
    fetchSearchStocks: builder.query<IStock[], string>({
      query: (searchValue) => `stocks?limit=20&search=${searchValue}`,
      transformResponse: (response: { data: IStock[] }, meta, arg) =>
        response.data.slice(0, 5),
    }),
    fetchCashflowData: builder.query<ICashFlowStatement[], string>({
      query: (fullTicker) => `stocks/${fullTicker}/cashflow`,
      transformResponse: (
        response: { data: ICashFlowStatement[] },
        meta,
        arg
      ) => response.data,
    }),
    fetchIncomeStatementData: builder.query<IIncomeStatement[], string>({
      query: (fullTicker) => `stocks/${fullTicker}/income-statement`,
      transformResponse: (response: { data: IIncomeStatement[] }, meta, arg) =>
        response.data,
    }),
    fetchBalanceSheetData: builder.query<IBalanceSheet[], string>({
      query: (fullTicker) => `stocks/${fullTicker}/balance-sheet`,
      transformResponse: (response: { data: IBalanceSheet[] }, meta, arg) =>
        response.data,
    }),
    fetchTimeSeriesDailyAdjustedData: builder.query<IPriceInfo[], string>({
      query: (fullTicker) => `stocks/${fullTicker}/time-series-daily-adjusted`,
      transformResponse: (response: { data: IPriceInfo[] }, meta, arg) =>
        response.data,
    }),
    fetchPeerStockData: builder.query<IStock[], string>({
      query: (symbol) => `stocks/peers/${symbol}`,
      transformResponse: (response: { data: IStock[] }, meta, arg) =>
        response.data,
    }),
    fetchBatchCompaniesStockData: builder.query<IBatchStockItem[], string[]>({
      query: (tickers: string[]) =>
        `stocks/batch/companies?tickers=${tickers.join(',')}`,
      transformResponse: (response: { data: IBatchStockItem[] }, meta, arg) =>
        response.data,
    }),
  }),
});

export const {
  useFetchStockDataQuery,
  useFetchCashflowDataQuery,
  useFetchIncomeStatementDataQuery,
  useFetchBalanceSheetDataQuery,
  useFetchTimeSeriesDailyAdjustedDataQuery,
  useFetchPeerStockDataQuery,
  useFetchBatchCompaniesStockDataQuery,
  useFetchSearchStocksQuery,
} = stockApi;
