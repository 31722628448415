import { Box, Flex, Text } from "@chakra-ui/react";
import { CommonButton } from "../common/buttons/common";
import { ReactComponent as Security } from "../../assets/svg/Security.svg";
import { ReactComponent as Pop } from "../../assets/svg/PopUp.svg";
import { Filter } from "../../types/Screener";
import { FormattedMessage } from "react-intl";

interface Props {
  onClick?: () => void;
}
export function NoneCompany({ onClick }: Props) {
  return (
    <Flex
      w="100%"
      h="max-content"
      flexDirection="column"
      alignItems="center"
      backgroundColor="#FFF"
      fontFamily={"Poppins"}
      color="#021425"
    >
      <Box
        mt="30px"
        mb="30px"
        w="250px"
        h="250px"
        display={"grid"}
        placeItems={"center"}
      >
        <Security />
      </Box>
      <Flex
        w="100%"
        alignItems={"center"}
        flexDirection={"column"}
        textAlign={"center"}
      >
        <Text
          fontSize="18px"
          fontWeight={"600"}
          lineHeight={"27px"}
          align="center"
          fontFamily="Poppins"
          noOfLines={2}
        >
          It feels so empty in here...
        </Text>
        <Text
          fontWeight="300"
          fontSize="16px"
          lineHeight="24px"
          fontFamily="Poppins"
          noOfLines={2}
        >
          Add a company and start beating the market!
        </Text>

        <CommonButton
          title={`Add a company +`}
          customProps={{
            mt: "30px",
            mb: "30px",
            height: "44px",
            padding: "10px 15px 10px 15px",
            borderRadius: "20px",
            width: { base: "200px !important" },
            onClick: () => {
              onClick && onClick();
            },
          }}
        ></CommonButton>
      </Flex>
    </Flex>
  );
}

export function NoneWatchlist({
  onClick,
  is,
}: Props & { is: "watchlist" | "portfolio" }) {
  return (
    <Flex
      w="100%"
      h="max-content"
      flexDirection="column"
      alignItems="center"
      backgroundColor="#FFF"
      fontFamily={"Poppins"}
      color="#021425"
    >
      <Box
        mt="30px"
        mb="30px"
        w="250px"
        h="250px"
        display={"grid"}
        placeItems={"center"}
      >
        <Pop />
      </Box>
      <Flex
        w="100%"
        alignItems={"center"}
        flexDirection={"column"}
        textAlign={"center"}
      >
        <Text
          fontSize="18px"
          fontWeight={"600"}
          lineHeight={"27px"}
          align="center"
          fontFamily="Poppins"
          noOfLines={2}
        >
          {`Ooops! You don't have any ${is} yet.`}
        </Text>
        <Text
          fontWeight="300"
          fontSize="16px"
          lineHeight="24px"
          fontFamily="Poppins"
          noOfLines={2}
        >
          Add one and start tracking those companies!
        </Text>

        <CommonButton
          title={`Add a ${is} +`}
          customProps={{
            mt: "30px",
            mb: "30px",
            height: "44px",
            padding: "10px 15px 10px 15px",
            borderRadius: "20px",
            width: { base: "200px !important" },
            onClick: () => {
              onClick && onClick();
            },
          }}
        ></CommonButton>
      </Flex>
    </Flex>
  );
}

export function NoneScreener({ onClick }: Props) {
  return (
    <Flex
      w="100%"
      h="max-content"
      flexDirection="column"
      alignItems="center"
      backgroundColor="#FFF"
      fontFamily={"Poppins"}
      color="#021425"
    >
      <Box
        mt="30px"
        mb="30px"
        w="250px"
        h="250px"
        display={"grid"}
        placeItems={"center"}
      >
        <Pop />
      </Box>
      <Flex
        w="100%"
        alignItems={"center"}
        flexDirection={"column"}
        textAlign={"center"}
      >
        <Text
          fontSize="18px"
          fontWeight={"600"}
          lineHeight={"27px"}
          align="center"
          fontFamily="Poppins"
          noOfLines={2}
        >
          {`Ooops! You don't have any screeners yet!`}
        </Text>
        <Text
          fontWeight="300"
          fontSize="16px"
          lineHeight="24px"
          fontFamily="Poppins"
          noOfLines={2}
        >
          Add one and start filtering those companies.
        </Text>

        <CommonButton
          title={`Add a screener +`}
          customProps={{
            mt: "30px",
            mb: "30px",
            height: "44px",
            padding: "10px 15px 10px 15px",
            borderRadius: "20px",
            width: { base: "200px !important" },
            onClick: () => {
              onClick && onClick();
            },
          }}
        ></CommonButton>
      </Flex>
    </Flex>
  );
}

export function NoneCompanyScreener({ filters }: { filters?: Filter[] }) {
  return (
    <Flex
      w="100%"
      h="max-content"
      flexDirection="column"
      alignItems="center"
      backgroundColor="#FFF"
      fontFamily={"Poppins"}
      color="#021425"
    >
      <Box
        mt="30px"
        mb="30px"
        w="250px"
        h="250px"
        display={"grid"}
        placeItems={"center"}
      >
        <Security />
      </Box>
      <Flex
        w="100%"
        alignItems={"center"}
        flexDirection={"column"}
        textAlign={"center"}
      >
        {filters && filters.length === 0 ? (
          <>
            <Text
              fontSize="18px"
              fontWeight={"600"}
              lineHeight={"27px"}
              align="center"
              fontFamily="Poppins"
              noOfLines={2}
            >
              <FormattedMessage id="filter_required_to_get_data" />
            </Text>
          </>
        ) : (
          <>
            <Text
              fontSize="18px"
              fontWeight={"600"}
              lineHeight={"27px"}
              align="center"
              fontFamily="Poppins"
              noOfLines={2}
            >
              No companies found!
            </Text>
            <Text
              fontWeight="300"
              fontSize="16px"
              lineHeight="24px"
              fontFamily="Poppins"
              noOfLines={2}
            >
              We found no companies matching your current criteria.
            </Text>
            <Text
              fontWeight="300"
              fontSize="16px"
              lineHeight="24px"
              fontFamily="Poppins"
              noOfLines={2}
            >
              Please update your filters.
            </Text>
          </>
        )}
      </Flex>
    </Flex>
  );
}
