import { ChangeEvent } from 'react';

import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';

import { HiMagnifyingGlass } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import { ICompany } from '../../types/Stock';

import styles from './styles.module.css';

type TProps = {
  searchValue: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  show?: boolean;
  reversed?: boolean;
  mobile?: boolean;
  stocks: ICompany[];
  clearSearch: () => void;
  heroStyle?: boolean
};

function CompanySearch({
  searchValue = '',
  onChange,
  show = false,
  reversed = false,
  mobile = false,
  stocks,
  clearSearch,
  heroStyle = false
}: TProps) {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Flex
      alignItems='center'
      columnGap={'12px'}
      justifyContent='flex-start'
      width='full'
      height='60px'
      py='21px'
      px='18px'
      borderRadius='12px'
      maxWidth='658px'
      position='relative'
      background='#FFFFFF1A'
      display={{ md: 'flex', base: show ? 'flex' : 'none' }}
      flexDirection={reversed ? 'row-reverse' : 'row'}
      zIndex={heroStyle ? 0 : 200}
    >
      <Input
        value={searchValue}
        onChange={onChange}
        size='md'
        border='0'
        fontSize={mobile ? '16px' : '20px'}
        fontWeight='light'
        color={'#FFF'}
        placeholder={intl.formatMessage({ id: 'search_placeholder' })}
        _placeholder={{ color: '#FFF', fontSize: mobile ? '16px' : '19px' }}
        pl='0'
        backgroundColor='transparent'
      />

      <HiMagnifyingGlass fontSize='30px' fontWeight='300' color='#CEE0F1' />

      {stocks?.length && searchValue ? (
        <Box
          position={'absolute'}
          top={'52px'}
          left={0}
          width={'100%'}
          backgroundColor={'#04284B'}
          color={'#FFF'}
          className={styles.stocksSearchWrapper}
          zIndex={200}
        >
          <Box
            w={'100%'}
            backgroundColor={!heroStyle ? '#FFFFFF1A' : '#16150A'}
            px='18px'
            pb='12px'
            pt='8px'
            borderBottomLeftRadius={'12px'}
            borderBottomRightRadius={'12px'}
          >
            { !heroStyle && <Divider mb='4' />}

            <Stack spacing={2} width='full'>
              {stocks?.map((stock) => (
                <Grid
                  templateColumns='repeat(12, 1fr)'
                  style={{ cursor: 'pointer' }}
                  gap={1}
                  key={stock.ticker}
                  fontFamily='Poppins'
                  fontSize={mobile ? '14px' : '16px'}
                  className={styles.stockSearchItem}
                  onClick={() => {
                    navigate(
                      `/stocks/${stock.ticker.split(':')[0]}/${
                        stock.ticker.split(':')[1]
                      }`
                    );
                    clearSearch();
                  }}
                >
                  <GridItem colSpan={mobile ? 4 : 3}>
                    <Text fontWeight='bold'>{stock.ticker.split(':')[1]}</Text>
                  </GridItem>

                  <GridItem colSpan={mobile ? 5 : 6}>
                    <Text width='full' noOfLines={1}>
                      {stock.companyName}
                    </Text>
                  </GridItem>

                  <GridItem colSpan={3}>
                    <Text fontWeight='medium' noOfLines={1} textAlign='right'>
                      {stock.ticker.split(':')[0]}
                    </Text>
                  </GridItem>
                </Grid>
              ))}
            </Stack>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Flex>
  );
}

export default CompanySearch;
