import { Box, Image } from "@chakra-ui/react";
import { useMainlyWhite } from "../../../hooks/useMainlyWhite";

interface StockImageProps {
  name: string;
  fullTicker: string;
  image: string;
}

export default function StockImage({
  name,
  fullTicker,
  image,
}: StockImageProps) {

  const {isMainlyWhite} = useMainlyWhite(image,5)
  const mainlyColor = "#1c1c1c" 
  
  return (
    <Box width='70px' 
    height='70px' 
    background={isMainlyWhite ? mainlyColor : "#fff"}
    borderRadius={"50%"}
    padding={"5px"}
    display={'grid'}
    alignItems={'center'}
    justifyItems={'center'}
    >
      <Image
        src={image}
        alt={name}
        fallbackSrc={`${process.env.PUBLIC_URL}/stock-icons/fallback.png`}
          onError={(e: any) => {
            e.target.onError = null;
            e.target.src = `${process.env.PUBLIC_URL}/stock-icons/fallback.png`;
          }}
        fallbackStrategy='beforeLoadOrError'
        boxSize={'70%'}
        minHeight={'70%'}
        objectFit="cover"
        maxWidth={'100%'}
        maxHeight={'none'}
        overflow={'hidden'}
      />
    </Box>
  );
}
