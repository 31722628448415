import { Box } from "@chakra-ui/react";
import { ListItem, CompanyItem, MonkItem, FundamentalsItem, DropdownBox, AddedItem } from "./ItemDropdown";
import { MonkScoreItems, CompanyProfileItems, ListItems, RatiosItems } from "../../../constants/items"
import { useState,useMemo } from "react";
import { AnimatePresence } from "framer-motion"
import {motion} from "framer-motion"

interface FilterMenuProps{
    onChangeMenu: (property:string)=>void
    filterText?:string
}
export function FilterMenu( { onChangeMenu, filterText } : FilterMenuProps){
    const [actualMenu, setActualMenu] = useState<"main" | "list" | "company" | "score" | "fundamentals">("main")

    const innerText = useMemo(()=>{
        if (actualMenu === "list") {
            return "My list"
        } else if (actualMenu === "company") {
            return "Company Profile"
        }
        else if (actualMenu === "score") {
            return "MonkStreet Score"
        }
        else if(actualMenu === "fundamentals"){
            return "Fundamentals"
        }else {
            return undefined
        }
    },[actualMenu])

    return (
        <Box w='280px' h='auto' mt={2} mb={2} bg='white'>
            <DropdownBox innerText={innerText} type={actualMenu} onBack={()=>setActualMenu("main")}>
                {
                    (actualMenu === "main" && (!filterText || filterText === "")) && (<AnimatePresence>
                        <ListItem key={"list"} onGo={()=>setActualMenu("list")} />
                        <CompanyItem  key={"company"} onGo={()=>setActualMenu("company")}/>
                        <MonkItem key={"score"} onGo={()=>setActualMenu("score")}/>
                        <FundamentalsItem key={"fundamentals"} onGo={()=>setActualMenu("fundamentals")}/>
                    </AnimatePresence>)
                }

                {
                    (actualMenu === "company" && (!filterText || filterText === "")) && (<AnimatePresence>
                        <Box w='100%' as={motion.div}
                        animate={{ scale: 1 }}
                        exit={{
                            scale: 0.5,
                            opacity: 0,
                            rotate: 90,
                            x: -100,
                            y: -100,
                            transition: { duration: 0.5, ease: "easeOut" }
                        }}
                        >
                        {
                            CompanyProfileItems.map((v, i) => {
                                return (
                                    <AddedItem key={`${v}`} text={v.label} value={v.value} onAdded={(value) => {
                                        console.log(value)
                                        onChangeMenu(value)
                                    }} />
                                )
                            })
                        }
                        </Box>
                    </AnimatePresence>)
                }

                {
                    (actualMenu === "score" && (!filterText || filterText === "")) && (<AnimatePresence>
                        {
                            MonkScoreItems.map((v, i) => {
                                return (
                                    <AddedItem key={`${v}`} text={v.label} value={v.value}  onAdded={(value) => {
                                        console.log(value)
                                        onChangeMenu(value)
                                    }} />
                                )
                            })
                        }
                    </AnimatePresence>)
                }


                {
                    (actualMenu === "list" && (!filterText || filterText === "")) && (<AnimatePresence>
                        {
                            ListItems.map((v, i) => {
                                return (
                                    <AddedItem key={`${v}`} text={v.label} value={v.value}  onAdded={(value) => {
                                        console.log(value)
                                        onChangeMenu(value)
                                    }} />
                                )
                            })
                        }
                    </AnimatePresence>)
                }

                {
                    (actualMenu === "fundamentals" && (!filterText || filterText === "")) && (<AnimatePresence>
                        {
                            RatiosItems.map((v, i) => {
                                return (
                                    <AddedItem key={`${v}`} text={v.label} value={v.value} onAdded={(value) => {
                                        console.log(value)
                                        onChangeMenu(value)
                                    }} />
                                )
                            })
                        }
                    </AnimatePresence>)
                }

                {
                    (filterText && filterText.trim()!=="") && (<AnimatePresence>
                        {
                            [...CompanyProfileItems,...MonkScoreItems,...ListItems,...RatiosItems].filter((v)=>v.label.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())).map((v, i) => {
                                return (
                                    <AddedItem key={`${v}`} text={v.label} value={v.value} onAdded={(value) => {
                                        console.log(value)
                                        onChangeMenu(value)
                                    }} />
                                )
                            })
                        }
                    </AnimatePresence>)
                }


            </DropdownBox>
            </Box>
    )
}