import { Box, Grid, GridItem, Stack, useMediaQuery } from "@chakra-ui/react";
import { AnimatePresence, Reorder } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { ActionsWatchList } from "../../../components/Box/ActionsWatchlist";
import { SkeletonMultipleCard } from "../../../components/common/skeleton/card";
import { NewPhoneStockEditable } from "../../../components/Stocks/NewStockItem/NewPhoneStockEditable";
import { NewStockWithEditable } from '../../../components/Stocks/NewStockItem/NewStockWithEditable';
import { usePromisedToast } from "../../../hooks/usePromisedToast";
import { useDeleteMutation, useRemoveCompaniesMutation, useUpdateMutation } from "../../../services/watchlistPortfolioApi";
import {
    cleanUpRemoved,
    fetchOne,
    removeCompany,
    removedStocks,
    error as storeError,
    loading as storeLoading,
    stocks as storeStocks,
    watchlist as storeWatchlist,
    setStocks

} from "../../../store/slices/watchlist";
import { IStock } from "../../../types/Stock";
import { WatchlistStock } from "../../../types/Watchlist";
import { itemVariants } from "../../../utils/framerVariants";
import { DraggableCard } from "../../../components/Drag/Card";
import immutabilityUpdate  from "immutability-helper";

interface WatchlistOverviewProps {
    is: 'watchlist' | 'portfolio'
    id: string | number
    name?: string
    onEditName: () => void
}
export function WatchlistOverview({ is, id, name, onEditName }: WatchlistOverviewProps) {

    const [editMode, setEditMode] = useState<boolean>(false)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const stocksCurrent = useSelector(storeStocks)
    const loading = useSelector(storeLoading)
    const error = useSelector(storeError)
    const watchlist = useSelector(storeWatchlist)
    const selectedRemovedStocks = useSelector(removedStocks)
    const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
    const intl = useIntl()
    const { wrapperPromise } = usePromisedToast()

    const mergeProps = (props) => {
        return {
            ...props
        }
    }


    const [
        update,
    ] = useUpdateMutation()

    const [
        mutate,
    ] = useDeleteMutation()

    const [
        remove,
    ] = useRemoveCompaniesMutation()

    useEffect(() => {
        dispatch(fetchOne({ is, id }) as any)
        const timeoutId = setTimeout(() => {
            setEditMode(false)
            clearTimeout(timeoutId)
        }, 1000)
    }, [is, id])

    

    const onSaveName = async (value: string) => {
        update({
            id: id as string,
            name: value,
            isWatchlist: is === 'watchlist'
        }).unwrap().then(() => {
            onEditName()
        })
    }

    const onDelete = async () => {
        mutate({
            id: id as string,
            isWatchlist: is === 'watchlist'
        }).unwrap().then(() => {
            onEditName()
            navigate(`/${is}`, {
                state: {
                    isDeleting: true
                }
            });
        })
    }

    const handleOnEdit = async (stock: Pick<IStock, "image" | "ticker" | "companyName" | "monkScore">) => {
        dispatch(removeCompany({
            ...stock as WatchlistStock
        }))
    }

    const handleSave = async () => {
        try {
            await update({
                id: id as string,
                name: name,
                stocks: stocksCurrent.map((s)=> s.id),
                isWatchlist: is === 'watchlist'
            }).unwrap()
            await remove(
                {
                    id: id as string,
                    isWatchlist: is === 'watchlist',
                    companyIds: [] as string[],
                    tickers: selectedRemovedStocks.map((r) => r.ticker)
                }
            ).unwrap()
            
            dispatch(cleanUpRemoved())
        } catch (error) {

        }
    }

    const moveCard = useCallback((dragIndex:any, hoverIndex:any) => {

          let prevStocks = stocksCurrent
          prevStocks = immutabilityUpdate(prevStocks,{
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, prevStocks[dragIndex]],
              ],
          })
          console.log("Prev:",prevStocks)
          dispatch(setStocks(prevStocks))
          console.log("index:",dragIndex,hoverIndex) 
          console.log(prevStocks) 
      }, [dispatch, stocksCurrent]);

    return (
        <Box w='100%' h='100%' mt={{ base: "0px", md: '20px' }}>
            {(!loading && !error) && <ActionsWatchList
                renderEmpty={stocksCurrent.length === 0}
                is={is}
                name={name || (watchlist?.name ?? '')}
                editMode={editMode}
                onClickEditCompany={() => setEditMode(true)}
                onSaveName={onSaveName}
                onDelete={() => onDelete()}
                onClickSaveButton={() => {
                    wrapperPromise(handleSave(), {
                        titleSuccess: intl.formatMessage({ id: 'titleSave' }),
                        titleError: intl.formatMessage({ id: 'errorTitle' }),
                        titlePending: intl.formatMessage({ id: 'loadingTitle' }),
                        descriptionSuccess: intl.formatMessage({ id: 'descriptionSave' }),
                        descriptionPending: intl.formatMessage({ id: 'saveLoading' }),
                        descriptionError: intl.formatMessage({ id: 'errorDescription' })
                    })
                    setEditMode(false)
                }}
            />}
            {isLargerThan768 && <Grid
                templateColumns={{ base: 'repeat(1,1fr)', md: 'repeat(1,1fr)', lg: 'repeat(3, 1fr)' }}
                mt='15px'
                w={'100%'}
                h={'max-content'}
                flexWrap={'wrap'}
                gap='10px'
                overflowY={'auto'}
                alignItems={{ base: 'center', md: 'stretch' }}
                overflow={'hidden'}
                className="customGrid"
            >
                {(loading || error) ? <SkeletonMultipleCard /> : (
                    <AnimatePresence>
                        {
                            stocksCurrent.map((stock, i, array) => (
                                <GridItem 
                                    as={DraggableCard}
                                    key={stock.id}
                                    id={stock.id}
                                    index={i}
                                    editMode={editMode}
                                    style={{ height: '160px' }}
                                    width={'100%'}
                                    h='160px'
                                    animate={{ scale: 1 }}
                                    exit={{
                                        scale: 0.5,
                                        opacity: 0,
                                        rotate: 90,
                                        x: -100,
                                        y: -100,
                                        transition: { duration: 0.5, ease: "easeOut" }
                                    }}
                                    moveCard={moveCard}
                                >
                                    <NewStockWithEditable
                                        width='100%'
                                        stock={stock}
                                        editMode={editMode}
                                        onEdit={handleOnEdit}
                                    />
                                </GridItem>
                            ))
                        }
                    </AnimatePresence>
                )
                }
            </Grid>
            }
            {
                !isLargerThan768 && <Stack mt={2} spacing={2} display={{ base: 'block', md: 'none' }} as={editMode ? Reorder.Group : 'div'}

                    {
                    ...mergeProps(

                        {
                            drag: 'y',
                            values: stocksCurrent,
                            onReorder: (newValue) => {
                                dispatch(setStocks(newValue))
                            }
                        })
                    }>
                    {
                        (loading || error) ? <SkeletonMultipleCard /> : (
                            <AnimatePresence>
                                {
                                    stocksCurrent.map((stock, i, array) => (
                                        <Box
                                            key={stock.id}
                                            variants={itemVariants}
                                            style={{ width: '100%' }}
                                            {...mergeProps(
                                                {
                                                    initial: "hidden",
                                                    animate: "visible",
                                                    exit: "hidden",
                                                    value: stock
                                                }
                                            )}
                                            as={editMode ? Reorder.Item : 'div'}
                                        >
                                            <NewPhoneStockEditable
                                                stock={stock}
                                                key={i}
                                                editMode={editMode}
                                                onEdit={handleOnEdit}
                                            />
                                        </Box>
                                    ))
                                }
                            </AnimatePresence>
                        )
                    }
                </Stack>
            }

        </Box>
    )
}