import { Box, SkeletonText } from '@chakra-ui/react'

export function SkeletonCard() {
    return <Box
        width={'100%'}
        padding={6} boxShadow={'lg'} bg={'white'}
        my='10px'
    >
        <SkeletonText mt={4} noOfLines={4} spacing={4} skeletonHeight={4} />
        <SkeletonText mt={4} noOfLines={4} spacing={4} skeletonHeight={4} />
    </Box>
}
export function SkeletonMultipleCard() {
    return (
    
        <>
             {Array(20).fill(0).map((_, index) => {
                return <SkeletonCard key={index+1}/>
            })
            }
        </>
           
        
    )
}