import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Screener, Filter } from "../types/Screener";

export const screenerApi = createApi({
  reducerPath: "screenerData",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/api/v1/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        const replacedToken = token.replace(/['"]+/g, "").trim();
        headers.set("authorization", replacedToken);
        headers.set("token", replacedToken);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    fetchScreenerData: builder.query({
      query: () => `/screener`,
      transformResponse: (response: { data: Array<Screener> }, meta, arg) =>
        response.data,
    }),
    fetchOneScreener: builder.query<Screener, string>({
      query: (id) => `/screener/${id}`,
      transformResponse: (response: Screener, meta, arg) => response,
    }),
    create: builder.mutation<Screener, {name:string,filters?:Array<Filter>}>({
      query: ({ name, filters = []}) => ({
        url: `/screener`,
        method: "POST",
        body: {
          name,
          filters
        },
      }),
    }),
    delete: builder.mutation<Screener, {id:string}>({
      query: ({ id }) => ({
        url: `screener/${id}`,
        method: "DELETE",
      }),
    }),
    update: builder.mutation<Screener, Partial<Screener>>({
        query: ({ id, ...patch }) => ({
          url: `screener/${id}`,
          method: "PUT",
          body: patch,
        }),
      }),
  }),
});

export const {
  useFetchScreenerDataQuery,
  useFetchOneScreenerQuery,
  useUpdateMutation,
  useCreateMutation,
  useDeleteMutation,
} = screenerApi;
