import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import InfoIcon from "../../../assets/svg/info.svg";
import "./tooltipModal.css";

const TooltipModal = ({ isOpen, setIsOpen, activeChart }) => {
  const getFormattedRatioName = () => {
    return activeChart === "P/FCF"
      ? "PFCF"
      : activeChart === "P/S"
      ? "PS"
      : activeChart === "Sloan Ratio"
      ? "sloan_ratio"
      : activeChart === "GP/Assets"
      ? "GP_Assets"
      : activeChart === "Net Debt to Equity"
      ? "NDTE"
      : activeChart === "Interest Coverage"
      ? "Interest_Coverage"
      : activeChart === "External Financing"
      ? "Ex_financing"
      : activeChart === "Net Income"
      ? "Net_income"
      : activeChart === "Revenue"
      ? "Revenue"
      : activeChart === "Shareholder Yield"
      ? "shareHolder"
      : activeChart === "Outstanding Shares"
      ? "outstanding"
      : activeChart === "Market cap"
      ? "mktCap"
      : activeChart === "Operating Income"
      ? "operatingIncome"
      : activeChart === "Probability of Default"
      ? "probabilityOfDefault"
      : activeChart === "Quick Ratio"
      ? "quickRatio"
      : activeChart === "Current Ratio"
      ? "currentRatio"
      : activeChart === "Operating Cash Flow"
      ? "operatingCashFlow"
      : activeChart === "Capital Expenditure"
      ? "capEx"
      : activeChart === "Long Term Reinvestment Rate"
      ? "longTermReinvestmentRate"
      : activeChart === "R&D to Enterprise Value"
      ? "rdToEnterpriseValue"
      : activeChart === "LTM FCF Growth As Percentage Of Revenue"
      ? "ltmFCFGrowthAsPercentageOfRevenue"
      : activeChart === "R&D Index Score"
      ? "rdIndexScore"
      : activeChart === "Dividend Yield"
      ? "dividendYield"
      : activeChart === "Buyback Ratio"
      ? "buybackYield"
      : activeChart === "Debt Paydown"
      ? "debtPaydownYield"
      : activeChart === "EV/Revenue"
      ? "evToRevenue"
      : activeChart === "EV/Operating Income"
      ? "evToOperatingIncome"
      : activeChart === "EV/Net Income"
      ? "evToNetIncome"
      : activeChart === "EV/Assets"
      ? "evToAssets"
      : activeChart === "PEG"
      ? "priceToEarningsGrowth"
      : activeChart === "EV/Operating Income Growth"
      ? "evToOperatingIncomeGrowth"
      : activeChart;
  };

  // ratios which doesn't have any contents on notion
  const excludedCharts = ["longTermReinvestmentRate"];

  const thirdParaCharts = [
    "External Financing",
    "Sloan Ratio",
    "Shareholder Yield",
    "Quick Ratio",
    "Capital Expenditure",
    "R&D to Enterprise Value",
    "Dividend Yield",
    "Buyback Ratio",
    "Debt Paydown",
    "EV/Revenue",
    "EV/Operating Income",
    "EV/Assets",
    "PEG",
    "EV/Operating Income Growth",
  ];
  return (
    <>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay sx={{ background: "#f9fafcd4" }} />
        <ModalContent
          mx={4}
          sx={{
            boxShadow:
              "0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
            borderRadius: "12px",
          }}
          my={4}
        >
          <ModalHeader pb={2}>
            <Flex>
              <img src={InfoIcon} alt="" height="50px" width="50px" />
              <Text
                fontSize="16px"
                fontFamily="Poppins"
                fontWeight="500"
                lineHeight="24px"
                display="flex"
                alignItems="center"
                ps={3}
                pe={5}
              >
                <FormattedMessage id={getFormattedRatioName()} />
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton size="lg" />
          <ModalBody pe={8}>
            <Text
              fontSize="14px"
              fontFamily="Poppins"
              fontWeight="400"
              lineHeight="24px"
              color="#475467"
            >
              <FormattedMessage
                id={
                  excludedCharts.includes(getFormattedRatioName())
                    ? "noId"
                    : `${getFormattedRatioName()}_para1`
                }
              />
            </Text>
            <Text
              fontSize="14px"
              fontFamily="Poppins"
              fontWeight="400"
              lineHeight="24px"
              color="#475467"
              mt={3}
            >
              <FormattedMessage
                id={
                  excludedCharts.includes(getFormattedRatioName())
                    ? "noId"
                    : `${getFormattedRatioName()}_para2`
                }
              />
            </Text>
            {thirdParaCharts.includes(activeChart) ? (
              <Text
                fontSize="14px"
                fontFamily="Poppins"
                fontWeight="400"
                lineHeight="24px"
                color="#475467"
                mt={3}
              >
                <FormattedMessage id={`${getFormattedRatioName()}_para3`} />
              </Text>
            ) : null}
          </ModalBody>

          <ModalFooter>
            {/* <Button
              background="white"
              py="25px"
              px="20px"
              style={{
                border: "1px solid #F9FAFB",
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                borderRadius: "16px",
              }}
              color="#344054"
              fontSize="16px"
              fontFamily="Poppins"
              fontWeight="600"
              lineHeight="24px"
              onClick={() => setIsOpen(false)}
            >
              Learn more
            </Button> */}
            <Button
              background="#0A64BC"
              py="25px"
              px="20px"
              style={{
                border: "1px solid #F9FAFB",
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                borderRadius: "16px",
              }}
              color="white"
              ms={5}
              fontSize="16px"
              fontFamily="Poppins"
              fontWeight="600"
              lineHeight="24px"
              onClick={() => setIsOpen(false)}
            >
              Understood
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TooltipModal;
