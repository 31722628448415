export const googleToYahooExchangeMapping = {
  VIE: ".VI",
  EBR: ".BR",
  TSE: ".CN",
  PRG: ".PR",
  EPA: ".PA",
  FRA: ".F",
  ETR: ".DE",
  ATH: ".AT",
  BDP: ".BD",
  IRE: ".IR",
  BIT: ".TI",
  BMV: ".MX",
  AMS: ".AS",
  ELI: ".LS",
  MCX: ".ME",
  BME: ".MC",
  STO: ".ST",
  SWX: ".SW",
  IST: ".IS",
  LON: ".L",
};
