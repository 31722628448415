import {Box, Text} from "@chakra-ui/react"

interface Props {
    isActive: boolean;
    text: string;
    onActive?: () => void 
}

export function ActiveButton(props:Props){

      const borderColor = props.isActive ? "rgba(8, 80, 150, 1)" : "rgba(206, 224, 241, 1)"
      const bgColor = props.isActive ? "rgba(206, 224, 241, 1)" : "#fff"

      return (
        <Box cursor={'pointer'} onClick={()=>props.onActive && props.onActive()} bgColor={bgColor} border={`1px solid ${borderColor}`} gap="8px" padding="8px 16px 8px 16px">
          <Text color={"rgba(8, 80, 150, 1)"} fontFamily={'Poppins'} fontSize={'12px'} fontWeight={400} lineHeight={'20px'}>
                {props.text}
          </Text>
      </Box>
      )
}
