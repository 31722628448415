import { Button, ButtonProps, Text, TextProps, Box } from '@chakra-ui/react';
import { ReactComponent as AddIcon } from "../../../assets/svg/fi_plus.svg";

export function CommonButton({ lightMonkButton = false, onClick, title, icon, customProps = {}, textProps = {} }: { lightMonkButton?: boolean, onClick?: () => void, title: string, icon?: JSX.Element, customProps?:ButtonProps, textProps?:TextProps }) {
    return (
        <Button
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            fontSize={'16px'}
            fontWeight={600}
            backgroundColor={lightMonkButton ? '#FFF' : '#085096'}
            color={lightMonkButton ? '#085096' : '#FFF'}
            colorScheme={lightMonkButton ? 'gray' : 'blue'}
            onClick={onClick}
            gap='10px'
            borderRadius='8px'
            px='15px'
            py='15px'
            width={{base:'200px', md:'100%'}}
            height={{base:'40px', md:'auto'}}
            boxShadow="0px 2px 3px rgba(0, 0, 0, 0.25)"
            fontFamily='Poppins'
            {...customProps}
        >
           
            <Text
                fontWeight='600'
                fontSize='16px'
                lineHeight='24px'
                ml={2}
                {...textProps}
            >
                {title}
            </Text>
            {
                icon && <>{icon}</>
            }
        </Button>
    );
}

export function AddButton({onClick}:{onClick:()=>void}){
    return (<Box my={"15px"} onClick={()=>onClick()} p={"10px"} gap={"10px"} display={'flex'} alignItems={'center'} justifyContent={'center'} w="40px" h="40px" borderRadius={"8px"} border="1px solid rgba(0, 0, 0, 0.1)" bgColor={"rgba(249, 250, 251, 1)"}>
            <AddIcon color={"inherit"} width="20px" height="20px" />
    </Box>)
}

