import React from 'react';
import { data } from './mockData';
import { IStock } from '../../../../types/Stock';
import { Flex, Text, Box } from '@chakra-ui/react';
import ProfileCard from './ProfileCard';

type TProfile = typeof data;

function CompanyProfile({
  compData,
  data,
}: {
  compData: IStock;
  data: TProfile;
}) {
  return (
    <section>
      <Text as={'h2'} fontWeight={'600'} fontSize={'24px'} lineHeight={'36px'}>
        {compData?.companyName} company profile
      </Text>

      <Text
        as={'p'}
        fontWeight={'400'}
        fontSize={'16px'}
        lineHeight={'24px'}
        mb='15px'
      >
        These are the basic facts you need to know about {compData?.companyName}
        .
      </Text>

      <Flex
        color={'#2C3E50'}
        columnGap={'10px'}
        rowGap={'12px'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        flexWrap={'wrap'}
        // maxWidth={'680px'}
        // marginBottom={'12px'}
      >
        <ProfileCard field={'sector'} value={compData?.sector} />
        <ProfileCard field={'industry'} value={compData?.industry} />
        <ProfileCard field={'ceo'} value={compData?.ceo} />
        <ProfileCard field={'exchange'} value={compData?.exchange} />
        <ProfileCard field={'ipoDate'} value={compData?.ipoDate} />
        <ProfileCard field={'website'} value={compData?.website} />
        <ProfileCard field={'description'} value={compData?.description} />
      </Flex>
    </section>
  );
}

export default CompanyProfile;
