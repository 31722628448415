import { Box, Flex, Text, Image } from '@chakra-ui/react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
    companyName: string;
    image: string;
    ticker: string;
    country: string;
}

export const StockTableItem: FC<Props> = (props: Props) => {
    const navigate = useNavigate();
    const { companyName, ticker, image, country } = props;

    return (
        <Box
            ml="-5px"
            height='auto'
            width={'210px'}
            display='flex'
            padding={'2px, 0px, 2px, 0px'}
            rowGap={'5px'}
            alignItems='center'
            backgroundColor='transparent'
            borderRadius='12px'
            cursor='pointer'
            fontFamily='Rubik'
            onClick={() =>
                navigate(`/stocks/${ticker?.split(':')[0]}/${ticker?.split(':')[1]}`)
              }
        >
            <Box width='50px'
                height='50px'
                borderRadius={"50%"}
                padding={"5px"}
                display={'grid'}
                alignItems={'center'}
                justifyItems={'center'}
            >
                <Image
                    src={image}
                    alt={companyName}
                    fallbackSrc={`${process.env.PUBLIC_URL}/stock-icons/fallback.png`}
                    onError={(e: any) => {
                        e.target.onError = null;
                        e.target.src = `${process.env.PUBLIC_URL}/stock-icons/fallback.png`;
                    }}
                    fallbackStrategy='beforeLoadOrError'
                    minHeight={'70%'}
                    objectFit="cover"
                    maxWidth={'100%'}
                    maxHeight={'none'}
                    overflow={'hidden'}
                />
            </Box>

            <Flex
                w={'100%'}
                alignItems={'flex-end'}
                justifyContent={'space-between'}
                columnGap={'16px'}
            >
                <Box ml={'10px'}>
                    <Text
                        fontSize='8px'
                        fontWeight={700}
                        lineHeight='12px'
                        noOfLines={1}
                        fontFamily='Poppins'
                        color={'#1C1C1C'}
                    >
                        {companyName}
                    </Text>
                    <Flex
                        mt={"2px"}
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                    >
                        <img
                            src={`https://purecatamphetamine.github.io/country-flag-icons/1x1/${country ? country : "US"
                                }.svg`}
                            alt=""
                            style={{
                                borderRadius: "50%",
                                height: "10px",
                                width: "10px",
                                marginRight: "4px",
                            }}
                        />
                        <Text
                            fontSize="8px"
                            fontWeight="400"
                            lineHeight="12px"
                            color="#1C1C1C"
                            height={"12px"}
                            textOverflow={"ellipsis"}
                        >
                            {ticker?.split(".")[0]}
                        </Text>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
};
