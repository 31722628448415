import React, { PropsWithChildren } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { IMenu } from '../../../types/common';
import { ButtonsSkeleton } from '../../../components/common/skeleton/buttons';


type LeftCommonSubViewProps = {
  menuLoading?: boolean;
  sectionName: string;
  currentIdx: number;
  onSelectMenu: (index: number, menuItem: Omit<IMenu, "_id">) => void;
  menu?: Array<Omit<IMenu, "_id">>,
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }>
  inactiveIcon: JSX.Element
}

export function LeftCommonSubView({ currentIdx = 0, menu = [], onSelectMenu, icon, sectionName, children, menuLoading = false, inactiveIcon }: PropsWithChildren<LeftCommonSubViewProps>) {

  const Icon = icon as React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }>;


  return (
    <Flex minW='240px' flexDirection='column' alignItems='center' gap='20px' display={{ base: 'none', md: 'flex' }}>
      <Box>
        <Box
          borderRadius='50%'
          backgroundColor='#085096'
          p='15px'
          color='#fff'
          mb='10px'
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          w='100px'
          h='100px'
        >
          <Flex
            flexDirection='column'
            alignItems='center'
            justifyContent='flex-start'
            color='currentColor'
            rowGap='6px'

          >
            <Icon color='inherit' />

          </Flex>
        </Box>
        <Text
          fontSize='16px'
          fontWeight='600'
          lineHeight='24px'
          textAlign='center'
        >
           {sectionName.charAt(0).toUpperCase() + sectionName.slice(1).toLocaleLowerCase()}
        </Text>
      </Box>

      {
        children
      }

      {menuLoading ?
        (<ButtonsSkeleton />)
        :
        (
          <Flex flexDirection='column' gap='10px' width='100%' align={'start'}>
            {menu.map((subItem, idx) => {

              const SubIcon = subItem.icon as React.FunctionComponent<
                React.SVGProps<SVGSVGElement> & {
                  title?: string | undefined;
                }>;
              const Inactive = () => inactiveIcon

              return (
                <Button
                  key={subItem.name}
                  alignItems='center'
                  justifyContent='flex-start'
                  columnGap='10px'
                  borderRadius='10px'
                  backgroundColor={currentIdx === idx ? '#FFF' : '#F9FAFB'}
                  px='10px'
                  py='10px'
                  width='100%'
                  height='auto'
                  marginLeft={currentIdx === idx ? '0px' : '-5px'}
                  onClick={() => onSelectMenu(idx, subItem)}
                >
                  <Box
                    backgroundColor={currentIdx === idx ? '#085096' : 'transparent'}
                    color={currentIdx === idx ? '#FFF' : '#F9FAFB'}
                    p='4px'
                    borderRadius='5px'
                  >
                    { (currentIdx === idx) ? <SubIcon width='24px' height='24px' color='inherit' /> :  <Inactive/> } 
                  </Box>
                  <Text
                    fontWeight='400'
                    fontSize='14px'
                    lineHeight='21px'
                  >
                    {subItem.name}
                  </Text>
                </Button>
              );
            })}
          </Flex>
        )
      }
    </Flex>
  );
}

