import { Flex, Text } from "@chakra-ui/react";
import { CommonModal } from "./Common";
import { ReactComponent as WarningIcon } from '../../assets/svg/warning.svg'
import { CommonButton } from "../common/buttons/common";
import { useCreateMutation } from "../../services/watchlistPortfolioApi";

interface Props {
    name: string;
    isOpen: boolean;
    is:'watchlist' | 'portfolio',
    onDelete?: () => void;
    onOpen?: () => void;
    onClose: () => void;
}

/**
 * Renders a modal for deleting a watchlist or portfolio.
 *
 * @param {Props} props - The component props.
 * @param {boolean} props.isOpen - Indicates whether the modal is open or not.
 * @param {function} props.onClose - The function to call when the modal is closed.
 * @param {'watchlist' | 'portfolio'} props.is - Indicates whether the item is a watchlist or portfolio.
 * @param {string} props.name - The name of the item to be deleted.
 * @param {function} props.onDelete - The function to call when the item is deleted.
 * @return {JSX.Element} The rendered modal component.
 */
export function DeleteWatchlistModal({ isOpen, onClose, is, name, onDelete  }: Props) {


      const [
        create,
        { isLoading: isCreating },
      ] = useCreateMutation()
    
    return (
        <CommonModal 
        modalContentCustomProps={{
            padding: '20px, 20px, 40px, 20px',
            gap: '20px'
        }}
        isOpen={isOpen} onClose={onClose}>
            <Flex flexDirection={'column'} alignItems={'center'}>
                <Flex alignItems={'center'} flexDirection={'column'} textAlign={'center'}>
                    <WarningIcon />
                    <Text
                        fontSize={{ base: '18px', lg: '18px', xl: '18px' }}
                        fontWeight={"600"}
                        lineHeight={"27px"}
                        align="center"
                        fontFamily='Poppins'
                        noOfLines={{base:3, md:2}}
                        my={'20px'}
                    >
                        {`Do you really want to delete the “ ${name} ” ${is}?`}
                    </Text>
                    <Text
                        fontWeight='300'
                        fontSize='16px'
                        lineHeight='24px'
                        fontFamily='Poppins'
                        noOfLines={{base:4,md:3,lg:2}}
                        mb={'20px'}
                    >
                       {`This will remove the ${is} from your account and deactivate any alerts associated. This action can not be undone.`}
                    </Text>
                </Flex>
                
                <CommonButton 
                lightMonkButton
                title='Yes, delete it permanently'
                customProps={{
                    width: {base:'295px', md:'560px'},
                    mt: '20px',
                    mb: '20px',
                    isLoading: isCreating,
                    onClick: async () => {
                        onDelete && onDelete()
                    },
                    height: '56px',
                    borderRadius: '20px',
                    
                    color: '#FF4B4B !important',
                    borderColor: '#FF4B4B !important',
                    border: "1px solid #FF4B4B !important",
                    boxShadow: 'none',
                    _hover:{
                        color: '#fff !important',
                        borderColor: '#FF4B4B !important',
                        bgColor: '#FF4B4B !important',
                        transition: 'all 0.7s'     
                    }
                }}
                ></CommonButton>
                <CommonButton 
                title='Continue without deleting'
                customProps={{
                    onClick: onClose,
                    width: {base:'295px', md:'560px'},
                    height: '56px',
                    borderRadius: '20px',
                    mb: '20px',
                    boxShadow: 'none'
                }}
                />
            </Flex>
        </CommonModal>
    )
}