import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";
import App from "./App";
import { LOCALES } from "./i18n/locales";
import { messages } from "./i18n/messages";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { CustomToastContainer } from "./context/toast";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const locale =
  navigator.language === "en-GB" ? LOCALES.ENGLISH_UK : LOCALES.ENGLISH_US;

const Theme = extendTheme({
  colors: {
    brand: {
      100: "#76CD0C",
      200: "#72CD0C",
    },
  },
});

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
      <IntlProvider
        locale={locale}
        defaultLocale={LOCALES.ENGLISH_US}
        messages={messages[locale]}
      >
        <ChakraProvider theme={Theme}>
          <App />
        </ChakraProvider>
        <CustomToastContainer />
      </IntlProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

reportWebVitals();
