import { Flex, Text } from "@chakra-ui/react";
import { ReactComponent as DoneIcon } from '../../assets/svg/done.svg'
import { CommonButton } from "../common/buttons/common";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"
import { containerVariants } from "../../utils/framerVariants";
interface Props {
    companyName: string;
    is: "watchlist" | "portfolio";
    id: string;
    name: string;
    onClose: () => void
}
export function ContinueNavigation({ companyName, is, id, name, onClose }: Props) {
    const navigate = useNavigate();
    return (
        <Flex
            as={motion.div}
            flexDirection={'column'}
            alignItems={'center'}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit={{ opacity: 0, transition: { duration: 0.5 } }}
        >
            <Flex alignItems={'center'} flexDirection={'column'} textAlign={'center'}>
                <DoneIcon />
                <Text
                    fontSize={{ base: '14px', lg: '16px', xl: '18px' }}
                    fontWeight={"600"}
                    lineHeight={"27px"}
                    align="center"
                    fontFamily='Poppins'
                    noOfLines={{ base: 3, md: 2 }}
                    mb={2}
                    mt={2}
                >
                    {`${companyName} has been added to your ${is} “${name}”`}
                </Text>
            </Flex>

            <CommonButton
                lightMonkButton
                title='Continue exploring'
                customProps={{
                    width: { base: '80%', md: '100' },
                    mt: 2,
                    onClick: async () => {
                        onClose()
                    },
                    variant: 'outline',
                }}
            ></CommonButton>
            <CommonButton
                title={`Go to my ${is}`}
                customProps={{
                    width: { base: '80%', md: '100' },
                    mt: 2,
                    onClick: async () => {
                        navigate(`/${is}/${id}`, { replace: true })
                    },
                    color: "white",
                    _hover: {
                        color: 'white'
                    }
                }}
            />
        </Flex>
    )
}