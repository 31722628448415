import { Button, Flex, Box } from '@chakra-ui/react';
import { useState } from 'react';
import cn from 'classnames';

import { singleStockMenu } from '../../../../constants/menu';
import styles from './styles.module.css';

function MobileStockMenu() {
  const [activeIdx, setActiveIdx] = useState(0);
  return (
    <Box w={{ base: '100%' }} className='scrollable_box'>
      <Flex
        w={'max-content'}
        px='7px'
        alignItems={'center'}
        justifyContent={'flex-start'}
        columnGap={'20px'}
      >
        {singleStockMenu.map((item, idx) => {
          if (item?.hidden) {
            return null;
          }

          return (
            <Button
              key={item?.name}
              // w={'max-content'}
              color={activeIdx === idx ? '#FFF' : '#CEE0F1'}
              variant={'unstyled'}
              fontSize={'14px'}
              fontWeight={activeIdx === idx ? 500 : 400}
              lineHeight={'20px'}
              onClick={() => setActiveIdx(idx)}
              className={cn(
                styles.mobileMenuItem,
                activeIdx === idx && styles.active
              )}
            >
              {item.title}
              {idx === 0 && <sup>®</sup>}
            </Button>
          );
        })}
      </Flex>
    </Box>
  );
}

export default MobileStockMenu;
