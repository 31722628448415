import { Input, Box, Text } from "@chakra-ui/react"
import { useState } from "react";

interface TextNumberInputProps {
    value: number
    onChange: (value:number)=>void
}
export function TextNumberInput({value, onChange}: TextNumberInputProps) {
    return (
        <Box w='60px' bgColor={"#fff"} color="#085096">
            <Input w={{base:"60px",md:"100%"}} value={value} onChange={(event) => onChange(parseFloat(event.target.value))} type='number' placeholder='Value' />
        </Box>
    )
}

interface TextBtwNumberInputProps{
    start?: number
    end?: number
    onChange: (start:number,end:number)=>void
}

export function TextBtwNumberInput({ start = 0, end = 100, onChange }: TextBtwNumberInputProps) {

    const [startValue, setStartValue] = useState(start ?? 0)
    const [endValue, setEndValue] = useState(end ?? 100)

    const handleChangeStart = (event) => {
        const val = parseFloat(event.target.value)
        setStartValue(val)
        onChange(val,endValue)
       
    }

    const handleChangeEnd = (event) => {
        const val = parseFloat(event.target.value)
        setEndValue(val)
        onChange(val, startValue)
        
    }

    return (
        <Box>
            <Box w='60px'  color="#085096" >
                <Input w={{base:"50px",md:"100%"}} bgColor={"#fff"} fontWeight={500} fontSize={"12px"} lineHeight={"20px"} color="#085096" value={startValue} onChange={handleChangeStart} type='number' placeholder='Value' />
            </Box>
            <Text fontFamily={"Poppins"} fontWeight={400} fontSize={"14px"} lineHeight={"20px"}>and</Text>
            <Box w='60px'>
                <Input w={{base:"50px",md:"100%"}} bgColor={"#fff"} fontWeight={500} fontSize={"12px"} lineHeight={"20px"} color="#085096" value={endValue} onChange={handleChangeEnd} type='number' placeholder='Value' />
            </Box>
        </Box>
    )
}