import {
    EditablePreview,
    useColorModeValue,
    IconButton,
    Input,
    useEditableControls,
    ButtonGroup,
    Editable,
    Tooltip,
    EditableInput,
    Flex,
    Button
  } from '@chakra-ui/react'

  import { ReactComponent as CheckIcon} from '../../../assets/svg/check.svg'
  import { ReactComponent as PencilIcon} from '../../../assets/svg/pencil.svg'
  import { ReactComponent as DeleteIcon} from '../../../assets/svg/deleteforever.svg'
import { useEffect, useState } from 'react'
    

interface Props{
    defaultValue: string
    onChange: (nextValue: string) => void
    textSave?: string
    textDelete?: string
    onDelete?: () => void
    small?: boolean
}
export function TextEditable({defaultValue,
     onChange,
     textSave = 'Save',
     textDelete = 'Delete',
     onDelete,
     small = false
    }:Props) {

    const [value, setValue] = useState<string>(defaultValue)
    

    useEffect(()=>{
          setValue(defaultValue)
    },[defaultValue])
    
    function EditableControls() {
      const {
        isEditing,
        getSubmitButtonProps,
        getCancelButtonProps,
        getEditButtonProps,
      } = useEditableControls()
  
      return isEditing ? (
        <ButtonGroup justifyContent='start' size='sm' w='full' spacing={1} mt={1} ml={2} mr={2}>
          <Button 
            variant='ghost'
            aria-label='check'
            leftIcon={<CheckIcon />}
            {...getSubmitButtonProps()}
            color='#58CC02'>
               { !small && textSave }
          </Button>
          <Button 
            variant='ghost'   
            aria-label='delete'
            leftIcon={<DeleteIcon />}
            {...getCancelButtonProps()}
            onClick={(e)=>{
                e.preventDefault()
                onDelete && onDelete()
            }}
            colorScheme='red'
          > { !small && textDelete } </Button>  
        </ButtonGroup>
      ) : (
          <IconButton ml={'-25px'} variant='ghost' aria-label='check' icon={<PencilIcon />} {...getEditButtonProps()}/>
      )
    }
  
    return (
      <Editable
        fontSize={{ lg: '20px', xl: '24px' }}
        fontWeight={"600"}
        lineHeight={"36px"}
        value={value}
        isPreviewFocusable={true}
        selectAllOnFocus={false}
        submitOnBlur={false}
        // mb={10}
        display={'flex'}
        alignItems={'center'}
        onSubmit={onChange}
        onChange={(newValue)=>setValue(newValue)}
        ml={0}
        mr={0}
      >
        <Tooltip label='Click to edit' shouldWrapChildren={true}>
          <EditablePreview
            py={2}
            px={4}
            ml={2}
            mr={2}
            _hover={{
              background: useColorModeValue('gray.100', 'gray.700'),
            }}
          />
        </Tooltip>
        <Flex alignItems={'start'} ml={2} mr={2}>
          <Input py={2} px={4} ml={2} as={EditableInput} />
          <EditableControls />
        </Flex>
      </Editable>
    )
  }