import { country } from "../constants/country";
import { industries } from "../constants/industries";
import {
  CompanyProfileItems,
  ListItems,
  MonkScoreItems,
  RatiosItems,
} from "../constants/items";
import { Filter } from "../types/Screener";
export const findLabelInValue = (
  value: string,
  arr: Array<{ label: string; value: string }>
) => {
  return arr.find((f) => f.value === value);
};

export const findLabelInValueMulti = (
    value: Array<string>,
    arr: Array<{ label: string; value: string }>
  ): Array<{ label: string; value: string }> => {
    const data = Array.isArray(value) ? arr.filter((f) => value.includes(f.value)) : [arr.find((f) => f.value === value)]
    return data as Array<{ label: string; value: string }> ?? [] as Array<{ label: string; value: string }>
  };

function getRandomInt(min, max) {
  // Ensure min is less than or equal to max
  if (min > max) {
    throw new Error("min must be less than or equal to max");
  }

  // Get a random decimal between 0 (inclusive) and 1 (exclusive)
  const randomDecimal = Math.random();

  // Multiply by the range (max - min) and add the minimum value
  const range = max - min + 1; // Add 1 to include max in the range
  const randomInteger = Math.floor(randomDecimal * range) + min;

  return randomInteger;
}

export const createDefaultSelectValue = (property: string) => {
  if (property === "sector") {
    return {
      label: "Technology",
      value: "Technology",
    };
  }

  if (property === "industry") {
    const index = getRandomInt(0, industries.length - 1);
    return {
      label: industries[index],
      value: industries[index],
    };
  }

  if (property === "country") {
    const index = getRandomInt(0, country.length - 1);
    return {
      label: country[index],
      value: country[index],
    };
  }

  if (property === "exchange") {
    return {
      label: "NASDAQ",
      value: "NASDAQ",
    };
  }
};

export const resolveNameProperty = (key: string) => {
  console.log(key)
  return [
    ...CompanyProfileItems,
    ...RatiosItems,
    ...MonkScoreItems,
    ...ListItems,
  ].find((f) => f.value.includes(key))?.label;
};

export const isValidFilters = (filters:Array<Filter>) => {
     if(!filters || !Array.isArray(filters)) return false

     for(let i = 0; i < filters.length; i++) {
       if(!filters[i].value) return false
       if(!isValid(filters[i])) return false
     }

     return true
}

export const isValid = (filter:Filter) => {
  if(typeof filter.value === "string" && filter.value.trim().length === 0) return false

  if(typeof filter.value === "number" && isNaN(filter.value)) return false

  if(Array.isArray(filter.value) && filter.value.length === 0) return false

  return true
}
