import { Flex, Text } from "@chakra-ui/react";
import { CommonModal } from "./Common";
import RatioIcon from "../../assets/svg/ratioiconcolor.svg";
import ProfitabilityCard from "../Stocks/CompanyScores/ProfitabilityCard";
import { useMemo } from "react";
import GrowthCard from "../Stocks/CompanyScores/GrowthCard";
import ShareholderCard from "../Stocks/CompanyScores/ShareholderCard";
import ValuationCard from "../Stocks/CompanyScores/ValuationCard";
import HealthCard from "../Stocks/CompanyScores/HealthCard";
import { FormattedMessage } from "react-intl";

interface Props {
  isOpen: boolean;
  activeChart: string;
  type: string;
  onOpen?: () => void;
  onClose: () => void;
}

const getFormattedRatioName = (activeChart: string) => {
  return activeChart === "P/FCF"
    ? "PFCF"
    : activeChart === "P/S"
    ? "PS"
    : activeChart === "Sloan Ratio"
    ? "sloan_ratio"
    : activeChart === "GP/Assets"
    ? "GP_Assets"
    : activeChart === "Net Debt to Equity"
    ? "NDTE"
    : activeChart === "Interest Coverage"
    ? "Interest_Coverage"
    : activeChart === "External Financing"
    ? "Ex_financing"
    : activeChart === "Net Income"
    ? "Net_income"
    : activeChart === "Revenue"
    ? "Revenue"
    : activeChart === "Shareholder Yield"
    ? "shareHolder"
    : activeChart === "Outstanding Shares"
    ? "outstanding"
    : activeChart === "Market cap"
    ? "mktCap"
    : activeChart === "Operating Income"
    ? "operatingIncome"
    : activeChart === "Probability of Default"
    ? "probabilityOfDefault"
    : activeChart === "Quick Ratio"
    ? "quickRatio"
    : activeChart === "Current Ratio"
    ? "currentRatio"
    : activeChart === "Operating Cash Flow"
    ? "operatingCashFlow"
    : activeChart === "Capital Expenditure"
    ? "capEx"
    : activeChart === "Long Term Reinvestment Rate"
    ? "longTermReinvestmentRate"
    : activeChart === "R&D to Enterprise Value"
    ? "rdToEnterpriseValue"
    : activeChart === "LTM FCF Growth As Percentage Of Revenue"
    ? "ltmFCFGrowthAsPercentageOfRevenue"
    : activeChart === "R&D Index Score"
    ? "rdIndexScore"
    : activeChart === "Dividend Yield"
    ? "dividendYield"
    : activeChart === "Buyback Ratio"
    ? "buybackYield"
    : activeChart === "Debt Paydown"
    ? "debtPaydownYield"
    : activeChart === "EV/Revenue"
    ? "evToRevenue"
    : activeChart === "EV/Operating Income"
    ? "evToOperatingIncome"
    : activeChart === "EV/Net Income"
    ? "evToNetIncome"
    : activeChart === "EV/Assets"
    ? "evToAssets"
    : activeChart === "PEG"
    ? "priceToEarningsGrowth"
    : activeChart === "EV/Operating Income Growth"
    ? "evToOperatingIncomeGrowth"
    : activeChart;
};

// ratios which doesn't have any contents on notion
const excludedCharts = ["longTermReinvestmentRate"];

const thirdParaCharts = [
  "External Financing",
  "Sloan Ratio",
  "Shareholder Yield",
  "Quick Ratio",
  "Capital Expenditure",
  "R&D to Enterprise Value",
  "Dividend Yield",
  "Buyback Ratio",
  "Debt Paydown",
  "EV/Revenue",
  "EV/Operating Income",
  "EV/Assets",
  "PEG",
  "EV/Operating Income Growth",
];

export function ChartModal({ isOpen, onClose, activeChart, type }: Props) {
  const renderChart = useMemo(() => {
    if (type === "profitability") {
      return <ProfitabilityCard activeChart={activeChart as any} />;
    }

    if (type === "health") {
      return <HealthCard activeChart={activeChart as any} />;
    }

    if (type === "valuation") {
      return <ValuationCard activeChart={activeChart as any} />;
    }

    if (type === "growth") {
      return <GrowthCard activeChart={activeChart as any} />;
    }

    if (type === "shareholder") {
      return <ShareholderCard activeChart={activeChart as any} />;
    }
  }, [activeChart, type]);

  return (
    <CommonModal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      headerElement={headerItem(activeChart)}
      color={'#FFF'}
    >
      <Flex flexDirection={"column"} alignItems={"center"} w="100%" mt="10px">
        {renderChart}
      </Flex>
    </CommonModal>
  );
}

function headerItem(activeChart: string): JSX.Element | undefined {
  return (
    <Flex alignItems={"start"}>
      <Flex
        m="20px"
        w="60px"
        height={"60px"}
        display={{ base: "none", sm: "none", md: "contents" }}
        padding={"10px 0px 0px 0px"}
        gap={"10px"}
      >
        <img
          src={RatioIcon}
          alt=""
          style={{
            borderRadius: "10%",
            height: "60px",
            width: "60px",
          }}
        />
      </Flex>
      <Flex flexDirection={"column"} alignItems={"start"}>
        <Text
          fontSize="18px"
          fontWeight={"600"}
          lineHeight={"27px"}
          align="center"
          fontFamily="Poppins"
          noOfLines={2}
        >
          <FormattedMessage id={getFormattedRatioName(activeChart)} />
        </Text>
        <Text
          fontWeight="300"
          fontSize="14px"
          lineHeight="21px"
          fontFamily="Poppins"
          noOfLines={2}
          wordBreak={"break-word"}
          color={"#000000"}
        >
          <FormattedMessage
            id={
              excludedCharts.includes(getFormattedRatioName(activeChart))
                ? "noId"
                : `${getFormattedRatioName(activeChart)}_para2`
            }
          />
        </Text>
      </Flex>
    </Flex>
  );
}
