import { useCallback, useEffect, useState } from "react";

export function useCopyToClipboard(autoReset = false, timeout = 1000) {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = useCallback((text) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  }, []);

  const resetIsCopied = useCallback(() => {
    setIsCopied(false);
  }, []);

  useEffect(() => {
    let timerId;
    if (autoReset && isCopied) {
      timerId = setTimeout(() => {
        setIsCopied(false);
      }, timeout);
    }
    return () => clearTimeout(timerId);
  }, [autoReset, timeout, isCopied]);

  return { isCopied, copyToClipboard, resetIsCopied };
}
