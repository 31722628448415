import { useCallback } from "react";
import {
    Bar,
    BarChart,
    Cell,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from "recharts";
import { useBreakpoint } from "@chakra-ui/react";

export interface EntryChartProps {
    useValue?: boolean,
    data: {
        percentile: number;
        value: number;
        year: string;
        period: string
    }[];
}

function convertTo100Scale(value) {
    const minValue = 0
    const maxValue = 100

    if (value < minValue) {
      return 0; // Handle values below the minimum range
    } else if (value > maxValue) {
      return 100; // Handle values above the maximum range
    } else {
      // Scale within the range
      const normalizedValue = (value - minValue) / (maxValue - minValue);
      return normalizedValue * 100;
    }
  }

export function EntryChart({ data = [], useValue = false }: EntryChartProps) {

    const breakpoint = useBreakpoint();

    const formattedData = useCallback(() => {
        return data.map((item) => ({
            date: new Date(parseInt(item.year), 0, 1),
            value: convertTo100Scale(item.value),
            percentile: convertTo100Scale(item.percentile),
            period: item.period
        }));
    }, [data])

    const isMobile = breakpoint === "base";
    const style= { width: "100%", height: isMobile ? "32px" : "100px" }

    return (
            <div style={style}>
                <ResponsiveContainer width="100%" height="100%" aspect={isMobile ? 1 : 0}>
                <BarChart
                    width={500}
                    height={500}
                    data={formattedData()}
                    barGap={5}
                >
                    <XAxis axisLine={false} tickLine={false} tick={false} />
                    <YAxis
                        type="number"
                        axisLine={false}
                        tickLine={false}
                        fontSize={"12px"}
                        padding={{ bottom: 3 }}
                        tickMargin={isMobile ? 5 : 20}
                        tick={false}
                    />
                    
                            <Bar barSize={100}
                            radius={[5, 5, 0, 0]}
                        dataKey={useValue ? "value" : "percentile"}
                        >
                        {data.map((entry, i, arr) => {
                            return (
                                <Cell
                                    key={`cell-${i}`}
                                    //radius={3}
                                    fill={
                                        arr.length - 1 === i ? "#085096" : "#CEE0F1"    
                                      }
                                />)
                        })}
                    </Bar>
                        
                </BarChart>
        </ResponsiveContainer>
            </div>
    )
}