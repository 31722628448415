import { Flex } from '@chakra-ui/react';

import { IStock } from '../../../types/Stock';
import NewCompanyBasicCard from '../../../components/Stocks/CompanyCard/NewCompanyBasicCard';

function CompanyLogoSection({ compData }: { compData: IStock }) {
  return (
    <Flex
      as={'section'}
      display={{ base: 'flex', md: 'none' }}
      borderRadius='20px'
      backgroundColor='#F9FAFB'
      p='30px'
      width={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
      mb='40px'
    >
      <NewCompanyBasicCard compData={compData} />
    </Flex>
  );
}

export default CompanyLogoSection;
