import { Box, Flex, Text } from '@chakra-ui/react';

import styles from './styles.module.css';
import CustomLink from '../../components/common/CustomLink';
import { rightSideBarMenu } from '../../constants/menu';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/slices/modal';
import { MODAL_TYPES_ENUM } from '../../constants/variables';

function RightSideBar() {
  const dispatch = useDispatch();

  const linkElements = rightSideBarMenu?.map((item) => {
    const Icon = item.icon;

    return (
      <CustomLink
        key={item?.name}
        as='button'
        className={styles.link}
        onClick={() =>
          dispatch(
            openModal({
              name: item?.title,
              link: item?.link,
              type: MODAL_TYPES_ENUM.iframe,
            })
          )
        }
      >
        <Flex
          as={'div'}
          flexDirection='column'
          alignItems='center'
          justifyContent='flex-start'
          rowGap='6px'
          w={'45px'}
          className={styles.wrapper}
        >
          <Icon />
          <Text
            fontSize='8px'
            lineHeight='12px'
            fontWeight='400'
            w={'fit-content'}
          >
            {item.title}
          </Text>
        </Flex>
      </CustomLink>
    );
  });

  return (
    <Box
      display={{ md: 'flex', base: 'none' }}
      flexDirection='column'
      alignItems='center'
      justifyContent='flex-start'
      rowGap={'20px'}
      position='fixed'
      top='100px'
      right='0'
      px='27px'
      pt='60px'
      width='64px'
      height='100%'
      backgroundColor='#04284B'
      fontFamily='Poppins'
      zIndex={200}
    >
      {linkElements}
    </Box>
  );
}

export default RightSideBar;
