import { Box, Flex, Image as ChakraImage, Text } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { IStock } from "../../../types/Stock";
import { useMainlyWhite } from "../../../hooks/useMainlyWhite";

interface Props {
  compData?: IStock;
  small?: boolean;
}

const checkImage = (path) =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ path, status: "ok" });
    img.onerror = () => resolve({ path, status: "error" });

    img.src = path;
  });

const NewCompanyBasicCard = ({ compData, small = false }: Props) => {
  const logoRef = useRef<any>();

  useEffect(() => {
    const url = compData?.image;

    checkImage(url);
  }, [compData]);

  const imageSrc = `${process.env.PUBLIC_URL}/stock-icons/${compData?.symbol}-${compData?.exchangeShortName}.png`
  const {isMainlyWhite} = useMainlyWhite(imageSrc,5)

  return (
    <Flex alignItems="center" flexDirection="column" justifyContent="center">
      <Flex
        alignItems="center"
        bgColor="#F9FAFB"
        borderRadius="20px"
        flexDirection={small ? "row" : "column"}
        columnGap={small ? "5px" : "0"}
      >
        <Box height={small ? "30px" : "100px"}
          width={small ? "30px" : "100px"}
          mb={small ? 0 : "15px"} 
          borderRadius={isMainlyWhite ? "50%": "50%"}
          bgColor={isMainlyWhite ? "#1c1c1c" : "#FFFFFF"}
          padding={"3px"} 
          display={'grid'}
          alignItems={'center'}
          justifyItems={'center'}>
        <ChakraImage
          src={`${process.env.PUBLIC_URL}/stock-icons/${compData?.symbol}-${compData?.exchangeShortName}.png`}
          ref={logoRef}
          alt={compData && compData.companyName}
          fallbackSrc={`${process.env.PUBLIC_URL}/stock-icons/fallback.png`}
          onError={(e: any) => {
            e.target.onError = null;
            e.target.src = `${process.env.PUBLIC_URL}/stock-icons/fallback.png`;
          }}
          fallbackStrategy='beforeLoadOrError'
          //borderRadius={isMainlyWhite ? "full": "0px"}
          //bgColor={isMainlyWhite ? "#1c1c1c" : "#ffffff"}
          //padding={"5px"}
          //border="1px solid gray.50"
          // backgroundColor='gray.300'
          boxSize={'70%'}
          minHeight={'70%'}
          objectFit="cover"
          maxWidth={'100%'}
          maxHeight={'none'}
          overflow={'hidden'}
        />
        </Box>
        
        <Box w={"max-content"}>
          <Text
            fontSize={small ? "8px" : "16px"}
            fontWeight={small ? "700" : "600"}
            lineHeight={small ? "12px" : "24px"}
            textAlign={small ? "left" : "center"}
            noOfLines={2}
          >
            {compData && compData?.companyName}
          </Text>
          <Flex
            mt={small ? "2px" : "4px"}
            alignItems={"center"}
            justifyContent={small ? "flex-start" : "center"}
          >
            <img
              src={`https://purecatamphetamine.github.io/country-flag-icons/1x1/${
                compData && compData.country ? compData.country : "US"
              }.svg`}
              alt=""
              style={{
                borderRadius: "50%",
                height: "10px",
                width: "10px",
                marginRight: "4px",
              }}
            />
            <Text
              fontSize="7px"
              fontWeight="400"
              lineHeight="14px"
              color="#1C1C1C"
              height={"12px"}
              textOverflow={"ellipsis"}
            >
              {compData?.ticker?.split(".")[0]}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default NewCompanyBasicCard;
