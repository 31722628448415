import { InputGroup, Input, Box, InputRightElement } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { useState, useRef, useEffect } from "react";
import { FilterMenu } from "../menu/FilterMenu";
import { useClickOutsideWithBuffer } from "../../../hooks/useClicks";

interface TextPropertyInputProps{
    propertyName: string
    onChangeProperty: (property:string) => void
}
export function TextPropertyInput(props: TextPropertyInputProps) {
    const [showMenu, setShowMenu] = useState(false)
    const ref = useRef()
    useClickOutsideWithBuffer(ref,50,()=>setShowMenu(false))
    /*useOutsideClick({
        ref: ref as any,
        handler: () => setShowMenu(false),
      })*/
    useEffect(()=>{

        return ()=> {
            setShowMenu(false)
        }
    },[])
    return (
        <>
               <InputGroup w='180px' h="40px" gap="10px" cursor={"pointer"} >
                    <Input 
                    bgColor={"#fff"}
                    value={props.propertyName}
                    w='178px' type='text' placeholder='Property' fontWeight={500} fontSize={"12px"} lineHeight={"20px"} color="#085096"/>
                    <InputRightElement pointerEvents='all'>
                        <ChevronDownIcon zIndex={500} cursor={"pointer"} color='blue.300' onClick={(e)=>{
                            e.preventDefault()
                            setShowMenu(true)}}/>
                    </InputRightElement>
                </InputGroup>  
            <>
                {
                    showMenu && (
                        <Box ref={ref as any} mt={60} mr={10} w='260px' position={'absolute'} zIndex={1000} borderRadius={"8px"}>
                            <FilterMenu onChangeMenu={(property:string) => {
                                props.onChangeProperty(property)
                                setShowMenu(false)
                            }}/>
                        </Box>
                    )
                }
            </>
        </>
    )
}