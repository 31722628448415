import { ChangeEvent, useState } from 'react';

import {
    Box,
    Flex,
    Grid,
    GridItem,
    Input,
    Stack,
    Text,
    InputGroup,
    InputRightElement
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';

import { HiMagnifyingGlass } from 'react-icons/hi2';
import { ICompany } from '../../types/Stock';

import styles from './styles.module.css';
import { axiosInstance } from '../../context/api';

type TProps = {
    searchValue: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
    show?: boolean;
    reversed?: boolean;
    mobile?: boolean;
    clearSearch: () => void;
    onClickStock: (stock: ICompany) => void
    bgColor?: string
};

export function ActionableCompanySearch({

    searchValue = '',
    onChange,
    show = false,
    reversed = false,
    mobile = false,
    bgColor = "#0564B8",
    clearSearch,
    onClickStock
}: TProps) {

    const intl = useIntl();
    const [stocks, setStocks] = useState<ICompany[]>([]);


    const onCommonChange = async (e: ChangeEvent<HTMLInputElement>) => {

        onChange(e);

        const res = await axiosInstance.get(
            `/stocks`,
            {
                params: { limit: 100, search: e.target.value },
            }
        );

        const filteredData = res.data.data.filter(
            (e: ICompany, i: number) =>
                res.data.data.findIndex((a: ICompany) => a.symbol === e.symbol) === i &&
                i <= 5
        );

        setStocks(filteredData);
    };


    return (
        <Flex
            alignItems='center'
            columnGap={'12px'}
            justifyContent='flex-start'
            width='full'
            height='60px'
            py='21px'
            px='18px'
            borderRadius='12px'
            maxWidth='658px'
            position='relative'
            background='#FFFFFF1A'
            flexDirection={reversed ? 'row-reverse' : 'row'}
            zIndex={200}
        >
            <InputGroup>
                <Input
                    value={searchValue}
                    onChange={onCommonChange}
                    border='0'
                    fontSize={mobile ? '16px' : '20px'}
                    fontWeight='light'
                    color={'#FFF'}
                    placeholder={intl.formatMessage({ id: 'search_placeholder' })}
                    _placeholder={{ color: '#FFF', fontSize: mobile ? '16px' : '19px' }}
                    padding={'12px, 18.75px, 12px, 18.75px'}
                    w='100%'
                    backgroundColor={bgColor}
                />
                <InputRightElement>
                    <HiMagnifyingGlass fontSize='27px' fontWeight='300' color='#CEE0F1' />
                </InputRightElement>
            </InputGroup>



            {stocks?.length && searchValue ? (
                <Box
                    position={'absolute'}
                    backgroundColor={bgColor}
                    color={'#FFF'}
                    className={styles.commonSearch}
                    zIndex={200}
                    borderBottomLeftRadius={'12px'}
                    borderBottomRightRadius={'12px'}
                    
                >
                    <Box
                        w={'100%'}
                        px='18px'
                        pb='12px'
                        pt='8px'
                        borderBottomLeftRadius={'12px'}
                        borderBottomRightRadius={'12px'}
                    >

                        <Stack spacing={2} width='full'>
                            {stocks?.map((stock) => (
                                <Grid
                                    templateColumns='repeat(12, 1fr)'
                                    style={{ cursor: 'pointer' }}
                                    gap={1}
                                    key={stock.ticker}
                                    fontFamily='Poppins'
                                    fontSize={mobile ? '14px' : '16px'}
                                    className={styles.stockSearchItem}
                                    onClick={() => {
                                        onClickStock(stock)
                                        clearSearch();
                                    }}
                                >
                                    <GridItem colSpan={mobile ? 4 : 3}>
                                        <Text fontWeight='bold'>{stock.ticker.split(':')[1]}</Text>
                                    </GridItem>

                                    <GridItem colSpan={mobile ? 5 : 6}>
                                        <Text width='full' noOfLines={1}>
                                            {stock.companyName}
                                        </Text>
                                    </GridItem>

                                    <GridItem colSpan={3}>
                                        <Text fontWeight='medium' noOfLines={1} textAlign='right'>
                                            {stock.ticker.split(':')[0]}
                                        </Text>
                                    </GridItem>
                                </Grid>
                            ))}
                        </Stack>
                    </Box>
                </Box>
            ) : (
                <></>
            )}
        </Flex>
    );
}


