import { Box, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import { AiOutlineMinus } from 'react-icons/ai';
import { AiOutlinePlus } from 'react-icons/ai';
import {
  FormattedMessage,
  //  useIntl
} from 'react-intl';

interface Props {
  data: { title: String; description: String };
}

const FAQBox: FC<Props> = ({ data }) => {
  return (
    <Box bgColor='#F9FAFB' padding='5px' borderRadius='20px'>
      <Accordion defaultIndex={[1]} allowMultiple>
        <AccordionItem border='none'>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton _hover={{ background: '#F9FAFB' }}>
                  <Box
                    as='span'
                    flex='1'
                    textAlign='left'
                    fontWeight='600'
                    fontSize='14px'
                    pr={{ base: '53px', md: 10 }}
                    mr={{ base: 0, md: 5 }}
                    lineHeight='21px'
                  >
                    <FormattedMessage
                      id='FAQHeading'
                      values={{
                        FAQtitle: data.title,
                      }}
                    />
                  </Box>
                  <Flex borderRadius={'50%'} backgroundColor={'#FFF'} p='8px'>
                    {isExpanded ? (
                      <AiOutlineMinus fontSize='12px' color='#085096' />
                    ) : (
                      <AiOutlinePlus fontSize='12px' color='#085096' />
                    )}
                  </Flex>
                </AccordionButton>
              </h2>
              <AccordionPanel
                pt={0}
                pb={4}
                fontWeight='400'
                fontSize='12px'
                lineHeight='20px'
                color='#6C727F'
              >
                <FormattedMessage
                  id='FAQDescription'
                  values={{
                    FAQdesc: data.description,
                  }}
                />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default FAQBox;
