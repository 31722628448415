import { Box, Flex, Text, Button, useDisclosure } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import GrowthBadge from "../../common/badges/GrowthBadge";
import GenericChart from "../GenericChartScores/GenericChart";
import {
  FormatChartNumber,
  handleTitleSubtext,
} from "../../../utils/formatChartNumber";
import { EntryBoxChart } from "../EntryChart/Box";
import CompanyMetaRow from "../../../subviews/RightSubview/Stocks/CompanyMetaRow";
import { IStock } from "../../../types/Stock";
import { useDispatch, useSelector } from "react-redux";
import {
  actualActiveChart,
  actualScore,
  actualStock,
  openActiveChart,
} from "../../../store/slices/stock";
import { useScaleCompanyRatio } from "../../../hooks/useData";
import "./MonkScoreChartCard.css";

interface ProfitabilityScoreCardProps {
  stockData: IStock;
  fullTicker: string;
  companyName: string;
  score: number;
}

const ratioDict = {
  ROIC: "returnOnInvestedCapital",
  ROE: "returnOnEquity",
  ROA: "returnOnAssets",
  "GP/Assets": "grossProfitToAssets",
};

const ProfitabilityChartCard = () => {
  const data = useSelector(actualScore);
  const stock = useSelector(actualStock);
  const dispatch = useDispatch();

  const onClickChart = (name: string) => {
    setTimeout(() => {
      dispatch(
        openActiveChart({
          type: "profitability",
          active: name,
        }) as any
      );
    }, 100);
  };

  const getChartProfitability = useCallback(() => {
    if (data) {
      return data.profitabilityScoreTimeline.length > 0
        ? data.profitabilityScoreTimeline
        : [];
    }
    return [];
  }, [data]);

  const sign = "%";

  const oneYearMetric = useCallback(() => {
    if (data) {
      const profitability = data.profitabilityScoreTimeline;
      return Math.floor(
        profitability.length > 0
          ? profitability[profitability.length - 1].percentile
          : 0
      );
    }
    return 0;
  }, [data]);

  const lastUpdate = new Date(stock?.lastScoreUpdateDate || Date.now())
    ?.toLocaleDateString()
    ?.replaceAll("/", "-")
    ?.split("-")
    .reverse()
    .join("-");

  const { chartData } = useScaleCompanyRatio();

  const intl = useIntl();

  return (
    <Box
      w="100%"
      h="auto"
      m="auto"
      display={"flex"}
      flexDirection={"column"}
      mb="10px"
    >
      <Text as={"p"} fontSize={"14px"} lineHeight={"21px"} fontWeight={700}>
        <FormattedMessage
          id="factors_generic"
          values={{
            name: stock?.companyName ?? "",
            quality: handleTitleSubtext(stock?.monkScore),
            card: "profitability",
          }}
        />{" "}
        <Text as="span" fontSize={"14px"} lineHeight={"21px"} fontWeight={400}>
          compared to its peers.
        </Text>
      </Text>

      <Box bgColor="#F9FAFB" id="ProfitabilityScoreCard">
        <Box
          position="relative"
          my={"10px"}
          backgroundColor={"#FFF"}
          borderRadius={"20px"}
          pt="20px"
          pb="2px"
        >
          <Box px="20px">
            <Flex
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              gap="10px"
            >
              <Text
                as={"p"}
                fontSize={"24px"}
                lineHeight={"36px"}
                fontWeight={500}
                display={"flex"}
                alignItems={"flex-end"}
              >
                {FormatChartNumber({
                  payload: Math.floor(oneYearMetric()),
                })}{" "}
                / 100
              </Text>

              <Button
                width={"auto"}
                fontSize={"14px"}
                lineHeight={"21px"}
                fontWeight={400}
                display={"flex"}
                alignItems={"center"}
                variant={"unstyled"}
                p="0"
                height={"auto"}
                justifyContent={"flex-end"}
                flexWrap={"wrap"}
                wordBreak={"break-all"}
                textDecoration="none"
                columnGap={"4px"}
              >
                <Text display={"flex"} alignItems={"center"}>
                  <FormattedMessage
                    id="evolution"
                    values={{
                      name: "Profitability",
                    }}
                  />{" "}
                </Text>
              </Button>
            </Flex>

            <Box mb="20px">
              <GrowthBadge
                vector={oneYearMetric() > 0 ? "up" : "down"}
                meta={`${
                  oneYearMetric() > 0 ? "+" : ""
                }${oneYearMetric()?.toFixed(2)}${sign} last 1 year`}
              />
            </Box>

            <Box>
              <GenericChart data={getChartProfitability()} />
            </Box>
          </Box>

          <Box px="10px">
            {stock && (
              <CompanyMetaRow
                compData={stock}
                lastUpdate={lastUpdate}
                idElementForScreenshot={`ProfitabilityScoreCard`}
                isChart
                styledBox
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box w="100%" my="10px">
        <Text
          id="message-container"
          as={"p"}
          fontSize={"14px"}
          lineHeight={"21px"}
        >
          <FormattedMessage id="factor_below_prefix" />
          <FormattedMessage
            id="factor_metric_company"
            values={{
              name: stock?.companyName ?? "",
            }}
          >
            {(txt) => <span className="highlight">{txt}</span>}
          </FormattedMessage>
          <FormattedMessage
            id="factor_subfix"
            values={{
              metric_name: intl.formatMessage({ id: "profitability_name" }),
            }}
          />
        </Text>
      </Box>

      <Box bgColor="#F9FAFB" id="ProfitabilityCardRatios">
        <Box
          my={"10px"}
          backgroundColor={"#FFF"}
          borderRadius={"20px"}
          
          pt="20px"
          pb="2px"
          display={"flex"}
          flexDirection={"column"}
        >
          <Box px="20px">
            <Text
              my={"20px"}
              fontWeight={600}
              fontSize={"16px"}
              lineHeight={"24px"}
            >
              Marging Percentiles
            </Text>
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="Gross Profit"
              data={chartData("ROIC")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="Operating Profit"
              data={chartData("ROE")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="Net Profit"
              data={chartData("ROA")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="FCF Profit"
              data={chartData("ROCE")}
            />

            <Text
              my={"20px"}
              fontWeight={600}
              fontSize={"16px"}
              lineHeight={"24px"}
            >
              Return Percentiles
            </Text>
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="ROIC"
              data={chartData("ROIC")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="ROE"
              data={chartData("ROE")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="ROA"
              data={chartData("ROA")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="ROCE"
              data={chartData("ROCE")}
            />
          </Box>

          <Box px="10px">
            {stock && (
              <CompanyMetaRow
                compData={stock}
                lastUpdate={lastUpdate}
                idElementForScreenshot={`ProfitabilityCardRatios`}
                isChart
                styledBox
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfitabilityChartCard;
