import {
  Box,
  Flex,
  Text,
  CircularProgress,
  CircularProgressLabel,
} from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";

import { monkScoreToColor } from "../../utils/monkScore";

interface MonkScoreProps {
  monkScore: number;
  thin?: boolean;
  withArrowIcon?: boolean;
}

export default function NewMonkScore({
  monkScore,
  thin = false,
}: MonkScoreProps) {
  const Title = thin ? (
    <Text
      fontSize="12px"
      fontWeight="400"
      lineHeight="14px"
      me={2}
      color="#999A9B"
    >
      {monkScore >= 0 ? Math.floor(monkScore) : ""}
    </Text>
  ) : (
    <Text fontSize={{ base: "10px", md: "12px" }} lineHeight="18px" me={2}>
      {monkScore >= 0 ? Math.floor(monkScore) : ""}
    </Text>
  );

  return (
    <Flex alignItems={"center"}>
      {Title}

      <Box
        backgroundColor={monkScoreToColor(monkScore)}
        height="21px"
        width="21px"
        minW={"21px"}
        minH="21px"
        borderRadius="50%"
      >
        &nbsp;
      </Box>
    </Flex>
  );
}

export function CircleMonkScore({ monkScore }: MonkScoreProps) {

  return (
    <Box
      w="60px"
      h="50px"
      pr="10px"
      display={"flex"}
      alignItems={"center"}
      justifyContent={"flex-end"}
    >
      <Text
        fontFamily={"Poppins"}
        fontWeight={400}
        fontSize={"12px"}
        lineHeight={"18px"}
        color={"#222222"}
        mx={"5.42px"}
      >
        {Math.floor(monkScore)}
      </Text>
      <CircularProgress
        size={"20px"}
        thickness="15px"
        value={monkScore >= 0 ? Math.floor(monkScore) : 0}
        color={monkScoreToColor(monkScore)}
      ></CircularProgress>
    </Box>
  );
}

export function NewMonkScoreTable({ monkScore, thin = false }: MonkScoreProps) {
  const Title = thin ? (
    <Text
      fontSize="12px"
      fontWeight="400"
      lineHeight="14px"
      me={2}
      color="#999A9B"
    >
      {monkScore >= 0 ? Math.floor(monkScore) : ""}
    </Text>
  ) : (
    <Text fontSize={{ base: "10px", md: "12px" }} lineHeight="18px" me={2}>
      {monkScore >= 0 ? Math.floor(monkScore) : ""}
    </Text>
  );

  return (
    <Flex alignItems={"center"} justifyContent={"flex-end"} pr={"10px"} w="60px" h="50px">
      {Title}

      <Box
        backgroundColor={monkScoreToColor(monkScore)}
        height="21px"
        width="21px"
        minW={"21px"}
        minH="21px"
        borderRadius="50%"
      >
        &nbsp;
      </Box>
    </Flex>
  );
}
