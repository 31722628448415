import { useCallback } from "react";
import { useSelector } from "react-redux";
import { actualRecentCompanyRatios, actualRatios,actualScaleCompanyRatios } from "../store/slices/stock";

const ratioDict = {
  ROIC: "returnOnInvestedCapital",
  ROE: "returnOnEquity",
  ROA: "returnOnAssets",
  "GP/Assets": "grossProfitToAssets",
  "Net Debt to Equity": "netDebtToEquity",
  "Interest Coverage": "interestCoverage",
  "External Financing Ratio": "externalFinancingRatio",
  "Probability of Default": "probabilityOfDefault",
  "Quick Ratio": "quickRatio",
  "Current Ratio": "currentRatio",
  PER: "priceToEarnings",
  "P/FCF": "priceToFCF",
  "P/S": "priceToSales",
  "EV/Revenue": "evToRevenue",
  "EV/Operating Income": "evToOperatingIncome",
  "EV/Net Income": "evToNetIncome",
  "EV/Assets": "evToAssets",
  PEG: "priceToEarningsGrowth",
  "EV/Operating Income Growth": "evToOperatingIncomeGrowth",
 
  //Growth
  FCF: "FCFLTM",
  "Net Income": "NetIncomeLTM",
  Revenue: "RevenueLTM",
  "Operating Income": "OperatingIncomeLTM",
  "Operating Cash Flow": "OperatingCashFlowLTM",
  "Capital Expenditure": "CapExLTM",
  //Growth

  //Shareholder
  "Dividend Yield": "dividendYield",
  "Buyback Ratio": "buybackYield",
  "Debt Paydown": "debtPaydownYield",
  "Shareholder Yield": "shareholderYield",
  //Shareholder
};

export function useRatioCompanyData() {
  const companyRatios = useSelector(actualRecentCompanyRatios);

  const mostRecentRatio = useSelector(actualRatios);

  const chartData = useCallback(
    (key: string) => {
      const ratiosData = mostRecentRatio?.statementsDate
        ? [mostRecentRatio, ...companyRatios]
        : companyRatios;

      const data = ratiosData
        ? ratiosData?.map((val, i) => ({
            percentile: val[ratioDict[key]] || 0,
            value: (val[ratioDict[key]] || 0),
            year: `${val.calendarYear}`,
            period: `${val.period}`,
          }))
        : [];
        
      return data.reverse();
    },
    [companyRatios, mostRecentRatio]
  );

  return { chartData };
}


export function useScaleCompanyRatio() {

  const companyRatios = useSelector(actualScaleCompanyRatios);

  const chartData = useCallback(
    (key: string) => {
      const ratiosData = companyRatios;

      const data = ratiosData
        ? ratiosData?.map((val, i) => ({
            percentile: val[ratioDict[key]] || 0,
            value: (val[ratioDict[key]] || 0),
            year: `${val.calendarYear}`,
            period: `${val.period}`,
          }))
        : [];
        
      return data.reverse();
    },
    [companyRatios]
  );

  return { chartData };
}
