import { FC, useState } from "react";
import { useIntl } from "react-intl";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CustomTooltip } from "./CustomTooltip";
import { useBreakpoint } from "@chakra-ui/react";

export interface CandlestickChatProps {
  lessCurved?: boolean;
  activeChart?: string;
  altColor?: boolean;
  data: {
    time: number;
    value: number;
  }[];
}

const CandlestickChart: FC<CandlestickChatProps> = ({
  data,
  lessCurved,
  activeChart,
  altColor,
}) => {
  const intl = useIntl();

  const breakpoint = useBreakpoint();
  const [hoverIndex, setHoverIndex] = useState<string>();
  const [position, setPosition] = useState({
    data: { x: 0, y: 0 },
    show: false,
  });

  const dataArr = [...data]?.map((value, index) =>
    Number(
      intl.formatNumber(Math.round(value?.value * 100) || 0, {
        notation: "compact",
      })
    )
  );

  const isMobile = breakpoint === "base";

  let thresholdValue = activeChart === "Earning Quality" ? 0.1 : 0;

  const CustomTick = ({ x, y, payload, stroke }) => {
    // Extract label from payload (assuming payload contains label)
    const label = payload.value;
  
    // Calculate text position based on tick location (adjust as needed)
    const textX = x; // Adjust horizontal offset
    const textY = y - 3; // Adjust vertical offset
  
    return (
      <g>
        <line x1={x} y1={y} x2={x} y2={y + 4} stroke={stroke} fill={"#C9CACB"} /> {/* Original tick line */}
        <text fill={"#C9CACB"} x={textX} y={textY} dominantBaseline="middle" fontSize={"8px"}>
          {label}
        </text>
      </g>
    );
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ResponsiveContainer width="100%" aspect={isMobile ? 1 : 2.8}>
        <BarChart
        
          data={data}
          margin={{
            top: 5,
            right: isMobile ? 0 : 30,
            left: isMobile ? -20 : 20,
            bottom: 5,
          }}
          barGap={5}
        >
          <XAxis
            dataKey="yearQ"
            axisLine={false}
            tickLine={false}
            tick={{fill:"#A3AED0"}}
            fontSize={"8px"}
            tickMargin={5}
          />
          <YAxis
            type="number"
            domain={
              activeChart === "Earning Quality" &&
              Math.min(...dataArr) > -20 &&
              Math.max(...dataArr) < 20
                ? [
                    (dataMin) => 0 - Math.abs(dataMin / 1),
                    (dataMax) => dataMax / 1,
                  ]
                : [0, "auto"]
            }
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#A3AED0" }}
            fontSize={"12px"}
            padding={{ bottom: 3 }}
            tickMargin={isMobile ? 5 : 20}
            tickFormatter={(v) => {
              if (activeChart === "Earning Quality") {
                return v?.toFixed(2) * 100;
              }
              if (
                [
                  "FCF",
                  "Net Income",
                  "Revenue",
                  "Operating Income",
                  "Operating Cash Flow",
                  "Capital Expenditure",
                ].includes(activeChart!)
              ) {
                return Math.round(v / 1_000_000_000) + "B";
              }

              return v;
            }}
          />
          <Tooltip
            wrapperStyle={{ outline: "none" }}
            content={
              <CustomTooltip active payload label activeChart={activeChart} />
            }
            cursor={{ fill: "transparent" }}
            position={{
              x: position?.data.x - 35 ?? 0,
              y:
                position?.data.y -
                  (data[Number(hoverIndex)]?.value > 0 ? 60 : -8) ?? 0,
            }}
          />
          <Bar
            dataKey="value"
            barSize={9.5}
            className="rechart_bar"
            radius={[3, 3, 0, 0]}
            onMouseMove={(data) => setPosition({ data: data, show: true })}
            onMouseLeave={(data) => setPosition({ data: data, show: false })}
          >
            {data.map((entry, i, arr) => {
              const isCurrent = i + 1 === arr.length;
              return (
                <Cell
                  onMouseEnter={() => {
                    if (i) {
                      setHoverIndex(`${i}`);
                    } else {
                      setHoverIndex("");
                    }
                  }}
                  key={`cell-${i}`}
                  fill={
                    altColor
                      ? Number(hoverIndex) === i
                        ? entry.value >= 0
                          ? "#ed9a9a"
                          : "#84b1dd"
                        : entry.value >= 0
                        ? `#FF4B4B`
                        : `#0A64BC`
                      : Number(hoverIndex) === i
                      ? (thresholdValue !== 0.1 && entry.value >= 0) ||
                        (thresholdValue === 0.1 &&
                          Math.abs(entry.value) < thresholdValue)
                        ? "#84b1dd" // lightblue
                        : "#ed9a9a" // lightred
                      : (thresholdValue !== 0.1 && entry.value >= 0) ||
                        (thresholdValue === 0.1 &&
                          Math.abs(entry.value) < thresholdValue)
                      ? `#0A64BC` // darkblue
                      : `#FF4B4B` // darkred
                  }
                  fillOpacity={
                    Number(hoverIndex) === i ? 1 : isCurrent ? 1 : ".25"
                  }
                  //radius={lessCurved ? 3 : 10}
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CandlestickChart;
