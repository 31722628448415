import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { stockApi } from '../services/stockApi';
import { chartApi } from '../services/chartApi';
import { watchlistPortfolioApi } from '../services/watchlistPortfolioApi';
import {screenerApi} from "../services/screenerApi"
import modalReducer from './slices/modal';
import watchlistReducer from './slices/watchlist';
import screenerReducer from './slices/screener';
import stockReducer from './slices/stock';

export const store = configureStore({
  reducer: {
    [stockApi.reducerPath]: stockApi.reducer,
    [chartApi.reducerPath]: chartApi.reducer,
    [watchlistPortfolioApi.reducerPath]: watchlistPortfolioApi.reducer,
    [screenerApi.reducerPath]: screenerApi.reducer,
    modal: modalReducer,
    watchlist: watchlistReducer,
    screener: screenerReducer,
    stock: stockReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(stockApi.middleware)
      .concat(chartApi.middleware)
      .concat(watchlistPortfolioApi.middleware)
      .concat(screenerApi.middleware)
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
