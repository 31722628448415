import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

function MobileContent({ children }) {
  const { pathname } = useLocation();
  const isStockPage = pathname?.startsWith('/stocks');
  const isWpPage = pathname?.startsWith('/watchlist') || pathname?.startsWith('/portfolio') || pathname?.startsWith('/screener');
  const baseHeight = isWpPage ? '65vh' : 'unset'
  return (
    <Box
      mt={{ base: '-45px', md: '100px' }}
      pt={{ base: 0, md: '0' }}
      pl={{ base: (isStockPage || isWpPage ) ? 0 : '10px', md: '100px' }}
      pr={{ base:  (isStockPage || isWpPage ) ? 0 : '10px', md: '64px' }}
      pb={{ base: '18px', md: '0' }}
      backgroundColor={{ base: 'transparent', md: '#04284B' }}
      minHeight={{ base: baseHeight , md: 'calc(100vh - 145px)' }}
      height={'auto'}
      fontFamily={'Poppins'}
      as='main'
    >
      {children}
    </Box>
  );
}

export default MobileContent;
