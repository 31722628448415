import axios from "axios";
import { showErrorToast } from "./toast";

const TOKEN_KEY = "accessToken";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v1/`,
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      localStorage.clear();
      showErrorToast("Unauthorized", "Cannot access resources");
    }

    return response;
  },
  (error) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token && !error.response) {
      localStorage.clear();
      showErrorToast("Unauthorized", "Cannot access resources");
    }

    if (!error.response) {
      showErrorToast(
        "Conexion error",
        "Please check your internet connection."
      );
    }

    const customError = {
      ...error,
      message: error.response?.data?.message ?? "Unknow",
      statusCode: error.response?.status ?? "Unknow",
    };
    return Promise.reject(customError);
  }
);

axiosInstance.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem(TOKEN_KEY);

    if (token && !req.url?.includes("Login")) {
      const replacedToken = token.replace(/['"]+/g, "").trim();
      if (req.headers) {
        req.headers.Authorization = "Bearer " + replacedToken;
        req.headers.token = replacedToken;
      }
    }

    return req;
  },
  (error) => {
    if (!error.response) {
      console.log("Please check your internet connection.");
    }
    const customError = {
      ...error,
      message: error.response?.data?.message ?? "Sin conexión",
      statusCode: error.response?.status ?? "Desconocido",
    };
    return Promise.reject(customError);
  }
);

export { axiosInstance };
