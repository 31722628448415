export function quickShare(shareData:any,onSuccess?:()=>void,onError?:()=>void){
    if (navigator.share) {
        navigator.share(shareData)
          .then(() => {
            console.log('Share successful');
            onSuccess && onSuccess()
          })
          .catch((error) => {
            console.error('Share failed:', error);
            onError && onError()
          });
      } else {
        console.log('Web Share API not supported');
      }
}