export const fakeDataForSkeleton = [
    {
        "_id": "65fddaf80c7ec115fa365907",
        "monkScorePercentile": 33.33333333333333,
        "monkScoreValue": 41.666666666666664,
        "GrowthValue": 63.88888888888889,
        "GrowthPercentile": 94.44444444444444,
        "ValueValue": 23.14814814814815,
        "ValuePercentile": 11.11111111111111,
        "ProfitabilityValue": 43.51851851851852,
        "ShareholderRetributionValue": 37.5,
        "ShareholderRetributionPercentile": 25,
        "HealthValue": 44.44444444444444,
        "HealthPercentile": 33.33333333333333,
        "CompanyProfile": {
            "companyName": "Prologis, Inc.",
            "country": "US",
            "image": "https://financialmodelingprep.com/image-stock/PLD.png",
            "ticker": "NYSE:PLD"
        }
    },
    {
        "_id": "65fddaf80c7ec115fa365908",
        "monkScorePercentile": 69.44444444444444,
        "monkScoreValue": 56.11111111111112,
        "GrowthValue": 58.333333333333336,
        "GrowthPercentile": 83.33333333333334,
        "ValueValue": 20.37037037037037,
        "ValuePercentile": 8.333333333333332,
        "ProfitabilityValue": 52.77777777777778,
        "ShareholderRetributionValue": 50.925925925925924,
        "ShareholderRetributionPercentile": 55.55555555555556,
        "HealthValue": 66.66666666666667,
        "HealthPercentile": 77.77777777777779,
        "CompanyProfile": {
            "companyName": "American Tower Corporation",
            "country": "US",
            "image": "https://financialmodelingprep.com/image-stock/AMT.png",
            "ticker": "NYSE:AMT"
        }
    },
    {
        "_id": "65fddaf80c7ec115fa365909",
        "monkScorePercentile": 16.666666666666664,
        "monkScoreValue": 35,
        "GrowthValue": 30.555555555555554,
        "GrowthPercentile": 33.33333333333333,
        "ValueValue": 29.629629629629633,
        "ValuePercentile": 22.22222222222222,
        "ProfitabilityValue": 46.29629629629629,
        "ShareholderRetributionValue": 44.44444444444444,
        "ShareholderRetributionPercentile": 41.66666666666667,
        "HealthValue": 41.66666666666667,
        "HealthPercentile": 27.77777777777778,
        "CompanyProfile": {
            "companyName": "Equinix, Inc.",
            "country": "US",
            "image": "https://financialmodelingprep.com/image-stock/EQIX.png",
            "ticker": "NASDAQ:EQIX"
        }
    },
    {
        "_id": "65fddaf80c7ec115fa36590a",
        "monkScorePercentile": 97.22222222222221,
        "monkScoreValue": 74.44444444444444,
        "GrowthValue": 60.18518518518518,
        "GrowthPercentile": 86.11111111111111,
        "ValueValue": 53.7037037037037,
        "ValuePercentile": 55.55555555555556,
        "ProfitabilityValue": 94.44444444444444,
        "ShareholderRetributionValue": 42.59259259259258,
        "ShareholderRetributionPercentile": 38.88888888888889,
        "HealthValue": 72.22222222222221,
        "HealthPercentile": 91.66666666666666,
        "CompanyProfile": {
            "companyName": "Public Storage",
            "country": "US",
            "image": "https://financialmodelingprep.com/image-stock/PSA.png",
            "ticker": "NYSE:PSA"
        }
    },
    {
        "_id": "65fddaf80c7ec115fa36590b",
        "monkScorePercentile": 5.555555555555555,
        "monkScoreValue": 27.22222222222222,
        "GrowthValue": 43.05555555555556,
        "GrowthPercentile": 55.55555555555556,
        "ValueValue": 60.18518518518519,
        "ValuePercentile": 66.66666666666666,
        "ProfitabilityValue": 10.185185185185185,
        "ShareholderRetributionValue": 12.037037037037036,
        "ShareholderRetributionPercentile": 0,
        "HealthValue": 19.444444444444443,
        "HealthPercentile": 8.333333333333332,
        "CompanyProfile": {
            "companyName": "Welltower Inc.",
            "country": "US",
            "image": "https://financialmodelingprep.com/image-stock/WELL.png",
            "ticker": "NYSE:WELL"
        }
    },
    {
        "_id": "65fddaf80c7ec115fa36590c",
        "monkScorePercentile": 80.55555555555556,
        "monkScoreValue": 63.33333333333333,
        "GrowthValue": 38.888888888888886,
        "GrowthPercentile": 52.77777777777778,
        "ValueValue": 40.74074074074074,
        "ValuePercentile": 33.33333333333333,
        "ProfitabilityValue": 86.11111111111111,
        "ShareholderRetributionValue": 51.85185185185186,
        "ShareholderRetributionPercentile": 61.111111111111114,
        "HealthValue": 68.05555555555556,
        "HealthPercentile": 80.55555555555556,
        "CompanyProfile": {
            "companyName": "Simon Property Group, Inc.",
            "country": "US",
            "image": "https://financialmodelingprep.com/image-stock/SPG.png",
            "ticker": "NYSE:SPG"
        }
    },
    {
        "_id": "65fddaf80c7ec115fa36590d",
        "monkScorePercentile": 55.55555555555556,
        "monkScoreValue": 52.777777777777786,
        "GrowthValue": 26.38888888888889,
        "GrowthPercentile": 22.22222222222222,
        "ValueValue": 32.40740740740741,
        "ValuePercentile": 27.77777777777778,
        "ProfitabilityValue": 70.37037037037038,
        "ShareholderRetributionValue": 50.92592592592593,
        "ShareholderRetributionPercentile": 58.333333333333336,
        "HealthValue": 68.05555555555556,
        "HealthPercentile": 80.55555555555556,
        "CompanyProfile": {
            "companyName": "Crown Castle Inc.",
            "country": "US",
            "image": "https://financialmodelingprep.com/image-stock/CCI.png",
            "ticker": "NYSE:CCI"
        }
    },
    {
        "_id": "65fddaf80c7ec115fa36590e",
        "monkScorePercentile": 11.11111111111111,
        "monkScoreValue": 33.888888888888886,
        "GrowthValue": 61.111111111111114,
        "GrowthPercentile": 88.88888888888889,
        "ValueValue": 37.96296296296296,
        "ValuePercentile": 30.555555555555557,
        "ProfitabilityValue": 26.851851851851855,
        "ShareholderRetributionValue": 32.40740740740741,
        "ShareholderRetributionPercentile": 16.666666666666664,
        "HealthValue": 26.388888888888893,
        "HealthPercentile": 13.88888888888889,
        "CompanyProfile": {
            "companyName": "Realty Income Corporation",
            "country": "US",
            "image": "https://financialmodelingprep.com/image-stock/O.png",
            "ticker": "NYSE:O"
        }
    },
    {
        "_id": "65fddaf80c7ec115fa36590f",
        "monkScorePercentile": 11.11111111111111,
        "monkScoreValue": 33.888888888888886,
        "GrowthValue": 55.555555555555564,
        "GrowthPercentile": 75,
        "ValueValue": 12.037037037037038,
        "ValuePercentile": 5.555555555555555,
        "ProfitabilityValue": 43.51851851851852,
        "ShareholderRetributionValue": 41.666666666666664,
        "ShareholderRetributionPercentile": 33.33333333333333,
        "HealthValue": 20.833333333333336,
        "HealthPercentile": 11.11111111111111,
        "CompanyProfile": {
            "companyName": "CoStar Group, Inc.",
            "country": "US",
            "image": "https://financialmodelingprep.com/image-stock/CSGP.png",
            "ticker": "NASDAQ:CSGP"
        }
    },
    {
        "_id": "65fddaf80c7ec115fa365910",
        "monkScorePercentile": 52.77777777777778,
        "monkScoreValue": 52.77777777777778,
        "GrowthValue": 56.94444444444444,
        "GrowthPercentile": 80.55555555555556,
        "ValueValue": 30.555555555555557,
        "ValuePercentile": 25,
        "ProfitabilityValue": 64.81481481481482,
        "ShareholderRetributionValue": 79.62962962962963,
        "ShareholderRetributionPercentile": 88.88888888888889,
        "HealthValue": 2.7777777777777777,
        "HealthPercentile": 0,
        "CompanyProfile": {
            "companyName": "Extra Space Storage Inc.",
            "country": "US",
            "image": "https://financialmodelingprep.com/image-stock/EXR.png",
            "ticker": "NYSE:EXR"
        }
    }
]