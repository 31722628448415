import { Box } from "@chakra-ui/react";

export function MonkBox(props){
    return (
        <Box display={'flex'} borderRadius={ '20px !important'}
                    padding={'10px !important'}
                    gap={'10px !important'}
                    bgColor={"#085096"}>

                    {props.children}
        </Box>

    )
}

export function MonkBoxLigth(props){
    return (
        <Box w='100%' mt={"5px"} alignItems={'center'} display={'flex'} borderRadius={ '20px !important'}
                    padding={'10px !important'}
                    gap={'15px !important'}
                    bgColor={"#F9FAFB"}>

                    {props.children}
        </Box>

    )
}