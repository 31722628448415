import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompanyRatios, ScoresTimeline } from "../../types/Chart";
import { IStock } from "../../types/Stock";
import { axiosInstance } from "../../context/api";

type State = {
  stock: IStock | null;
  scoresTimeline: ScoresTimeline | null;
  mostRecentRatio: ICompanyRatios | null;
  scaleCompanyRatios: ICompanyRatios[];
  mostRecentCompanyRatios: ICompanyRatios[];
  activeChart?: { type?: string; active: string; open: boolean, stock: IStock | null };
};

const initialState: State = {
  stock: null,
  scoresTimeline: null,
  mostRecentRatio: null,
  mostRecentCompanyRatios: [],
  scaleCompanyRatios: [],
  activeChart: {
    type: "",
    active: "",
    open: false,
    stock: null
  },
};

export const fetchOneTimeline = createAsyncThunk(
  "stock/fetchOne",
  async ({ fullTicker }: { fullTicker: string }, thunkAPI) => {
    const response = await axiosInstance.get<{ data: ScoresTimeline[] }>(
      `charts/${fullTicker}/monkscoretimeline`
    );
    const responseData = response.data.data;
    return responseData.length > 0 ? responseData[0] : null;
  }
);

export const fetchAllRatios = createAsyncThunk(
  "stock/fetchmostRecentRatio",
  async ({ fullTicker }: { fullTicker: string }, thunkAPI) => {
    const response = await axiosInstance.get<{
      mostRecentData: ICompanyRatios | null;
    }>(`charts/${fullTicker}/all-ratios`);
    const responseData = response.data.mostRecentData ?? null;
    return responseData;
  }
);

export const fetchAllCompanyRatios = createAsyncThunk(
  "stock/fetchCompanyRatios",
  async ({ fullTicker }: { fullTicker: string }, thunkAPI) => {
    const response = await axiosInstance.get<{ data: ICompanyRatios[] | null }>(
      `charts/${fullTicker}/all-ratios`
    );
    const responseData = response.data.data ?? [];
    return responseData;
  }
);

export const fetchAllCompanyScores = createAsyncThunk(
  "stock/fetchCompanyScores",
  async ({ fullTicker }: { fullTicker: string }, thunkAPI) => {
    const response = await axiosInstance.get<{ data: ICompanyRatios[] | null }>(
      `charts/${fullTicker}/all-company-score-five-year`
    );
    const responseData = response.data.data ?? [];
    return responseData;
  }
);

const stockSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    setStock: (state, action: PayloadAction<IStock>) => {
      state.stock = action.payload;
    },
    setScoresTimeline: (state, action: PayloadAction<ScoresTimeline>) => {
      state.scoresTimeline = action.payload;
    },
    openActiveChart: (
      state,
      action: PayloadAction<{ type: string; active: string }>
    ) => {
      state.activeChart = {
        open: true,
        type: action.payload.type,
        active: action.payload.active,
        stock: state.stock
      };
    },
    closeOpenActive: (state) => {
      state.activeChart = {
        type: state.activeChart?.type,
        open: false,
        active: state.activeChart?.active ?? "",
        stock: state.stock ?? null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOneTimeline.fulfilled, (state, action) => {
        state.scoresTimeline = action.payload;
      })
      .addCase(fetchOneTimeline.rejected, (state, action) => {
        state.scoresTimeline = null;
      })
      .addCase(fetchAllCompanyRatios.fulfilled, (state, action) => {
        state.mostRecentCompanyRatios = action.payload;
      })
      .addCase(fetchAllCompanyRatios.rejected, (state, action) => {
        state.mostRecentCompanyRatios = [];
      })
      .addCase(fetchAllRatios.fulfilled, (state, action) => {
        state.mostRecentRatio = action.payload;
      })
      .addCase(fetchAllRatios.rejected, (state, action) => {
        state.mostRecentRatio = null;
      })
      .addCase(fetchAllCompanyScores.fulfilled, (state, action) => {
        state.scaleCompanyRatios = action.payload;
      })
      .addCase(fetchAllCompanyScores.rejected, (state, action) => {
        state.scaleCompanyRatios = [];
      });
  },
});

export const { setStock, setScoresTimeline, closeOpenActive, openActiveChart } =
  stockSlice.actions;
export const actualScore = (state) =>
  state.stock.scoresTimeline as ScoresTimeline;
export const actualStock = (state) => state.stock.stock as IStock;
export const actualRecentCompanyRatios = (state) =>
  (state.stock.mostRecentCompanyRatios ?? []) as ICompanyRatios[];
export const actualRatios = (state) =>
  state.stock.mostRecentRatio as ICompanyRatios;
export const actualActiveChart = (state) =>
  state.stock.activeChart as { type?: string; active: string; open: boolean };
export const actualScaleCompanyRatios = (state) =>
  (state.stock.scaleCompanyRatios ?? []) as ICompanyRatios[];
export default stockSlice.reducer;
