import { PropsWithChildren, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import {HTMLMotionProps, motion} from "framer-motion"

const style = {
  transition: "all 1s",
};

type PropsDrag = {
    id: any
    className?: string
    index: number
    moveCard: (dragIndex: number, hoverIndex: number) => void,
    editMode?: boolean
} & HTMLMotionProps<"div">

/**
 * Renders a draggable card component with children.
 *
 * @param {PropsWithChildren<PropsDrag>} props - The props for the card component.
 * @param {any} props.id - The ID of the card.
 * @param {any} props.className - The className apply for root div.
 * @param {number} props.index - The index of the card.
 * @param {(dragIndex: number, hoverIndex: number) => void} props.moveCard - The function to move the card.
 * @param {ReactNode} props.children - The children of the card.
 * @return {JSX.Element} The rendered card component.
 */
export const DraggableCard = ({ id, index, moveCard, children, className, editMode, ...rest } : PropsWithChildren<PropsDrag>) => {

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item:any, monitor) {
      if(!editMode){
        return ;
      }
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset?.y ?? 0)- hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  const cursor = editMode ? (isDragging ? "grabbing" : "grab") : "pointer"
  
  drag(drop(ref));
  return (
    <motion.div {...rest} ref={ref} style={{ ...style, opacity, cursor }} data-handler-id={handlerId} className={className}>
      {children}
    </motion.div>
  );
};
