// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to uses
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBbFNDn4STRZ_IzR_N-ulgh5naW9BpDRJQ",
  authDomain: "monkstreet-app-login.firebaseapp.com",
  projectId: "monkstreet-app-login",
  storageBucket: "monkstreet-app-login.appspot.com",
  messagingSenderId: "1065281427483",
  appId: "1:1065281427483:web:10fdf3ab4a1f36cd8900be",
  measurementId: "G-K44Q0WDF63",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);
