import { Box, Flex, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import SigninSignup from '../components/Auth/SigninSignup';
import Accordion from '../components/Stocks/Accordion';
import MobileContent from '../layout/MainContent/MobileContent';
import DesktopContent from '../layout/MainContent/DesktopContent';

const PrivacyPolicy = () => {
  return (
    <>
      <MobileContent>
        <DesktopContent>
          <Flex width='375px' flexDirection={'column'} ml='auto' mr='auto'>
            <Box color='white' columnGap={1} position='relative' mb='40px'>
              {/* <Box
                position='absolute'
                right={3}
                top={3}
                style={{ zIndex: 100 }}
              >
                <SigninSignup />
              </Box> */}
              <Text
                fontSize='32px'
                fontWeight='500'
                lineHeight='35px'
                textDecorationColor='white'
                pl={1}
                color='#000'
              >
                <FormattedMessage id='privacy_policy_title' />
              </Text>
            </Box>

            <Box px={2} pb='4'>
              <Text
                fontSize='24px'
                fontWeight='500'
                textDecorationColor='white'
              >
                <FormattedMessage id='privacy_policy' />
              </Text>

              <Accordion
                titleId='privacy_policy_subheading1'
                descriptionId='privacy_policy_description1'
              />

              <Accordion
                titleId='privacy_policy_subheading2'
                descriptionId='privacy_policy_description2'
                list={[
                  'privacy_policy_list1_a',
                  'privacy_policy_list1_b',
                  'privacy_policy_list1_c',
                  'privacy_policy_list1_d',
                  'privacy_policy_list1_e',
                ]}
              />

              <Accordion
                titleId='privacy_policy_subheading3'
                descriptionId='privacy_policy_description3'
                list={[
                  'privacy_policy_list2_a',
                  'privacy_policy_list2_b',
                  'privacy_policy_list2_c',
                  'privacy_policy_list2_d',
                  'privacy_policy_list2_e',
                ]}
              />

              <Accordion
                titleId='privacy_policy_subheading4'
                descriptionId='privacy_policy_description4'
                list={[
                  'privacy_policy_list3_a',
                  'privacy_policy_list3_b',
                  'privacy_policy_list3_c',
                  'privacy_policy_list3_d',
                  'privacy_policy_list3_e',
                ]}
                listStyles={{ listStyleType: 'none' }}
              />

              <Accordion
                titleId='privacy_policy_subheading5'
                descriptionId='privacy_policy_description5'
                list={['privacy_policy_list4_a']}
                listStyles={{ listStyleType: 'none' }}
              />

              <Accordion
                titleId='privacy_policy_subheading6'
                descriptionId='privacy_policy_description6'
                list={[
                  'privacy_policy_list5_a',
                  'privacy_policy_list5_b',
                  'privacy_policy_list5_c',
                  'privacy_policy_list5_d',
                  'privacy_policy_list5_e',
                ]}
                listStyles={{ listStyleType: 'none' }}
              />

              <Accordion
                titleId='privacy_policy_subheading7'
                descriptionId='privacy_policy_description7'
                listStyles={{ listStyleType: 'none' }}
              />

              <Accordion
                titleId='privacy_policy_subheading8'
                descriptionId='privacy_policy_description8'
                listStyles={{ listStyleType: 'none' }}
              />

              <Accordion
                titleId='privacy_policy_subheading9'
                descriptionId='privacy_policy_description9'
                listStyles={{ listStyleType: 'none' }}
              />

              <Accordion
                titleId='privacy_policy_subheading9'
                descriptionId='privacy_policy_description9'
                listStyles={{ listStyleType: 'none' }}
              />

              <Accordion
                titleId='privacy_policy_subheading10'
                descriptionId='privacy_policy_description11'
                list={[
                  'privacy_policy_list6_a',
                  'privacy_policy_list6_b',
                  'privacy_policy_list6_c',
                  'privacy_policy_list6_d',
                  'privacy_policy_list6_e',
                ]}
                footerId='privacy_policy_footer_1'
              />

              <Accordion
                titleId='privacy_policy_subheading11'
                descriptionId='privacy_policy_description11'
                listStyles={{ listStyleType: 'none' }}
              />

              <Accordion
                titleId='privacy_policy_subheading12'
                descriptionId='privacy_policy_description12'
                listStyles={{ listStyleType: 'none' }}
              />

              <Text
                fontSize='24px'
                fontWeight='500'
                textDecorationColor='white'
                mt={4}
              >
                <FormattedMessage id='cookies_policy' />
              </Text>

              <Accordion
                titleId='cookies_policy_subheading1'
                descriptionId='cookies_policy_description1'
                listStyles={{ listStyleType: 'none' }}
              />

              <Accordion
                titleId='cookies_policy_subheading2'
                descriptionId='cookies_policy_description2'
                listStyles={{ listStyleType: 'none' }}
              />

              <Accordion
                titleId='cookies_policy_subheading3'
                descriptionId='cookies_policy_description3'
                listStyles={{ listStyleType: 'none' }}
              />

              <Accordion
                titleId='cookies_policy_subheading4'
                descriptionId='cookies_policy_description4'
                list={[
                  'cookies_policy_list1_a',
                  'cookies_policy_list1_b',
                  'cookies_policy_list1_c',
                  'cookies_policy_list1_d',
                  'cookies_policy_list1_e',
                  'cookies_policy_list1_f',
                  'cookies_policy_list1_g',
                  'cookies_policy_list1_h',
                  'cookies_policy_list1_i',
                ]}
              />

              <Accordion
                titleId='cookies_policy_subheading5'
                descriptionId='cookies_policy_description5'
                listStyles={{ listStyleType: 'none' }}
              />
            </Box>
          </Flex>
        </DesktopContent>
      </MobileContent>

      {/* </Flex> */}
    </>
  );
};

export default PrivacyPolicy;
